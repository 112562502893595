import React, { useState, useRef, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { handleSignOut } from "../Loan/PersonalLoan/SessionExpiredApi";
import LoadingSpinner from "../fields/spinner";
import {verifyOtpAPI,loginAPI,newSessionLoginAPI} from "../actioncreators/actioncreators"
import { useDispatch ,useSelector} from "react-redux";
import { toast } from "react-toastify";
const OtpForm = () => {
  const [ otp, setOtp ] = useState( [ '', '', '', '', '', '' ] );
  const [ isInvalidOtp, setIsInvalidOtp ] = useState( false );
  const [ isInvalidpan, setIsInvalidpan ] = useState( false );
  const [ isOtpResend, setOtpResend ] = useState( false );
  const [ isApiDown, setIsApiDown ] = useState( false );
  const [ isResendClickable, setIsResendClickable ] = useState( false );
  const [ resendTimer, setResendTimer ] = useState( 120 );
  const navigate = useNavigate();
  const [ apiError, setApiError ] = useState( "" );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ isButtonClicked, setIsButtonClicked ] = useState( false );
  const [ isVerifyButtonDisabled, setIsVerifyButtonDisabled ] = useState( true );
  const location = useLocation();
  const { state } = location;
  const { mobile, panCard } = state || {};
  const loginresponse = useSelector( ( state ) => state.loginResponse );
  
  const inputRefs = useRef( [ ...Array( 6 ) ].map( () => React.createRef() ) );
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect( () => {
    inputRefs.current[ 0 ].current.focus();
    const timerStartTime = parseInt( localStorage.getItem( "timerStartTime" ) );
    if ( timerStartTime ) {
      const elapsedTime = Math.floor( ( Date.now() - timerStartTime ) / 1000 );
      const remainingTime = Math.max( 0, 120 - elapsedTime );
      setResendTimer( remainingTime );
    } else {
      saveTimerStartTime(); // Call saveTimerStartTime if timerStartTime doesn't exist in local storage
    }
  }, [] );


  useEffect( () => {
    if ( resendTimer > 0 ) {
      const intervalId = setInterval( () => {
        setResendTimer( ( prevTimer ) => {
          if ( prevTimer <= 0 ) {
            clearInterval( intervalId );
            setIsResendClickable( true );
            return 0;
          }
          return prevTimer - 1;
        } );
      }, 1000 );
      return () => clearInterval( intervalId );
    }
  }, [ resendTimer ] );

  const saveTimerStartTime = () => {
    localStorage.setItem( "timerStartTime", Date.now().toString() );
  };
  const handleInputKeyDown = ( index, e ) => {
    if ( e.key === 'Backspace' && !otp[ index ] && index > 0 ) {
      inputRefs.current[ index - 1 ].current.focus();
    }
  };

  const handleOtpChange = ( index, value ) => {
    if ( /^\d*$/.test( value ) ) {
      const newOtp = [ ...otp ];
      newOtp[ index ] = value;
      setOtp( newOtp );

      if ( index < otp.length - 1 && value !== "" && inputRefs.current[ index + 1 ] ) {
        inputRefs.current[ index + 1 ].current.focus();
      }

      if ( newOtp.join( "" ).length === 1 ) {
        setIsInvalidOtp( false );
      }
      setIsVerifyButtonDisabled( newOtp.join( "" ).length !== 6 );
    }
  };

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    setIsButtonClicked(true);
    setIsLoading(true);
    const requestBody = {
      "otp": otp.join(""), // Join the otp array into a string
    };
  
    if (otp.join("") === "") {
      setIsInvalidOtp(true);
      inputRefs.current[0].current.focus();
      setIsLoading(false); // Need to set loading to false here too
      setIsButtonClicked(false); // Need to set button click state here too
      return;
    }
  
    try {
      
      const response = await verifyOtpAPI(requestBody);
  
      if (response.status === 200) {
        const responseData = response.data;
  
        if (responseData.businessStatusCode === 2) {
          if (responseData.message === "OTP has been validated") {
            localStorage.removeItem("timerStartTime");
            navigate("/home");
          } else {
            console.warn("Unexpected success message:", responseData.message);
          }
        } else {
          if (responseData.businessStatusSubCode === 1.1) {
            setIsInvalidOtp(true);
          } else if (
            responseData.businessStatusSubCode === 1.2 ||
            responseData.businessStatusSubCode === 1.3 ||
            responseData.businessStatusSubCode === 1.4) {
            toast.error(responseData?.message + ".");
            setIsInvalidpan(true);
            navigate("/");
          } else if (responseData.errorCode === 500) {
            setIsApiDown(true);
            navigate("/");
          } else {
            console.log("Unexpected response status:", response.status);
          }
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      handleApiError(error);
        console.error("Login failed:", error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  // const handleResendOtp = async ( e ) => {
  //   e.preventDefault();

  //   setOtp( [ "", "", "", "", "", "" ] );
  //   setResendTimer( 120 );
  //   localStorage.removeItem( "timerStartTime" );
  //   saveTimerStartTime();
  //   const requestBody = {
  //     "mobile": mobile,
  //     "pan": panCard,
  //   };

  //   try {
  //     if(loginresponse.data.httpResponseCode === 200 &&
  //       loginresponse.data.businessStatusCode === 1 &&
  //       loginresponse.data.businessStatusSubCode === 1.1){
  //         const response = await newSessionLoginAPI(requestBody);
          
  //       } else{
  //         const response = await loginAPI(requestBody);
  //       }
  //     if ( response.status === 200 && response.data.businessStatusCode === 2 ) {
  //       setOtpResend( true );
  //     } else {
  //       toast.error( response.data.message );
  //       setApiError( "Login failed. Invalid credentials." );
  //     }
  //   } catch ( error ) {
  //     if ( error.response && error.response.status === 401 ) {
  //       await handleSignOut();
  //       toast.error( "Your Session has expired. You will be redirected to the Login Page." );
  //       navigate( '/' );
  //     }else if (error.response && error.response.status === 429) {
  //       toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
  //     }
  //     else {
  //       toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
  //     }
  //     setApiError( "An error occurred while communicating with the server." );
  //   }
  // };

  const handleResendOtp = async (e) => {
    e.preventDefault();
  
    setOtp(["", "", "", "", "", ""]);
    setResendTimer(120);
    localStorage.removeItem("timerStartTime");
    saveTimerStartTime();
  
    const requestBody = {
      mobile: mobile,
      pan: panCard,
    };
  
    try {
      let response;
      if (
        loginresponse.data.httpResponseCode === 200 &&
        loginresponse.data.businessStatusCode === 1 &&
        loginresponse.data.businessStatusSubCode === 1.1
      ) {
        response = await newSessionLoginAPI(requestBody);
      } else {
        response = await loginAPI(requestBody);
      }
  
      if (response.status === 200 && response.data.businessStatusCode === 2) {
        setOtpResend(true);
      } else {
        toast.error(response.data.message);
        setApiError("Login failed. Invalid credentials.");
      }
    } catch (error) {
      handleApiError(error);
      setApiError("An error occurred while communicating with the server.");
    }
  };
  

  return (
    <div className="login-form-new">
      <b className="title mb-3">Verify OTP</b>
      <div className="enterotp">
        <div className="enter-the-6-digit">
          Enter the 6-digit code we have sent to{" "}
        </div>
        <h6 className="verify-phone-number" style={{ fontWeight: "900" }}>
          +91 {mobile}
          {/* <a href="/">
            <FaEdit className="fa-edit-icon ms-2" />
          </a> */}
        </h6>
      </div>
      <div className="Verify-OTP">
        <div className="Verify-OTP-section">

          <div className="otp-inputs">
            {otp.map( ( value, index ) => (
              <input
                key={index}
                type="text"
                className={`loginOtp-input ${isInvalidOtp ? "error" : ""}`}
                maxLength="1"
                value={value}
                onChange={( e ) => handleOtpChange( index, e.target.value )}
                onKeyDown={( e ) => handleInputKeyDown( index, e )}
                ref={inputRefs.current[ index ]}
              />
            ) )}
            {isInvalidOtp && (
              <p style={{ color: "red", fontSize: "10px" }}>Invalid OTP</p>
            )}
            {isInvalidpan && (
              <p style={{ color: "red", fontSize: "10px" }}>Invalid PAN</p>
            )}
            {isApiDown && (
              <p style={{ color: "red", fontSize: "10px" }}>
                Our Service is down. Please try again in some time.
              </p>
            )}
            {isOtpResend && (
              <p style={{ color: "green", fontSize: "10px" }}>
                OTP Resend Successfully
              </p>
            )}
          </div>

          <br />
          {resendTimer > 0 ? (
            <h6 className="text-center">
              Resend OTP in:
              {" "}{resendTimer < 10 ? `0${resendTimer}` : resendTimer} seconds
            </h6>
          ) : (
            <h6 className="text-center">
              Didn't receive any code?{" "}
              <a
                href="#"
                style={{ color: "rgb(255, 136, 0)", marginLeft: "5px" }}
                onClick={handleResendOtp} disabled={!isResendClickable}
              >
                <h6 className="resend-otp1">Resend OTP</h6>
              </a>
            </h6>

          )}

        </div>
      </div>
      <a
        href={isInvalidOtp ? "invalidotp" : "/home"}
        className="btn btn-rounded verify-proceed-btn"
        onClick={handleVerifyClick}
        style={{
          background: "rgb(253, 95, 7)",
          width: "100%",
          borderRadius: "20px",
          marginTop: "10px",
          fontWeight: 800,
          textTransform: "none",
          color: isVerifyButtonDisabled ? "white" : "white",
          opacity: isVerifyButtonDisabled || isButtonClicked ? "0.34" : "1",
          pointerEvents: isVerifyButtonDisabled || isButtonClicked ? "none" : "auto",
        }}
        disabled={isVerifyButtonDisabled}
      >
        {isLoading ? (
          <LoadingSpinner style={{ pointerEvents: 'none' }} />
        ) : ( ' Verify & Proceed'
        )}
      </a>
    </div>
  );
};

export default OtpForm;
