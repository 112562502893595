import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArthaTextField from "../../fields/ArthaTextField";
import { FaCheck, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { Button } from "@mui/material";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import "./personalLoan.css";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import {
  propertyLoanStoreGstrAPI,
  propertyLoanFetchGstrDetailsAPI,
  propertyLoanskipGstrAPI,
  propertyLoanBackPageAPI,
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
import LoadingSpinner from "../../fields/spinner";
import { FaTimes } from "react-icons/fa";
import UploadGSTRPopup from "./UploadGSTRPopup";

const GstrDetails = ({ changeApplicantStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [res, setRes] = useState();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    file1: "",
    file2: "",
    fileName1: "",
    gstin: "",
    fileName2: "",
  });

  const [fileNames, setFileNames] = useState([]);
  const [filesData, setfilesData] = useState([]);

  const matchedFile = fileNames.find((file) => file.id);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handlePopupClose = (filesData, id) => {
    setId(id);
    for (let index = 0; index < fileNames.length; index++) {
      const element = fileNames[index];
      if (element.id == id) {
        for (let index = 0; index < element.files.length; index++) {
          const file = element.files[index];
          filesData.push(file);
        }
      }
    }
    const updatedFileNames = fileNames.filter((item) => item.id !== id);
    let temp = { id: id, files: filesData };
    const newFileNames = [...updatedFileNames, temp];
    setFileNames(newFileNames);
    setPopupOpen(false);
  };

  const removeFileFromFileNames = (file) => {
    let bankObject;
    for (let index = 0; index < fileNames.length; index++) {
      const element = fileNames[index];
      if (element.id) {
        bankObject = element;
      }
    }
    if (bankObject) {
      let existingfiles = bankObject.files;
      let latestArray = [];
      for (let index = 0; index < existingfiles.length; index++) {
        const tempFile = existingfiles[index];
        if (tempFile.name != file.name) {
          latestArray.push(tempFile);
        }
      }

      const updatedFileNames = fileNames.filter((item) => item.id);
      let temp = { files: latestArray };
      const newFileNames = [...updatedFileNames, temp];
    }
  };

  const [id, setId] = useState();

  const navigate = useNavigate();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const [documents, setDocuments] = useState([]);
  const fetchGstrReport = async () => {
    try {
      const response2 = await propertyLoanFetchGstrDetailsAPI();
      setRes(response2);
      const serializedResponse = JSON.stringify(response2);

      // Store the serialized data in local storage
      localStorage.setItem("response2Data", serializedResponse);

      if (response2.data?.gstResponse?.documents?.[0]?.documentName) {
        setIsITRUploaded(true);
        setIsITRUploaded2(true); // This line might be redundant if the condition is the same
      }
      setFormData({
        username: response2.data?.username,
        password: "",
        gstin: response2.data?.gstResponse.gstin,
        fileName1: response2.data?.gstResponse?.documents?.[0]?.documentName,
        fileName2: response2.data?.gstResponse?.documents?.[1]?.documentName,
      });
    } catch (error) {
      handleApiError(error);
    }
  };
  useEffect(() => {
    fetchGstrReport();
  }, []);

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      username: res?.username || "",
    });
  }, [res]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [panProvided, setPanProvided] = useState(false);
  const [isITRUploaded, setIsITRUploaded] = useState(false);
  const [isITRUploaded2, setIsITRUploaded2] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [error, setError] = useState("");

  const handleUsernamePasswordChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      (formData.username !== "" && formData.password !== "") ||
      (fileNames[0]?.files && fileNames[0].files.length > 0) ||
      res?.data.gstResponse?.documents?.length > 0
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }

    if (formData.username !== "" && formData.password !== "") {
      setPanProvided(true);
    } else if (
      (fileNames[0]?.files && fileNames[0].files.length > 0) ||
      res?.data.gstResponse?.documents?.length > 0
    ) {
      setFilesUploaded(true);
    } else {
      setFilesUploaded(false);
      setPanProvided(false);
    }
  }, [formData, fileNames, res]);

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading(true);

    let documentData =
      res?.data?.gstResponse?.documents?.map((doc) => ({
        documentId: doc.docId || "",
        documentName: doc.documentName,
      })) || [];

    let payload = {
      gstIn: formData.gstin,
      documents: [
        ...(fileNames[0]?.files || []), // Spread the files array from the first element of fileNames
        ...(documentData || []), // Spread the array of mapped document data
      ],
      passWord: formData.password,
      userName: formData.username,
    };

    try {
      const response = await propertyLoanStoreGstrAPI(payload);
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else if (
        response.data.businessStatusCode === 2 &&
        response.data.businessStatusSubCode === 2.2
      ) {
        changeApplicantStatus(response?.data?.nextPage);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const skipGstr = async () => {
    try {
      const response = await propertyLoanskipGstrAPI();
      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        console.error("Invalid response status.");
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error making GET request:", error);
    }
  };

  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  const handleGSTINChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, gstin: value });

    if (!gstRegex.test(value)) {
      setError("Please enter valid GSTIN");
    } else {
      setError("");
    }
  };

  const handleUploadButtonStatement = async () => {
    setPopupOpen(true);
  };

  const handleRemove = (identifier) => {
    const updatedDocuments =
      res?.data.gstResponse?.documents?.filter(
        (doc) => doc.docId !== identifier && doc.documentName !== identifier
      ) || [];

    // Remove from fileNames
    const updatedFiles =
      fileNames[0]?.files?.filter(
        (file) =>
          file.documentId !== identifier && file.documentName !== identifier
      ) || [];

    // Update state with the filtered lists
    setRes((prevRes) => ({
      ...prevRes,
      data: {
        ...prevRes.data,
        gstResponse: {
          ...prevRes.data.gstResponse,
          documents: updatedDocuments,
        },
      },
    }));

    setFileNames((prevFiles) => [
      {
        ...prevFiles[0],
        files: updatedFiles,
      },
    ]);
  };

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6>GSTR Analysis</h6> 
            <div
              onClick={goToSummaryPage}
              className="text fw-bold"
              style={{
                color: "orange",
                marginLeft: "auto",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Go To Applicant Summary
            </div>
          </div>
          </div>
          <div className="col-12">
            <a
              className="ms-auto my-2"
              onClick={skipGstr}
              style={{
                color: "rgb(255, 115, 0)",
                fontWeight: "bold",
                textDecoration: "none",
                cursor: "pointer",
                float: "right",
              }}
            >
              Skip
            </a>
          </div>
          <div
            className="row border p-3 rounded"
            style={{
              opacity: filesUploaded ? 0.5 : 1,
              backgroundColor: filesUploaded ? "lightgray" : "initial",
              pointerEvents: filesUploaded ? "none" : "auto", // Disables interactions
            }}
          >
            <div className="col-12">
              <h6>Fetch GSTR Analysis</h6>
              <h6 style={{ fontSize: "16px", fontWeight: 600 }}>
                Provide the GST Portal Username and Password to fetch GST
                Analysis
              </h6>

              <div className="row pt-1">
                <div className="col-3 pt-2">
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                  >
                    GSTIN :
                  </span>
                </div>

                <div className="col-6">
                  <ArthaTextField
                    label="GSTIN"
                    fullWidth
                    style={{ marginLeft: "-40px" }}
                    name="gstin"
                    value={formData.gstin}
                    disabled
                    onChange={handleGSTINChange}
                    className="form-control custom-disabled-input"
                  />
                  {error && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {error}
                    </span>
                  )}{" "}
                  <br />
                </div>
              </div>
              <div className="row pt-1 mt-3">
                <div className="col-3 pt-2">
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                  >
                    UserName :
                  </span>
                </div>

                <div className="col-6">
                  <ArthaTextField
                    label="GST Portal UserName"
                    fullWidth
                    style={{ marginLeft: "-40px" }}
                    name="username"
                    value={formData.username}
                    // onChange={(e) =>
                    //     setFormData({
                    //         ...formData,
                    //         username: e.target.value,
                    //     })
                    // }
                    onChange={handleUsernamePasswordChange}
                    disabled={filesUploaded}
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-3 pt-3">
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                  >
                    Password :
                  </span>
                </div>
                <div className="col-6">
                  <ArthaTextField
                    label="GST Portal Password"
                    fullWidth
                    style={{ marginLeft: "-40px", marginTop: "10px" }}
                    name="password"
                    value={formData.password}
                    onChange={handleUsernamePasswordChange}
                    disabled={filesUploaded}
                    // onChange={(e) =>
                    //     setFormData({
                    //         ...formData,
                    //         password: e.target.value,
                    //     })
                    // }
                  />
                </div>
              </div>
              <h6
                style={{ fontSize: "16px", fontWeight: 600, marginTop: "20px" }}
              >
                This option will help us to get your details directly from GST
                portal and expedite loan processing.
              </h6>
              <h6 className="mt-2" style={{ color: "#00d208" }}>
                Please Note that username and password are for one time use and
                are not stored in system.{" "}
              </h6>
            </div>
          </div>
          <div
            className="row border p-3 rounded mt-3"
            style={{ backgroundColor: panProvided ? "lightgray" : "initial" }}
          >
            <div className="col-12">
              <h6>
                If you do not wish to share the GST login details, you can
                directly upload past 24 months' GSTR using the below option. You
                can attach multiple documents, if required.
              </h6>
              <div className="row">
                <div className="col-6 mt-0 mt-md-4">
                  <p>Upload GSTR Report for previous 24 Months</p>

                  {/* Checking if either gstResponse documents or fileNames contains data */}
                  {res?.data.gstResponse?.documents?.length > 0 ||
                  (fileNames[0]?.files && fileNames[0].files.length > 0) ? (
                    <>
                      {/* Mapping over res.data.gstResponse.documents if data is present */}
                      {res?.data.gstResponse?.documents?.length > 0 &&
                        res.data.gstResponse.documents.map((doc, index) => (
                          <div className="mt-3 upload-lable" key={index}>
                            <FaCheckCircle
                              className=""
                              style={{
                                color: "green",
                                marginTop: "0px !important",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "13px",
                                marginTop: "3px",
                              }}
                            >
                              {doc.documentName}
                            </span>
                            {/* Remove button */}
                            <FaTimes
                              className="remove-icon"
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                              onClick={() =>
                                handleRemove(doc.docId || doc.documentName)
                              }
                            />
                          </div>
                        ))}

                      {/* Mapping over fileNames if files are present */}
                      {fileNames[0]?.files?.length > 0 &&
                        fileNames[0].files.map((file, index) => (
                          <div className="mt-3 upload-lable" key={index}>
                            <FaCheckCircle
                              className=""
                              style={{
                                color: "green",
                                marginTop: "0px !important",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "13px",
                                marginTop: "3px",
                              }}
                            >
                              {file.documentName}
                            </span>
                            {/* Remove button */}
                            <FaTimes
                              className="remove-icon"
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                              onClick={() =>
                                handleRemove(
                                  file.documentId || file.documentName
                                )
                              }
                            />
                          </div>
                        ))}
                    </>
                  ) : (
                    <>
                      {/* Show "Not Uploaded" if no data is present in either gstResponse documents or fileNames */}
                      <FaExclamationCircle
                        className=""
                        style={{
                          color: "rgb(255, 187, 0)",
                          marginTop: "2px !important",
                        }}
                      />
                      <span className=""> Not Uploaded</span>
                    </>
                  )}
                </div>

                <div className="col-6 mt-0 mt-md-4">
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none"
                    onClick={() => {
                      handleUploadButtonStatement();
                      // fetchGstrReport();
                    }}
                    disabled={panProvided}
                  >
                    {matchedFile ? "Edit" : "Upload"}
                  </Button>
                </div>
              </div>
            </div>
            <UploadGSTRPopup
              response={res}
              documents={documents}
              // open={isPopupOpen}
              // handleClose={handlePopupClose}
              // popupLable="Bank Statement"
              // // bank={selectedBank}
              // removeFileFromFileNames={removeFileFromFileNames}
              // fileNames={fileNames}
              // setFileNames={setFileNames}
              // setFilesData={filesData}
            />
          </div>

          <div className="row mt-4">
            <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton
                type="button"
                className="custom_btn"
                onClick={handleBack}
              >
                Back
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleContinue}
                disabled={!isFormValid}
                style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Continue"
                )}
              </ArthaContinueButton>
            </div>
          </div>
      
        <UploadGSTRPopup
          open={isPopupOpen}
          handleClose={handlePopupClose}
          popupLable="Bank Statement"
          // bank={selectedBank}
          removeFileFromFileNames={removeFileFromFileNames}
          fileNames={fileNames}
          setFileNames={setFileNames}
          setFilesData={filesData}
        />
      </div>
    </>
  );
};

export default GstrDetails;
