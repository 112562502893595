import React, { useState, useEffect, useRef } from "react";
import ArthaTextField from "../../fields/ArthaTextField";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { Button } from "@mui/material";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import "./personalLoan.css";
import axios from "axios";
import {
  propertyLoanStoreItrAPI,
  propertyLoanFetchItrDetailsAPI,
  propertyLoanSkipITRAPI,
  propertyLoanBackPageAPI,
} from "../../actioncreators/actioncreators";
import { handleSignOut } from "./SessionExpiredApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GstrDetails from "./Gstr";
import { FaTimes } from "react-icons/fa";
import LoadingSpinner from "../../fields/spinner";

const ITrDetails = ({ changeApplicantStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    file1: "",
    file2: "",
    file3: "",
    fileName1: "",
    fileName2: "",
    fileName3: "",
  });

  const [res, setRes] = useState();
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [showGstrDetails, setshowGstrDetails] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const navigate = useNavigate();
  const fetchItrReport = async () => {
    try {
      const response2 = await propertyLoanFetchItrDetailsAPI();
      setRes(response2.data);
      if (response2.data?.itrFiles?.[0]?.fileName) {
        setIsITRUploaded(true);
      }
      if (response2.data?.itrFiles?.[1]?.fileName) {
        setIsITRUploaded2(true);
      }
      if (response2.data?.itrFiles?.[2]?.fileName) {
        setIsITRUploaded3(true);
      }
      setFormData({
        username: response2.data?.username,
        password: "",
        file1: "",
        file2: "",
        file3: "",
        fileName1: response2.data?.itrFiles?.[0]?.fileName,
        fileName2: response2.data?.itrFiles?.[1]?.fileName,
        fileName3: response2.data?.itrFiles?.[2]?.fileName,
      });
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching ITR report:", error);
    }
  };

  useEffect(() => {
    fetchItrReport();
  }, []);

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };
  useEffect(() => {
    setFormData({
      ...formData,
      username: res?.username || "",
    });
  }, [res]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [panProvided, setPanProvided] = useState(false);
  const [documentProvided, setDocumentProvided] = useState(false);
  const [isITRUploaded, setIsITRUploaded] = useState(false);
  const [isITRUploaded2, setIsITRUploaded2] = useState(false);
  const [isITRUploaded3, setIsITRUploaded3] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsITRUploaded(true);

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setFormData({
          ...formData,
          file1: base64String,
          fileName1: file.name,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsITRUploaded2(true);

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setFormData({
          ...formData,
          file2: base64String,
          fileName2: file.name,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsITRUploaded3(true);

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        setFormData({
          ...formData,
          file3: base64String,
          fileName3: file.name,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUsernameChange = (e) => {
    let enteredValue = e.target.value.toUpperCase(); // Convert to uppercase

    if (enteredValue.length > 10) {
      enteredValue = enteredValue.slice(0, 10);
    }
    setFormData({
      ...formData,
      username: enteredValue,
    });
    // Validate PAN card format
    const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    setIsValidPanCard(panCardRegex.test(enteredValue));
  };
  const handleRemove = () => {
    setFormData({ ...formData, fileName1: "" });
    setIsITRUploaded(false);
  };

  const handleRemove2 = () => {
    setFormData({ ...formData, fileName2: "" });
    setIsITRUploaded2(false);
  };

  const handleRemove3 = () => {
    setFormData({ ...formData, fileName3: "" });
    setIsITRUploaded3(false);
  };

  useEffect(() => {
    if (
      formData.password !== "" ||
      (isITRUploaded && isITRUploaded2 && isITRUploaded3)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }

    if (formData.username !== "" && formData.password !== "") {
      setPanProvided(true);
    } else {
      setPanProvided(false);
    }

    if (
      formData.fileName1 !== "" ||
      formData.fileName2 !== "" ||
      formData.fileName3 !== ""
    ) {
      setDocumentProvided(true);
    } else {
      setDocumentProvided(false);
    }
  }, [formData, isITRUploaded, isITRUploaded2, isITRUploaded3]);

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading(true);
    const payload = {
      itrFiles: [
        {
          fileData: formData.file1,
          fileName: formData.fileName1,
          financialYear: res?.itrFiles?.[0]?.financialYear,
        },
        {
          fileData: formData.file2,
          fileName: formData.fileName2,
          financialYear: res?.itrFiles?.[1]?.financialYear,
        },
        {
          fileData: formData.file3,
          fileName: formData.fileName3,
          financialYear: res?.itrFiles?.[2]?.financialYear,
        },
      ],
      password: formData.password,
      username: formData.username,
    };

    try {
      const response = await propertyLoanStoreItrAPI(payload);
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else if (response?.data?.businessStatusCode === 1) {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      handleApiError(error);

      console.error("Error uploading ITR files:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const skipITR = async () => {
    try {
      const response = await propertyLoanSkipITRAPI();

      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        console.error("Invalid response status.");
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error making GET request:", error);
    }
  };

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };
  return (
    <>
      <div className="row">
        <div className="col-12">  
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6>Income Tax Return</h6>
              <div
                onClick={goToSummaryPage}
                className="text fw-bold"
                style={{
                  color: "orange",
                  marginLeft: "auto",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Go To Applicant Summary
              </div>
             
            </div>
            </div>
            <div className="col-12">
            <a
              className="ms-auto my-2"
              onClick={skipITR}
              style={{
                color: "rgb(255, 115, 0)",
                fontWeight: "bold",
                textDecoration: "none",
                cursor: "pointer",
                float: "right",
              }}
            >
              Skip
            </a>
            </div>
          <div
            className="row border p-3 rounded"
            style={{
              opacity:
                isITRUploaded || isITRUploaded2 || isITRUploaded3 ? 0.5 : 1,
              backgroundColor:
                isITRUploaded || isITRUploaded2 || isITRUploaded3
                  ? "lightgray"
                  : "initial",
              pointerEvents:
                isITRUploaded || isITRUploaded2 || isITRUploaded3
                  ? "none"
                  : "auto", // Disables interactions
            }}
          >
            <div className="col-12">
              <h6>Fetch ITR Reports</h6>
              <h6 style={{ fontSize: "16px", fontWeight: 600 }}>
                Provide Income Tax Portal Username and Password to fetch ITR
                Details
              </h6>
              <div className="row pt-1">
                <div className="col-3 pt-2">
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                  >
                    User Name/ PAN
                  </span>
                </div>
                <div className="col-6">
                  <ArthaTextField
                    label="Income Tax Portal UserName / PAN"
                    fullWidth
                    style={{ marginLeft: "-40px" }}
                    name="username"
                    value={formData.username}
                    onChange={(e) => handleUsernameChange(e)}
                    error={!isValidPanCard}
                    helperText={
                      !isValidPanCard ? "Invalid PAN card format" : ""
                    }
                  />
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-3 pt-2">
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                  >
                    Password
                  </span>
                </div>
                <div className="col-6">
                  <ArthaTextField
                    label="Income Tax Portal Password"
                    fullWidth
                    style={{ marginLeft: "-40px" }}
                    name="password"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <h6
                style={{ fontSize: "16px", fontWeight: 600, marginTop: "10px" }}
              >
                This option will help us to get your details directly from
                income tax portal and expedite loan processing
              </h6>
              <h6 className="mt-2" style={{ color: "#00d208" }}>
                Please Note that username and password are for one time use and
                are not stored in system.{" "}
              </h6>
            </div>
          </div>

          <div
            className="row itrBox mt-3"
            style={{ backgroundColor: panProvided ? "#ebebeb" : "initial" }}
          >
            <div className="col-12">
              <h6>
                If you do not wish to share the income tax login details, you
                can directly upload ITRs for the required period, using below
                option.
              </h6>

              <div className="row">
                <div className="col-8 col-md-4 mt-3 mt-md-4">
                  <span className="msg">
                    ITR for {res?.itrFiles?.[0]?.financialYear}
                  </span>
                  <br />
                  {isITRUploaded ? (
                    <>
                      <FaCheck
                        className="check-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> {formData.fileName1}</span>
                      <FaTimes
                        className="remove-icon"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={handleRemove}
                      />
                    </>
                  ) : (
                    <>
                      <FaExclamationCircle
                        className="badge-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> Not Uploaded</span>
                    </>
                  )}
                </div>
                <div className="col-4 col-md-2 mt-4">
                  <input
                    accept=".pdf"
                    style={{ display: "none" }}
                    id="itr-upload"
                    type="file"
                    disabled={panProvided}
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="itr-upload">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload_btn btn_white shadow-none mt-3"
                      disabled={panProvided}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-8 col-md-4 mt-3 mt-md-4">
                  <span className="msg">
                    ITR for {res?.itrFiles?.[1]?.financialYear}
                  </span>
                  <br />
                  {isITRUploaded2 ? (
                    <>
                      <FaCheck
                        className="check-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> {formData.fileName2}</span>
                      <FaTimes
                        className="remove-icon"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={handleRemove2}
                      />
                    </>
                  ) : (
                    <>
                      <FaExclamationCircle
                        className="badge-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> Not Uploaded</span>
                    </>
                  )}
                </div>
                <div className="col-4 col-md-2 mt-4">
                  <input
                    accept=".pdf"
                    style={{ display: "none" }}
                    id="itr-upload-2"
                    type="file"
                    disabled={panProvided}
                    onChange={handleFileUpload2}
                  />
                  <label htmlFor="itr-upload-2">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload_btn btn_white shadow-none mt-3"
                      disabled={panProvided}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-8 col-md-4 mt-3 mt-md-4">
                  <span className="msg">
                    ITR for {res?.itrFiles?.[2]?.financialYear}
                  </span>
                  <br />
                  {isITRUploaded3 ? (
                    <>
                      <FaCheck
                        className="check-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> {formData.fileName3}</span>
                      <FaTimes
                        className="remove-icon"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={handleRemove3}
                      />
                    </>
                  ) : (
                    <>
                      <FaExclamationCircle
                        className="badge-icon"
                        style={{ marginTop: "3px" }}
                      />
                      <span> Not Uploaded</span>
                    </>
                  )}
                </div>
                <div className="col-4 col-md-2 mt-4">
                  <input
                    accept=".pdf"
                    style={{ display: "none" }}
                    id="itr-upload-3"
                    type="file"
                    disabled={panProvided}
                    onChange={handleFileUpload3}
                  />
                  <label htmlFor="itr-upload-3">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload_btn btn_white shadow-none mt-3"
                      disabled={panProvided}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton
                type="button"
                className="custom_btn"
                onClick={handleBack}
              >
                Back
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleContinue}
                disabled={!isFormValid}
                style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Continue"
                )}
              </ArthaContinueButton>
            </div>
          </div>
      
      </div>
    </>
  );
};

export default ITrDetails;
