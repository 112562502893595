import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ApplicantSummaryDetails from "./ApplicantsSummary.jsx";

import {
  propertyLoanFetchDataAPI,
  propertyLoanFetchRetriedDetailsAPI,
  propertyLoanStoreRetriedDetailsAPI,
  propertyLoanBackPageAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import EligibilityCheckForm from "../PersonalLoan/EligibilityCheckForm.jsx";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

import { Button, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import UploadDeceasedDoc from "./UploadDeceasedDoc.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
// import AddBank from "../BusinessLoan/AddBank.jsx";
// import AddBank from "./AddBank.jsx";
import AddBankRetried from "./AddBankRetried.jsx";
import UploadApplicantWithoutPanDoc from "./UploadApplicantWithoutPanDoc.jsx";
import UploadRetriedDoc from "./UploadRetriedDoc.jsx";

const QualificationIncomeRetriedForm = ({ changeApplicantStatus }) => {
  const [selectedDocs, setSelectedDocs] = useState({ document: [] });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [openBankDialog, setOpenBankDialog] = React.useState(false);
  const [bankDetails, setBankDetails] = React.useState(false);
  const [firmDetails, setFirmDetails] = React.useState({});
  const [documents, setDocuments] = useState([]);
  const [response, setResponse] = React.useState([]);
  const [qualificationDropdown, setQualificationDropdown] = React.useState([]);
  const [occupationDropdown, setOccupationDropdown] = React.useState([]);
  const [incomeSourceDropdown, setIncomeSourceDropdown] = React.useState([]);
  const fileInputRefID = useRef(null);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const navigate = useNavigate();
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const [isLoading3, setIsLoading3] = useState(false);

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const [formData, setFormData] = useState({
    qualification: "",
    monthlyObligations: "",
    monthlyIncome: "",
    incomeSource: "",
    occupation: "",
    // selectedDocs: "",
  }); 


  const [currentEmployeer, setCurrentEmployeer] = React.useState({
    selectedDocs: "",
  });

  useEffect(() => {
    validateForm();
  }, [formData,bankDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "qualification") {
      setFormData({
        ...formData,
        qualification: value,
      });
    } else if (name === "monthlyObligations" || name === "monthlyIncome") {
      const sanitizedValue = value.replace(/\D/g, "");
      const regex = /^[0-9]{0,8}$/;

      if (regex.test(sanitizedValue)) {
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      } else {
        console.error(
          "Invalid input. Please enter a number with up to 8 digits."
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    validateForm(); // Validate on every change
  };

  // const validateForm = () => {
  //   const { monthlyIncome, monthlyObligations, qualification, occupation, incomeSource } = formData;

  //   // Validate each field

  //   const isMonthlyIncomeValid = /^\d+$/.test(monthlyIncome);
  //   const isMonthlyObligationValid = /^\d+$/.test(monthlyObligations);
  //   const hasBankDetails = !!bankDetails;
  //   // const hasSupportingDocuments = selectedDocs?.document?.length > 0;
  //   const isQualificationValid = !!qualification;
  //   const isOccupationValid = !!occupation;
  //   const isIncomeSourceValid = !!incomeSource;
  //   // Update the overall form validity
  //   setIsFormValid(
  //     isMonthlyIncomeValid &&
  //     isMonthlyObligationValid &&
  //     hasBankDetails &&
  //     // hasSupportingDocuments &&
  //     isQualificationValid && isOccupationValid && isIncomeSourceValid
  //   );
  // };

  const validateForm = () => {
    const { qualification, occupation, incomeSource, monthlyIncome, monthlyObligations } = formData;
    // Check if all fields are filled and bank details are added
    const isMonthlyIncomeValid = monthlyIncome && /^\d+$/.test(monthlyIncome);
    const isMonthlyObligationValid = monthlyObligations && /^\d+$/.test(monthlyObligations);

    const isComplete =
      qualification &&
      occupation &&
      incomeSource &&
      isMonthlyIncomeValid &&
      isMonthlyObligationValid &&
      bankDetails;
    
    setIsFormValid(isComplete);
  };
//   const validateForm = () => {
//     const { monthlyIncome, monthlyObligations, qualification, occupation, incomeSource } = formData;

//     // Check that monthlyIncome and monthlyObligations are numeric and not empty
//     const isMonthlyIncomeValid = monthlyIncome && /^\d+$/.test(monthlyIncome);
//     const isMonthlyObligationValid = monthlyObligations && /^\d+$/.test(monthlyObligations);

//     // Check for presence of bank details, qualification, occupation, and income source
//     const hasBankDetails = !!bankDetails;
//     const isQualificationValid = !!qualification;
//     const isOccupationValid = !!occupation;
//     const isIncomeSourceValid = !!incomeSource;

//     // Determine if the form is valid and update the button's disabled status
//     const formIsValid = 
//         isMonthlyIncomeValid &&
//         isMonthlyObligationValid &&
//         hasBankDetails &&
//         isQualificationValid &&
//         isOccupationValid &&
//         isIncomeSourceValid;

//     setIsFormValid(formIsValid); // Updates state controlling the button's disabled state
// };



  useEffect(() => {
    //fetching Qualification dropdown
    let apiPayload = {
      type: "BORROWER_QUALIFICATION",
      key: "BORROWER_QUALIFICATION",
      source: "json_value",
    };
    fetchDropDownValues(apiPayload, "BORROWER_QUALIFICATION");
    let apiPayload1 = {
      source: "json_value",
      type: "OCCUPATION",
      key: "Retired/Homemaker/Student",
    };
    fetchDropDownValues(apiPayload1, "OCCUPATION");
  }, []);
  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    setQualificationDropdown([]);
    setOccupationDropdown([]);
    try {
      const response = await propertyLoanFetchDataAPI(apiPayload);

      if (dropdownType == "BORROWER_QUALIFICATION") {
        setQualificationDropdown(response?.data?.[0]?.values);
      } else if (dropdownType == "OCCUPATION") {
        setOccupationDropdown(response?.data?.[0]?.List || []);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading3(true);

    let payload = {
      qualification: formData.qualification,
      monthlyIncome: parseInt(formData.monthlyIncome, 10),
      monthlyObligations: parseInt(formData.monthlyObligations, 10),
      banksDetail: bankDetails,
      supportingDocuments: selectedDocs.document,
      occupation: formData.occupation,
      incomesource: formData.incomeSource,
    };
    console.log(payload);
    try {
      const response = await propertyLoanStoreRetriedDetailsAPI(payload);

      if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 2
      ) {
        changeApplicantStatus(response.data.nextPage);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error storing employee history:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading3(false);
      setIsButtonClicked(false);
    }
  };
  const handleUploadDocument = () => {
    setPopupOpen(true);
  };

  // useEffect(() => {
  //   if (response?.data?.response?.supportingDocuments) {
  //     console.log("response?.data?.response?.supportingDocuments",response?.data?.response?.supportingDocuments);

  //     setDocuments(response?.data?.response?.supportingDocuments);
  //   }
  // }, [response.data,response]);

  // useEffect(() => {
  //   if (firmDetail.supportingDocuments) {
  //     setSelectedDocs({ document: firmDetail.supportingDocuments });
  //   }
  // }, [firmDetail?.supportingDocuments]);

  useEffect(() => {
    const fetchProfessionalDetails = async () => {
      try {
        // Fetch the main details
        const response = await propertyLoanFetchRetriedDetailsAPI();
        console.log("API Response:", response);
        setResponse(response.data);

        // Set individual form fields
        setFormData((prevFormData) => ({
          ...prevFormData,
          qualification: response?.data?.response?.qualification || "",
          monthlyIncome: response?.data?.response?.monthlyIncome || "",
          monthlyObligations: response?.data?.response?.monthlyObligations || "",
          occupation: response?.data?.response?.occupation || "",
          incomeSource: response?.data?.response?.incomeSource || "",
        }));

        // Fetch income source dropdown if business status code is 2
        if (response?.data?.businessStatusCode === 2) {
          const selectedOccupation = response?.data?.response?.occupation || "";
          try {
            const incomeSourceResponse = await propertyLoanFetchDataAPI({
              source: "json_value",
              type: selectedOccupation, // Send occupation as the 'type'
              key: "INCOME_SOURCE",
            });

            // Assuming response?.data?.[0]?.List contains the dropdown data
            setIncomeSourceDropdown(incomeSourceResponse?.data?.[0]?.List || []);
          } catch (error) {
            console.error("Error fetching income source data:", error);
          }
        }

        // Extract firm details from the response
        const firmDetailsData = response?.data?.response;
        console.log("Firm Details Data:", firmDetailsData);

        // If firm details exist, extract bank details
        if (firmDetailsData) {
          const firmDetail = firmDetailsData;
          setFirmDetails(firmDetail);
          console.log("Firm Detail:", firmDetail);

          const bankAccDetails = firmDetail?.bankDetails;
          console.log("Bank Account Details:", bankAccDetails);
          setBankDetails(bankAccDetails && Object.keys(bankAccDetails).length > 0 ? bankAccDetails : false);

          const documentDetails = firmDetail?.supportingDocuments;
          setSelectedDocs({ document: documentDetails }); // Update state with document details

          // const documentDetails = firmDetail?.supportingDocuments || []; // Default to empty array if undefined
          // console.log("Document Details:", documentDetails);

          // Set bank details if present, otherwise set to false




          // setSelectedDocs({ document: documentDetails }); // Update state with document details
        } else {
          setBankDetails(false);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchProfessionalDetails();
  }, []);


  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor: isFormValid
      ? "rgba(255, 115, 0, 1)"
      : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const removeExistingDoc = (documentType) => {
    setSelectedDocs((prevState) => {
      // Filter out the document with the given documentType
      const updatedDocuments = prevState.document.filter(
        (doc) => doc.documentType !== documentType
      );

      // Update the state with the updated array
      return {
        ...prevState,
        document: updatedDocuments,
      };
    });
  };

  const handlePopupClose = (selectedFileNames) => {
    // If selectedFileNames is empty array, it means all documents were removed
    if (selectedFileNames.length === 0) {
      setSelectedDocs(prev => ({
        ...prev,
        // document: []
      }));
    } else {
      // Update with new document list
      setSelectedDocs(prev => ({
        ...prev,
        document: selectedFileNames
      }));
    }
    setPopupOpen(false);
  };

  const handleFileChangeID = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          registrationCertificateDoc: {
            ...formData.registrationCertificateDoc,
            documentData: e.target.result.split(",")[1], // Assuming base64 data
            documentName: file.name,
            documentType: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    }
    validateForm(); // Validate after file upload
  };

  const cancelBankDialog = () => {
    setOpenBankDialog(false);
  };

  const onBankDataSave = (data) => {
    setBankDetails(data);
    setOpenBankDialog(false);
  };

  const handleBack = async () => {
    try {
      const response = await propertyLoanBackPageAPI();
      if (response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
      } else {
        toast.info(response.data.message);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };
  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };
  
  return (
    <>
      <div
        onClick={goToSummaryPage}
        className="col-12 text-end fw-bold py-1"
        style={{
          color: "orange",
          marginLeft: "auto",
          textDecoration: "none",
          cursor: "pointer",

        }}
      >
        Go To Applicant Summary
      </div>

      <div className="mt-1">
        <h6 className="text mt-4" style={{ fontWeight: 750 }}>
          Qualification & Income
        </h6>
        <p className="text fw-normal">
          Share Your Academic background,Monthly Income/Obligation and Bank
          account details (as applicable)
        </p>
      </div>

      <form className="mt-5">
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Qualification</InputLabel>
              <Select
                labelId="qualification-label"
                id="qualification"
                name="qualification"
                value={formData.qualification}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    qualification: e.target.value,
                  })
                }
              >
                {qualificationDropdown &&
                  qualificationDropdown.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </ArthaFormControl>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Occupation</InputLabel>
              <Select
                labelId="qualification-label"
                id="occupation"
                name="occupation"
                value={formData.occupation}
                onChange={async (e) => {
                  const selectedOccupation = e.target.value;
                  setFormData({
                    ...formData,
                    occupation: selectedOccupation,
                  });

                  try {
                    const response = await propertyLoanFetchDataAPI({
                      source: "json_value",
                      type: selectedOccupation,
                      key: "INCOME_SOURCE",
                    });

                    // Assuming response?.data?.[0]?.List contains the dropdown data
                    setIncomeSourceDropdown(response?.data?.[0]?.List);
                  } catch (error) {
                    console.error("Error fetching income source data:", error);
                  }
                }}
              >
                {occupationDropdown &&
                  occupationDropdown.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </ArthaFormControl>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaFormControl fullWidth>
              <InputLabel id="qualification-label">Income Source</InputLabel>
              <Select
                labelId="qualification-label"
                id="incomeSource"
                name="incomeSource"
                value={formData.incomeSource}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    incomeSource: e.target.value,
                  })
                }
                disabled={!formData.occupation}
              >
                {incomeSourceDropdown &&
                  incomeSourceDropdown.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </ArthaFormControl>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <TextField
              id="outlined-basic"
              name="monthlyIncome"
              label="Monthly Income (₹)"
              fullWidth
              variant="outlined"
              value={formData.monthlyIncome}
              onChange={handleChange}
              // InputProps={{
              //   style: {
              //     height: "50px", // Adjust the height as needed
              //   },
              // }}
              // inputProps={{
              //   style: {
              //     padding: "18px 14px", // Adjust padding to center the text vertically
              //   },
              // }}
            />
          </div>
          <div className="col-md-6">
            <TextField
              id="outlined-basic"
              name="monthlyObligations"
              label="Monthly Obligations (₹)"
              fullWidth
              variant="outlined"
              value={formData.monthlyObligations}
              onChange={handleChange}
              // InputProps={{
              //   style: {
              //     height: "50px", // Adjust the height as needed
              //   },
              // }}
              // inputProps={{
              //   style: {
              //     padding: "18px 14px", // Adjust padding to center the text vertically
              //   },
              // }}
            />
          </div>
        </div>

        <div className="row mt-3 mt-md-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-4">
            <div className="row">
              <div className="col-8 col-md-7 mt-3">
                <p className="msg">Bank Account Details</p>

                {bankDetails ? (
                  <>
                    <FaCheckCircle
                      className="badge-icon"
                      style={{ marginTop: "-7px", color: "green" }}
                    />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {" "}
                      Bank Details Added!
                    </span>
                  </>
                ) : (
                  <>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      {" "}
                      Not Added
                    </span>
                  </>
                )}
              </div>
              <div className="col-4 col-md-5">
                <input
                  type="file"
                  ref={fileInputRefID}
                  style={{ display: "none" }}
                  onChange={handleFileChangeID}
                />
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none mt-4"
                  onClick={() => {
                    setOpenBankDialog(true);
                  }}
                >
                  {bankDetails ? "Edit" : "Add Bank"}
                </Button>
                <AddBankRetried
                  openBankDialog={openBankDialog}
                  onCancel={cancelBankDialog}
                  onBankDataSave={onBankDataSave}
                  firmDetails={firmDetails} // Pass firm details here
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md- col-lg-6 mt-4">
            <div className="row mt-3">
            <div className="col-9">
                <h6 className="text font-weight-bold">Supporting Documents (Optional)</h6>
                <p className="msg mt-2" style={{ color: "blue" }}>
                You may attach any previous employment or income related
                documents
                </p>
                {selectedDocs?.document?.length > 0 ? (
                  selectedDocs.document.map((doc, index) => (
                    <React.Fragment key={`${doc.documentType}-${index}`}>
                      <FaCheckCircle
                        className="badge-icon"
                        style={{ marginTop: "-7px", color: "green" }}
                      />
                      <span
                        className="mt-2 upload-lable"
                        style={{ position: "relative", top: "-10px" }}
                      >
                        {doc.documentType} <br />
                      </span>
                    </React.Fragment>
                  ))
                ) : (
                  <div>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      Not Uploaded
                    </span>
                  </div>
                )}
              </div>
              <div className="col-2">
                <input
                  type="file"
                  //   ref={fileInputRefID}
                  style={{ display: "none" }}
                  onChange={handleFileChangeID}
                />
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none mt-5"
                  onClick={handleUploadDocument}
                >
                  Upload
                </Button>
                <UploadRetriedDoc
                  open={isPopupOpen}
                  existingDocuments={selectedDocs.document}
                  onRemove={removeExistingDoc}
                  handleClose={handlePopupClose}
                  occupation={formData.occupation}
                  incomeSource={formData.incomeSource}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6 d-flex justify-content-end mt-4">
            <ArthaClearButton variant="contained" onClick={handleBack}>
              Back
            </ArthaClearButton>
          </div>

          <div className="col-6 d-flex justify-content-start mt-4">
            <ArthaContinueButton
              variant="contained"
              onClick={handleContinue}
              disabled={!isFormValid}
              style={{
                backgroundColor: isFormValid
                  ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                  : "rgb(255 98 0 / 39%)",
                opacity: isButtonClicked ? 0.39 : 1,
                pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
              }}
            >
              {isLoading3 ? (
                <LoadingSpinner style={{ pointerEvents: "none" }} />
              ) : (
                "Continue"
              )}
            </ArthaContinueButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default QualificationIncomeRetriedForm;
