import React, { useState, useRef, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    FormControlLabel,
    Radio,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog
} from "@mui/material";
import {
    FaIdBadge,
    FaCheck,
    FaExclamationTriangle,
    FaExclamationCircle,
} from "react-icons/fa";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";

import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import UploadDocument from "./UploadDocument";
import { propertyLoanfetchDistStateAPI } from "../../actioncreators/actioncreators";
export const EmployeerForm = ({ setPrev2Info, prev2Info, setPrev1Info, prev1Info, isPrev1AddressAdded, isPrev2AddressAdded, setPrev1Address, setPrev2Address, prev1Address, prev2Address, setPrev1AddressAdded, setPrev2AddressAdded, previousEmployerList, setPreviousEmployerList, updatePrevDoc, currentIndex, previousEmployeerDocuments, setPreviousEmployeerTillDate, setPreviousEmployeerFromDate, previousEmployeerFromDate, previousEmployeerTillDate, employeerCategoryList, setPreviousEmployeerType, previousEmployeerType, res, minDate, formData, selectedEmployeerNames, previoudEmployerClick, setFormData, workedFromCounter, workedTillCounter, getWorkTill, bankNames, selectedPreviuosEmployer, fileName2, fileInputRefID2, handleFileChangeID2, handleUploadButtonClickID2, employeers, prevCount, isValidateEmployeerForm }) => {
    const [isFormFilled, setIsFormFilled] = useState(false);
    let [minDates, setMinDates] = React.useState([]);
    useEffect(() => {
        setMinDates(previousEmployeerFromDate);
    }, [previousEmployeerFromDate])


    const [customText, setCustomText] = useState([]);

    const handlePreviousNewEmployerChange = (e, index) => {
        const newValue = e.target.value;

        setCustomText((prevCustomText) => {
            const updatedCustomText = [...prevCustomText];
            updatedCustomText[index] = newValue;
            return updatedCustomText;
        });

        const updatedPreviousEmployers = [...formData.previousEmployer];
        updatedPreviousEmployers[index] = newValue;

        setFormData({
            ...formData,
            previousEmployer: updatedPreviousEmployers,
        });
    };
    const handleTextFieldClick = (event, index) => {
        // Stop event propagation to prevent closing the dropdown
        //customText[ index ] = "";
        event.stopPropagation();
    };
    const handlePreviousEmployerChange = (e, index) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [...formData.previousEmployer];
        updatedPreviousEmployers[index] = newValue;

        setFormData({
            ...formData,
            previousEmployer: updatedPreviousEmployers,
        });
    };

    const handlePreviousFromChange = (e, index) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [...formData.workedFrom];
        updatedPreviousEmployers[index] = newValue;

        const updatedpreviousEmployeerFromDate = [...previousEmployeerFromDate];
        updatedpreviousEmployeerFromDate[index] = newValue;

        setFormData({
            ...formData,
            workedFrom: updatedPreviousEmployers,
        });

        setPreviousEmployeerFromDate(updatedpreviousEmployeerFromDate);

    };


    const [dateValidationError, setDateValidationError] = useState('');
    // const handlePreviousWorkedTillChange = ( e, index ) => {
    //     let fromDate = previousEmployeerFromDate[ index ];
    //     let currentDate = e.target.value;

    //     if ( currentDate <= fromDate ) {
    //         setDateValidationError( 'Worked Till date should be greater than Working From date' );
    //     } else {
    //         setDateValidationError( '' ); // Reset error message if the date is valid

    //         const newValue = e.target.value;
    //         const updatedPreviousEmployers = [ ...formData.workedTill ];
    //         updatedPreviousEmployers[ index ] = newValue;

    //         const updatedpreviousEmployeerTillDate = [ ...previousEmployeerTillDate ];
    //         updatedpreviousEmployeerTillDate[ index ] = newValue;

    //         setFormData( {
    //             ...formData,
    //             workedTill: updatedPreviousEmployers,
    //         } );

    //         setPreviousEmployeerTillDate( updatedpreviousEmployeerTillDate );
    //     }
    // };

    // const handlePreviousWorkedTillChange = (e, index) => { 
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     const newObj = { ...workedTiiList };
    //     newObj[name] = value;
    //     setWorkTillList(newObj);

    //     const fromDate = previousEmployeerFromDate[index];//4 .  14
    //     let currentDateValue = '';
    //     if (index == 0) {
    //         currentDateValue = workedTiiList['workedTill-0'];
    //     } else {
    //         currentDateValue = workedTiiList['workedTill-1'];
    //     }
    //     const currentDate = currentDateValue;
    //     if (previousEmployeerTillDate[prevCount - 1]) {
    //         if (currentDate >= previousEmployeerFromDate[prevCount - 1] || currentDate <= fromDate) {
    //             setDateValidationError('Worked Till date should be greater than current employer start date');
    //         } else {
    //             setDateValidationError(''); // Reset error message if the date is valid

    //             const updatedPreviousEmployers = [...formData.workedTill];
    //             updatedPreviousEmployers[index] = currentDate;

    //             const updatedpreviousEmployeerTillDate = [...previousEmployeerTillDate];
    //             updatedpreviousEmployeerTillDate[index] = currentDate;

    //             setFormData({
    //                 ...formData,
    //                 workedTill: updatedPreviousEmployers,
    //             });

    //             setPreviousEmployeerTillDate(updatedpreviousEmployeerTillDate);
    //             getWorkTill(currentDateValue, index);
    //         }
    //     } else {
    //         if (currentDate >= formData.workingSince || currentDate <= fromDate) {
    //             setDateValidationError('Worked Till date should be greater than Working From date');
    //         } else {
    //             setDateValidationError(''); // Reset error message if the date is valid

    //             const updatedPreviousEmployers = [...formData.workedTill];
    //             updatedPreviousEmployers[index] = currentDate;

    //             const updatedpreviousEmployeerTillDate = [...previousEmployeerTillDate];
    //             updatedpreviousEmployeerTillDate[index] = currentDate;

    //             setFormData({
    //                 ...formData,
    //                 workedTill: updatedPreviousEmployers,
    //             });

    //             setPreviousEmployeerTillDate(updatedpreviousEmployeerTillDate);
    //             getWorkTill(currentDateValue, index);
    //         }
    //     }

    // };
    const handleDateChange = (e, index) => {
        const value = e.target.value; // Date selected by the user
        const formattedDate = new Date(value).toISOString().split("T")[0]; // Standardize the date format

        // Update `workedTiiList` with the new standardized date value
        const updatedWorkedTiiList = { ...workedTiiList, [`workedTill-${index}`]: formattedDate };
        setWorkTillList(updatedWorkedTiiList);

        // Perform validation checks
        const fromDate = previousEmployeerFromDate[index];

        // Case where there are previous employer dates to check against
        if (index > 0 && previousEmployeerTillDate[index - 1]) {
            if (formattedDate >= previousEmployeerFromDate[index - 1] || formattedDate <= fromDate) {
                setDateValidationError("Worked Till date should be greater than current employer start date");
                return; // Exit if there's an error to avoid updating further
            } else {
                setDateValidationError(""); // Reset error if valid
            }
        } else {
            // Initial case where there's only a single date range to validate
            if (formattedDate >= formData.workingSince || formattedDate <= fromDate) {
                setDateValidationError("Worked Till date should be greater than Working From date");
                return;
            } else {
                setDateValidationError(""); // Reset error if valid
            }
        }

        // If valid, update all relevant states with the standardized date
        const updatedPreviousEmployers = [...formData.workedTill];
        updatedPreviousEmployers[index] = formattedDate;

        const updatedPreviousEmployeerTillDate = [...previousEmployeerTillDate];
        updatedPreviousEmployeerTillDate[index] = formattedDate;

        setFormData({
            ...formData,
            workedTill: updatedPreviousEmployers,
        });

        setPreviousEmployeerTillDate(updatedPreviousEmployeerTillDate);
        getWorkTill(formattedDate, index); // Trigger any necessary side effects
    };


    const [emailValidationError, setEmailValidationError] = useState(false);
    const handlePreviousPreviousOfficialEmailIDChange = (e, index) => {
        const newValue = e.target.value;

        // Check if the value is empty
        if (newValue.trim() === '') {
            setEmailValidationError(false);
        } else {
            // Validate the email format
            const isValidEmail =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);



            if (!isValidEmail) {
                setEmailValidationError(true);
            } else {
                setEmailValidationError(false);
            }
        }

        const updatedPreviousEmployers = [...formData.previousOfficialEmailID];
        updatedPreviousEmployers[index] = newValue;

        setFormData({
            ...formData,
            previousOfficialEmailID: updatedPreviousEmployers,
        });
    };

    const calculateMaxWorkedTillDate = (index) => {
        const workingSinceDate = new Date(formData.workingSince);
        const oneDayBeforeWorkingSince = new Date(workingSinceDate);
        oneDayBeforeWorkingSince.setDate(workingSinceDate.getDate() - 1);

        if (previousEmployeerFromDate[index - 1]) {
            const maxDate = new Date(previousEmployeerFromDate[index - 1]);
            if (!isNaN(maxDate.getTime())) {
                maxDate.setDate(maxDate.getDate() - 1);
                return maxDate.toISOString().split("T")[0];
            }
        } else {
            if (!isNaN(oneDayBeforeWorkingSince.getTime())) {
                return oneDayBeforeWorkingSince.toISOString().split("T")[0];
            }
        }
    };


    const handlePreviousEmployerTypeChange = (e, index) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [...formData.previousEmployerType];
        updatedPreviousEmployers[index] = newValue;

        const updatedEmployeerCategory = [...previousEmployeerType];
        updatedEmployeerCategory[index] = newValue;

        setFormData({
            ...formData,
            previousEmployerType: updatedPreviousEmployers,
        });

        setPreviousEmployeerType(updatedEmployeerCategory)
    };

    const handlePreviousBankChange = (e, index) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [...formData.previousSalaryAccountBank];
        updatedPreviousEmployers[index] = newValue;

        setFormData({
            ...formData,
            previousSalaryAccountBank: updatedPreviousEmployers,
        });
    };


    //Document Popup
    const [isPopupOpen1, setPopupOpen1] = useState(false);
    const [fileNames1, setFileNames1] = useState([]);
    const [isIDUploaded2, setIsIDUploaded2] = useState(false);
    const handleUploadDocument = () => {
        setPopupOpen1(true);
    };
    const handlePopupClose1 = (selectedFileNames) => {
        if (selectedFileNames.length > 0) {
            updatePrevDoc(currentIndex, selectedFileNames);
        }

        setIsIDUploaded2(true)
        setPopupOpen1(false);
    };
    // const isFormValid = () => {

    //     const isPreviousEmployerFilled = formData.previousEmployer.every(value => value !== "");
    //     const isPreviousEmployerTypeFilled = previousEmployeerType.every(value => value !== "");
    //     const isWorkedFromFilled = formData.workedFrom.every(value => value !== "");
    //     const isWorkedTillFilled = formData.workedTill.every(value => value !== "");
    //     const isSalaryAccountBankFilled = formData.previousSalaryAccountBank.every(value => value !== "");


    //     return (
    //         isPreviousEmployerFilled &&
    //         isPreviousEmployerTypeFilled &&
    //         isWorkedFromFilled &&
    //         isWorkedTillFilled &&
    //         isSalaryAccountBankFilled &&
    //         (isValidEmailFlag1 && isValidEmailFlag2)

    //     );
    // };

    const isFormValid = () => {
        // Required fields validation
        const requiredFields = {
            previousEmployer: formData.previousEmployer[prevCount]?.trim(),
            employerType: previousEmployeerType[prevCount]?.trim(),
            designation: prevCount === 0 ? prev1Info.designation?.trim() : prev2Info.designation?.trim(),
            workedFrom: previousEmployeerFromDate[prevCount]?.trim(),
            workedTill: previousEmployeerTillDate[prevCount]?.trim(),
            salaryAccountBank: formData.previousSalaryAccountBank[prevCount]?.trim(),
            accountNumber: prevCount === 0 ? prev1Info.accNo?.trim() : prev2Info.accNo?.trim()
        };

        // Address validation
        const isAddressValid = prevCount === 0 ? isPrev1AddressAdded : isPrev2AddressAdded;


        // Check if all required fields are filled
        const areRequiredFieldsFilled = Object.values(requiredFields).every(value =>
            value !== undefined && value !== '' && value !== null
        );

        let isValidEmailFlag1 = true;
        let isValidEmailFlag2 = true;
        if (formData.previousOfficialEmailID) {
            for (let index = 0; index < formData.previousOfficialEmailID.length; index++) {
                const element = formData.previousOfficialEmailID[index];
                if (element) {
                    if (element != "") {
                        if (index == 0) {
                            isValidEmailFlag1 = isValidEmail(element);
                        } if (index == 1) {
                            isValidEmailFlag2 = isValidEmail(element);
                        }
                    }
                }

            }
        }
        // Date validation
        const isDateValid = !dateValidationError &&
            previousEmployeerFromDate[prevCount] &&
            previousEmployeerTillDate[prevCount];

        return areRequiredFieldsFilled && isAddressValid && isDateValid;
    };


    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };
    // useEffect(() => {
    //     checkFormFilled();
    //     isValidateEmployeerForm(isFormValid());
    // }, [formData, prev1Address, isFormFilled]);
    useEffect(() => {
        checkFormFilled();
        const isValid = isFormValid();
        isValidateEmployeerForm(isValid);
    }, [
        formData,
        previousEmployeerType,
        previousEmployeerFromDate,
        previousEmployeerTillDate,
        isPrev1AddressAdded,
        isPrev2AddressAdded,
        prev1Address,
        prev2Address,
        prev1Info,
        prev2Info,
        dateValidationError,
        isFormFilled
    ]);

    const removeExistingDoc = (documentType) => {
        let updatedList = [...previousEmployerList];

        // Make a copy of the document array at the specified currentIndex
        let updatedDocuments = [...updatedList[currentIndex].document];

        // Filter out the document with the given documentType
        updatedDocuments = updatedDocuments.filter(doc => doc.documentType !== documentType);

        // Update the document array at the specified currentIndex in the updatedList array
        updatedList[currentIndex] = { ...updatedList[currentIndex], document: updatedDocuments };

        // Update the state with the updated list
        setPreviousEmployerList(updatedList);
    }

    const [openPrev1AddressDialog, setOpenPrev1AddressDialog] = useState(false);
    const openPrev1AddrDialog = () => {
        setOpenPrev1AddressDialog(true);
        setPincodeError('');
    }
    const closePrev1AddrDialog = () => {
        if (isPrev1AddressAdded) {
            setOpenPrev1AddressDialog(false);
        } else {
            setOpenPrev1AddressDialog(false);
            setPrev1Address({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }
    const [openPrev2AddressDialog, setOpenPrev2AddressDialog] = useState(false);
    const openPrev2AddrDialog = () => {
        setOpenPrev2AddressDialog(true)
    }
    const closePrev2AddrDialog = () => {
        if (isPrev2AddressAdded) {
            setOpenPrev2AddressDialog(false);
        } else {
            setOpenPrev2AddressDialog(false);
            setPrev2Address({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }

    }

    const [pincodeError, setPincodeError] = React.useState('');
    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setPrev1Address(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
        checkFormFilled();
    }


    const handlePrev2AddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setPrev2Address(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
    }

    // const handlePrev1Info = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     setPrev1Info(prevAddress => ({
    //         ...prevAddress,
    //         [name]: value
    //     }));
    // }
    const handlePrev1Info = (e) => {
        const { name, value } = e.target;
        let cleanedValue = value;
      
        // Remove spaces for employerWebsite and non-alphanumeric characters for accNo
        if (name === "employerWebsite") {
          cleanedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
        } else if (name === "accNo") {
          cleanedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
        }
      
        setPrev1Info((prev) => ({
          ...prev,
          [name]: cleanedValue,
        }));
      };

    // const handlePrev2Info = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     setPrev2Info(prevAddress => ({
    //         ...prevAddress,
    //         [name]: value
    //     }));
    // }
    const handlePrev2Info = (e) => {
        const { name, value } = e.target;
        let cleanedValue = value;
      
        // Remove spaces for employerWebsite and non-alphanumeric characters for accNo
        if (name === "employerWebsite") {
          cleanedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
        } else if (name === "accNo") {
          cleanedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
        }
      
        setPrev2Info((prev) => ({
          ...prev,
          [name]: cleanedValue,
        }));
      };

    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);

    const checkFormFilled = () => {
        if (!prev1Address) return;

        const { line1, line2, pincode, city, state } = prev1Address;

        const isLine1Filled = line1 && line1.trim() !== '';
        const isLine2Filled = line2 && line2.trim() !== '';
        const isPincodeFilled = pincode && pincode.trim() !== '';
        const isCityFilled = city && city.trim() !== '';
        const isStateFilled = state && state.trim() !== '';

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }
    };

    const [workedTiiList, setWorkTillList] = React.useState({
        'workedTill-0': '',
        'workedTill-1': ''
    });
    const setTillDateArray = (e, index) => {
        let name = e.target.name;
        let value = e.target.value;
        const newObj = { ...workedTiiList };
        newObj[name] = value;
        setWorkTillList(newObj);
    }

    const fetchAddressDetails = async (pincode) => {
        try {
            let data = {
                pinCode: pincode,
            };
            const response = await propertyLoanfetchDistStateAPI(data);
            const { district, stateName } = response.data;
            setPrev1Address((prevState) => ({
                ...prevState,
                city: district || "",
                state: stateName || "",
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
            setPrev1Address((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    };
    const fetchAddressDetails2 = async (pincode) => {
        try {
            let data = {
                pinCode: pincode,
            };
            const response = await propertyLoanfetchDistStateAPI(data);
            const { district, stateName } = response.data;
            setPrev2Address((prevState) => ({
                ...prevState,
                city: district || "",
                state: stateName || "",
            }));
        } catch (error) {
            console.error("Error fetching address details:", error);
            setPrev2Address((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    };

    return (
        <div className="row mt-2">
            <div className="col-12 col-md-6 col-lg-6 ">
                {res.employerDetails ?
                    <ArthaFormControl fullWidth>
                        <InputLabel id="previousEmployer-label">
                            Previous Employer Name
                        </InputLabel>
                        <Select
                            labelId="previousEmployer-label"
                            id="previousEmployer"
                            name={`previousEmployer-${prevCount}`}
                            renderValue={(value) => (value === 'editable' ? customText[prevCount] : value)}
                            value={formData.previousEmployer[prevCount] || ""}
                            onChange={(e) => handlePreviousEmployerChange(e, prevCount)}
                        >
                            <MenuItem value="">Previous Employer Name</MenuItem>
                            {res.employerDetails &&
                                res.employerDetails.map((item, index) => (
                                    selectedEmployeerNames.includes(item.companyName) ? null : (
                                        <MenuItem
                                            key={index}
                                            value={item.companyName}
                                            onClick={() => {
                                                previoudEmployerClick(index);
                                            }}
                                        >
                                            {item.companyName}
                                        </MenuItem>
                                    )
                                ))}
                            <MenuItem value="editable" style={{ display: "flex", flexDirection: "column" }}>
                                <TextField
                                    placeholder="add new employeer"
                                    name={`previousEmployer-${prevCount}`}
                                    value={customText[prevCount] || ""}
                                    onChange={(e) => handlePreviousNewEmployerChange(e, prevCount)}
                                    onClick={(e) => { handleTextFieldClick(e, prevCount) }}
                                    label="New employeer"
                                    style={{ width: '100%' }}
                                    className="employeer-field"
                                    autoComplete="off"
                                    InputProps={{
                                        onKeyDown: (e) => e.stopPropagation(), // Prevent dropdown interaction while typing
                                    }}
                                />
                            </MenuItem>
                        </Select>
                    </ArthaFormControl> :
                    <ArthaFormControl fullWidth>
                        <InputLabel id="previousEmployer-label">
                            Previous Employer Name
                        </InputLabel>
                        <Select
                            labelId="previousEmployer-label"
                            id="previousEmployer"
                            name={`previousEmployer-${prevCount}`}
                            renderValue={(value) => (value === 'editable' ? customText[prevCount] : value)}
                            value={formData.previousEmployer[prevCount] || ""}
                            onChange={(e) => handlePreviousEmployerChange(e, prevCount)}
                        >
                            <MenuItem value="">Previous Employer Name</MenuItem>

                            <MenuItem value="editable" style={{ display: "flex", flexDirection: "column" }}>
                                <TextField
                                    placeholder="add new employeer"
                                    name={`previousEmployer-${prevCount}`}
                                    value={customText[prevCount] || ""}
                                    onChange={(e) => handlePreviousNewEmployerChange(e, prevCount)}
                                    onClick={(e) => { handleTextFieldClick(e, prevCount) }}
                                    label="New employeer"
                                    style={{ width: '100%' }}
                                    className="employeer-field"
                                    autoComplete="off"
                                    InputProps={{
                                        onKeyDown: (e) => e.stopPropagation(), // Prevent dropdown interaction while typing
                                    }}
                                />
                            </MenuItem>
                        </Select>
                    </ArthaFormControl>
                }
            </div>

            <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-0">
                <ArthaFormControl fullWidth>
                    <InputLabel id="previousEmployerType">
                        Previous Employer Type
                    </InputLabel>
                    <Select
                        labelId="previousEmployerType"
                        id="previousEmployerType"
                        name={`previousEmployerType-${prevCount}`}
                        value={previousEmployeerType[prevCount] || ""}
                        onChange={(e) => handlePreviousEmployerTypeChange(e, prevCount)}
                    >
                        <MenuItem value="">Previous Employer Type</MenuItem>
                        {employeerCategoryList.map((item) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </ArthaFormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <span className="msg">Employer Address</span><br />
                {prevCount == 0 ? <>
                    <div className="row">
                        {isPrev1AddressAdded ? <>
                            <div className="col-8">
                                <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "5px !important" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{}}
                                >
                                    {" "}
                                    Address Added!
                                </span>
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none"
                                    onClick={openPrev1AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Edit
                                </Button>
                            </div>
                        </> : <>
                            <div className="col-7">
                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "0" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{}}
                                >
                                    {" "}
                                    Not added
                                </span>
                            </div>
                            <div className="col-5">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none "
                                    onClick={openPrev1AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Add Address
                                </Button>
                            </div>

                        </>}
                    </div>
                </> : <>
                    <div className="row">
                        {isPrev2AddressAdded ? <>
                            <div className="col-8">
                                <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "0" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{ margin: "0", fontSize: '11px' }}
                                >
                                    {" "}
                                    Address Added!
                                </span>
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none "
                                    onClick={openPrev2AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Edit
                                </Button>
                            </div></> : <>
                            <FaExclamationCircle className="badge-icon" />
                            <span
                                className="mt-2 upload-lable"
                                style={{ position: "relative", top: "-10px" }}
                            >
                                {" "}
                                Not added
                            </span>
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={openPrev2AddrDialog}
                                style={{ fontSize: '11px !important' }}
                            >
                                Add Address
                            </Button></>}
                    </div>
                </>}

                <>
                    <Dialog
                        open={openPrev1AddressDialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="modal row">
                            <div className="modal-content col-md-6">
                                {!isPrev1AddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line1"
                                            fullWidth
                                            value={prev1Address && prev1Address.line1}
                                            onChange={(e) => { handleAddressChange(e) }}
                                            label="Address Line 1"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line2"
                                            fullWidth
                                            value={prev1Address && prev1Address.line2}
                                            onChange={(e) => { handleAddressChange(e) }}
                                            label="Address Line 2"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="pincode"
                                            fullWidth
                                            value={prev1Address && prev1Address.pincode}
                                            onChange={(e) => {
                                                const inputValue = e.target.value
                                                    .replace(/\D/g, "")
                                                    .slice(0, 6);
                                                handleAddressChange({
                                                    target: {
                                                        name: "pincode",
                                                        value: inputValue,
                                                    },
                                                });
                                                setPincodeError(
                                                    inputValue.length === 6
                                                        ? ""
                                                        : "Invalid pincode"
                                                );

                                                if (inputValue.length === 6) {
                                                    fetchAddressDetails(inputValue);
                                                } else {
                                                    // Clear city and state if pincode is cleared
                                                    setPrev1Address((prevState) => ({
                                                        ...prevState,
                                                        city: "",
                                                        state: "",
                                                    }));
                                                }
                                                checkFormFilled();
                                            }}
                                            label="Pincode"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {pincodeError}
                                        </span>
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="city"
                                            fullWidth
                                            value={prev1Address && prev1Address.city}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(
                                                    /[^a-zA-Z]/g,
                                                    ""
                                                );
                                                handleAddressChange({
                                                    target: {
                                                        name: "city",
                                                        value: inputValue,
                                                    },
                                                });
                                            }}
                                            label="City"
                                            InputLabelProps={{ shrink: true }} // Add this line
                                        />
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="state"
                                            fullWidth
                                            value={prev1Address && prev1Address.state}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(
                                                    /[^a-zA-Z]/g,
                                                    ""
                                                );
                                                handleAddressChange({
                                                    target: {
                                                        name: "state",
                                                        value: inputValue,
                                                    },
                                                });
                                            }}
                                            label="State"
                                            InputLabelProps={{ shrink: true }} // Add this line
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 mx-auto">
                                    <div className="col-6 d-flex justify-content-end mt-4">
                                        <ArthaClearButton variant="contained" onClick={closePrev1AddrDialog}>
                                            Cancel
                                        </ArthaClearButton>
                                    </div>
                                    <div className="col-6 d-flex justify-content-start mt-4">
                                        <ArthaContinueButton
                                            variant="contained"
                                            disabled={!isFormFilled}
                                            onClick={() => { setOpenPrev1AddressDialog(false); setPrev1AddressAdded(true) }}
                                        >
                                            Save
                                        </ArthaContinueButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </>

                <>
                    <Dialog
                        open={openPrev2AddressDialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="modal row">
                            <div className="modal-content col-md-6">
                                {!isPrev2AddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line1"
                                            fullWidth
                                            value={prev2Address && prev2Address.line1}
                                            onChange={(e) => { handlePrev2AddressChange(e) }}
                                            label="Address Line 1"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line2"
                                            fullWidth
                                            value={prev2Address && prev2Address.line2}
                                            onChange={(e) => { handlePrev2AddressChange(e) }}
                                            label="Address Line 2"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="pincode"
                                            fullWidth
                                            value={prev2Address && prev2Address.pincode}
                                            onChange={(e) => {
                                                const inputValue = e.target.value
                                                    .replace(/\D/g, "")
                                                    .slice(0, 6);
                                                handlePrev2AddressChange({
                                                    target: {
                                                        name: "pincode",
                                                        value: inputValue,
                                                    },
                                                });
                                                setPincodeError(
                                                    inputValue.length === 6
                                                        ? ""
                                                        : "Invalid pincode"
                                                );

                                                if (inputValue.length === 6) {
                                                    fetchAddressDetails2(inputValue);
                                                } else {
                                                    // Clear city and state if pincode is cleared
                                                    setPrev2Address((prevState) => ({
                                                        ...prevState,
                                                        city: "",
                                                        state: "",
                                                    }));
                                                }
                                                checkFormFilled();
                                            }}
                                            label="Pincode"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <span style={{ color: "red", fontSize: "12px" }}>
                                            {pincodeError}
                                        </span>
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="city"
                                            fullWidth
                                            value={prev2Address && prev2Address.city}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(
                                                    /[^a-zA-Z]/g,
                                                    ""
                                                );
                                                handlePrev2AddressChange({
                                                    target: {
                                                        name: "city",
                                                        value: inputValue,
                                                    },
                                                });
                                            }}
                                            label="City"
                                            InputLabelProps={{ shrink: true }} // Add this line
                                        />
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="state"
                                            fullWidth
                                            value={prev2Address && prev2Address.state}
                                            onChange={(e) => {
                                                const inputValue = e.target.value.replace(
                                                    /[^a-zA-Z]/g,
                                                    ""
                                                );
                                                handlePrev2AddressChange({
                                                    target: {
                                                        name: "state",
                                                        value: inputValue,
                                                    },
                                                });
                                            }}
                                            label="State"
                                            InputLabelProps={{ shrink: true }} // Add this line
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 mx-auto">
                                    <div className="col-6 d-flex justify-content-end mt-4">
                                        <ArthaClearButton variant="contained" onClick={closePrev2AddrDialog}>
                                            Cancel
                                        </ArthaClearButton>
                                    </div>
                                    <div className="col-6 d-flex justify-content-start mt-4">
                                        <ArthaContinueButton
                                            variant="contained"
                                            disabled={!isFormFilled}
                                            onClick={() => { setOpenPrev2AddressDialog(false); setPrev2AddressAdded(true) }}
                                        >
                                            Save
                                        </ArthaContinueButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="employerWebsite"
                    value={prevCount == 0 ? prev1Info.employerWebsite : prev2Info.employerWebsite}
                    onChange={(e) => prevCount == 0 ? handlePrev1Info(e) : handlePrev2Info(e)}
                    label="Employer Website (Optional)"
                    fullWidth
                />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="designation"
                    value={prevCount == 0 ? prev1Info.designation : prev2Info.designation}
                    onChange={(e) => prevCount == 0 ? handlePrev1Info(e) : handlePrev2Info(e)}
                    label="Designation"
                    fullWidth
                />
            </div>
            <div className="col-8 col-md-4 mt-3 mt-md-4 ">
                <p className="msg">ID Card (optional)</p>
                {(isIDUploaded2 || previousEmployeerDocuments && previousEmployeerDocuments.document) ? (
                    <>

                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                            {" "}
                            {
                                previousEmployeerDocuments && previousEmployeerDocuments.document ? previousEmployeerDocuments.document.map((item) => {
                                    return <p><FaCheck className="check-icon" /><span style={{ marginRight: '10px' }}>{item.documentType}</span></p>
                                }) : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                        {" "}
                                        Not Uploaded
                                    </span>
                                </>
                            }
                        </span>
                        {fileNames1.map((fileName, index) => (
                            <div
                                className="mt-2 upload-lable"
                                style={{ position: "relative", top: "-10px" }}
                                key={index}
                            >
                                <FaCheck className="check-icon" />
                                <span style={{ fontSize: "13px", position: "relative", top: "-9px" }}>{fileName}</span>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <FaExclamationCircle className="badge-icon" />
                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                            {" "}
                            Not Uploaded
                        </span>
                    </>
                )}
            </div>
            <div className="col-4 col-md-2 mt-4">
                <input
                    type="file"
                    ref={fileInputRefID2}
                    style={{ display: "none" }}
                    onChange={handleFileChangeID2}
                />
                <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={handleUploadDocument}
                >
                    Upload
                </Button>
                <UploadDocument
                    open={isPopupOpen1}
                    fileNames={fileNames1}
                    existingDocuments={previousEmployeerDocuments && previousEmployeerDocuments.document}
                    onRemove={removeExistingDoc}
                    handleClose={handlePopupClose1}

                />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="date"
                    name={`workedFrom-${prevCount}`}
                    value={previousEmployeerFromDate[prevCount] || ""}
                    onChange={(e) => handlePreviousFromChange(e, prevCount)}
                    InputProps={{
                        inputProps: { max: previousEmployeerTillDate[prevCount - 1] ? previousEmployeerFromDate[prevCount - 1] : formData.workingSince },
                    }}
                    className={`workedFrom${workedFromCounter}`}
                    label="Working From"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="date"
                    name={`workedTill-${prevCount}`}
                    value={previousEmployeerTillDate[prevCount] || ""}
                    // onChange={(e) => { setTillDateArray(e); }}
                    // onBlur={
                    //     (e) => {
                    //         handlePreviousWorkedTillChange(e, prevCount);
                    //     }
                    // }
                    onChange={(e) => handleDateChange(e, prevCount)} // Use combined function here
                    className={`workedTill${prevCount}`}
                    label="Worked Till"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            //max: calculateMaxWorkedTillDate( prevCount ),
                            //max: formData.workingSince,
                            min: previousEmployeerFromDate[prevCount],
                            max: new Date().toISOString().split("T")[0]
                        }
                    }}
                    fullWidth
                    error={Boolean(dateValidationError)}
                    helperText={dateValidationError}
                />
            </div>

            <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-4">
                <ArthaFormControl fullWidth>
                    <InputLabel id="previousSalaryAccount-label">
                        Salary Account Bank
                    </InputLabel>
                    <Select
                        labelId="previousSalaryAccount-label"
                        id="previousSalaryAccountBank"
                        name={`previousSalaryAccountBank-${prevCount}`}
                        value={formData.previousSalaryAccountBank[prevCount] || ""}
                        onChange={(e) => handlePreviousBankChange(e, prevCount)}

                    >
                        <MenuItem value="">Salary Account Bank</MenuItem>
                        {bankNames && bankNames.map((item) => {
                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                        })}
                    </Select>
                </ArthaFormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="accNo"
                    value={prevCount === 0 ? prev1Info.accNo : prev2Info.accNo}
                    onChange={(e) => (prevCount === 0 ? handlePrev1Info(e) : handlePrev2Info(e))}
                    label="Salary Account Number"
                    fullWidth
                    inputProps={{
                        maxLength: 80,
                        style: {
                            padding: '18px 14px', // Center text vertically
                        },
                    }}
                />
            </div>

        </div>
    )
}