import React, { useState, useEffect, useRef } from "react";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { FetchTermsAndCondition } from "../../actioncreators/actioncreators";

const TermsPopup = (props) => {
  const [termsMessage, setTermsMessage] = useState("");
  const navigate = useNavigate();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await FetchTermsAndCondition();
        // Process the terms and conditions to include HTML tags
        const formattedTerms = formatTerms(response.data.consentPageTermsAndConditions);
        setTermsMessage(formattedTerms);
        console.log("formattedTerms", formattedTerms);

      } catch (error) {
        handleApiError(error);
        console.error("Error fetching terms and conditions:", error);
      }
    };
    fetchData();
  }, []);

  // Html Handler function

  const formatTerms = (terms) => {
    // Check if terms contain HTML or need further processing
    if (typeof terms === 'string') {
      // Optionally sanitize or modify the HTML content here if needed
      // You can use libraries like DOMPurify to sanitize if required
      return terms;
    } else {
      // If terms are not in the expected format (string), return an empty string
      return '';
    }
  };

  const handleAcceptTerms = () => {
    props.onAcceptTerms();
    props.closeModal();
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <div>
      {/* Internal CSS for custom visible scrollbar */}
      <style>
        {`
          /* Ensure content is scrollable with proper settings */
          .modal-body {
            position: relative;
            max-height: 80vh;
            overflow-y: scroll;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
          }

          /* Custom Scrollbar Styles */
          .modal-body::-webkit-scrollbar {
            width: 16px;
          }

          .modal-body::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
          }

          .modal-body::-webkit-scrollbar-thumb {
            background: #555;
            border-radius: 10px;
            transition: background-color 0.3s ease;
          }

          .modal-body::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          /* For mobile screens, make the scrollbar more prominent */
          @media (max-width: 700px) {
            .modal-dialog {
              margin: 0.5rem;
              max-height: 80vh;
            }
            
            .modal-content {
              max-height: calc(80vh - 2rem);
            }

            .modal-body {
              max-height: calc(80vh - 200px);
              padding-right: 15px;
              overflow-y: scroll;
              -webkit-overflow-scrolling: touch;
            }

            .modal-body::-webkit-scrollbar {
              width: 16px;
            }

            .modal-body::-webkit-scrollbar-thumb {
              background: #555;
              height: 80px;
              visibility: visible;
            }

            /* Ensure content doesn't touch scrollbar */
            .modal-body > div {
              padding-right: 5px;
            }
          }
        `}
      </style>

      {props.isOpen && (
        <div className="modal termsPopup" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Terms & Conditions</h6>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: termsMessage }} />
              </div>
              <div className="modal-footer">
                <div className="d-flex justify-content-center w-100">
                  <ArthaContinueButton
                    variant="contained"
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    onClick={handleAcceptTerms}
                    disabled={props.isTermsAccepted}
                  >
                    Accept Terms
                  </ArthaContinueButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsPopup;
