// App.js
import React from 'react';
import './App.css';
import './global.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginModule from './components/Login/LoginModule';
import Sidebar from './components/Sidebar/Sidebar';
import { store, persistor } from "./store";
import Dashboard from './components/Sidebar/Pages/Dashboard';
import Header from './components/Sidebar/Header';
import Drawer from './components/Sidebar/Drawer';
import OtpForm from './components/Login/OtpForm';
import LoginForm from './components/Login/LoginForm';
import ToastProvider from './components/Sidebar/ToastProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import CustomerConsent from './components/Login/customerConsent';
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
    <ToastProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<LoginModule />} />
          <Route path="/customer/customer-consent" element={<CustomerConsent />} />
          <Route path='/home/*' element={<Sidebar />} />
        </Routes>
      </BrowserRouter>
    </ToastProvider>
    </PersistGate>
    </Provider>
  );
}

export default App;
