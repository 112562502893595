import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField1.js";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import {
  propertyLoanFetchDataAPI,
  propertyLoanStoreDetailsAPI,
  propertyLoanUserDetailsAPI,
  propertyLoanCreateLoanApplicationAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import { Select, MenuItem } from "@mui/material";
import PinCodeValidation from "./pinCodeValidation";
const BasicPropertyDetails = ({ onSubmit, onCancel,changeStatus }) => {

  const hasFetched = useRef(false); 
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [propertyTypeDropdown, setPropertyTypeDropdown] = React.useState([]);
  const [propertyJurisdictionDropdown, setPropertyJurisdictionDropdown] = React.useState([]);
  const [propertyAgeDropdown, setPropertyAgeDropdown] = React.useState([]);
  const [propertyOwnerDropdown, setPropertyOwnerDropdown] = React.useState([]);
  const [propertyOwnerNumberDropdown, setPropertyOwnerNumberDropdown] =
    React.useState([]);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false);
  const [pinCodeError, setPinCodeError] = useState("");
  const [pinCodeError1, setPinCodeError1] = useState("");
  const [pinCodeErrors, setPinCodeErrors] = useState([]);

  const [formData, setFormData] = useState({
    propertyType: "",
    propertyJurisdiction: "",
    propertyOwnership: "",
    propertyPin: "",
    numberOfOwners: "1",
    propertyAge: "",
    propertyValuation: "",
    currentAddressPincode: "",
    fullName: "",
  });
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return;

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }
    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true; // Set flag to true to prevent subsequent calls

    const fetchData1 = async () => {
      try {
        // First API call: Create Loan Application
        const response = await propertyLoanCreateLoanApplicationAPI();
        if (response?.businessStatusCode === 2) {
          setApid(response?.ap_id || "");
          setLoanAppId(response?.loan_app_id || "");
        }
        const lapLoanApplicationStatus = response?.status || "";

        // Second API call: Fetch user details
        const fetchData = async () => {
          try {
            const response1 = await propertyLoanUserDetailsAPI();
            console.log("response1", response1);

            setFormData(() => ({
              fullName: response1?.fullName || "", // Fallback to empty string if undefined
              currentAddressPincode: response1?.pinCode || "", // Fallback to empty string if undefined
            }));

            // Check if the property array is valid and set form data accordingly
            if (Array.isArray(response1?.property) && response1?.property.length > 0) {
              setPropertyForms(response1.property);
            } else {
              // Default form in case the property array is empty or undefined
              setPropertyForms([{
                propertyID: "",
                fullName: "",
                currentAddressPincode: "",
                propertyType: "",
                propertyJurisdiction: "",
                propertyOwnership: "",
                propertyPin: "",
                numberOfOwners: "",
                propertyAge: "",
                propertyValuation: "",
              }]);
            }
          } catch (error) {
            handleApiError(error);
            console.error("Error fetching user data:", error);
          }
        };

        // Fetch user details after loan application status
        await fetchData();

        // Fetch dropdown values after fetching user details
        const fetchDropDownData = async () => {
          const apiPayloads = [
            { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_TYPE", dropdownType: "PROPERTY_TYPE" },
            { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_OWNEDBY", dropdownType: "PROPERTY_OWNEDBY" },
            { source: "json_value", type: "LAP_LOAN", key: "NUMBER_OF_OWNERS", dropdownType: "NUMBER_OF_OWNERS" },
            { source: "json_value", type: "LAP_LOAN", key: "PROPERTY_AGE", dropdownType: "PROPERTY_AGE" },
            { source: "json_value", type: "LOAN_AGAINST_PROPERTY", key: "PROPERTY_JURISDICTION", dropdownType: "PROPERTY_JURISDICTION" },
          ];

          // Fetch each dropdown value
          for (const payload of apiPayloads) {
            await fetchDropDownValues(payload, payload.dropdownType);
          }
        };

        await fetchDropDownData();

        // Navigate after fetching data
        navigate("/home/loanagainstproperty", {
          state: {
            lapLoanApplicationStatus,
          },
        });
      } catch (error) {
        handleApiError(error);
        console.error("Error creating loan application:", error);
      }
    };

    fetchData1();
  }
  }, [navigate, ap_id, loan_app_id]); // Added 'navigate' as a dependency

  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    try {
      const response = await propertyLoanFetchDataAPI(apiPayload);
      const data = response?.data?.[0] || {}; // Safely accessing the response

      // Handling different dropdown values based on type
      if (dropdownType === "PROPERTY_JURISDICTION") {
        setPropertyJurisdictionDropdown(data.List || []); // Fallback to empty array if undefined
      } else if (dropdownType === "PROPERTY_TYPE") {
        setPropertyTypeDropdown(data.PropertyType || []); // Fallback to empty array if undefined
      } else if (dropdownType === "PROPERTY_OWNEDBY") {
        setPropertyOwnerDropdown(data.PropertyOwnedBy || []); // Fallback to empty array if undefined
      } else if (dropdownType === "NUMBER_OF_OWNERS") {
        setPropertyOwnerNumberDropdown(data.NumberOfOwners || []); // Fallback to empty array if undefined
      } else if (dropdownType === "PROPERTY_AGE") {
        setPropertyAgeDropdown(data.PropertyAge || []); // Fallback to empty array if undefined
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching dropdown data:", error);
    }
  };


  useEffect(() => {
    validateForm();
  }, []);

  // Fetching dropdown Values




  // const validateForm = () => {
  //   const {
  //     fullName,
  //     currentAddressPincode,
  //     propertyType,
  //     propertyJurisdiction,
  //     numberOfOwners,
  //     propertyAge,
  //     propertyOwnership,
  //     propertyPin,
  //     propertyValuation,
  //   } = formData;

  //   const isValid =
  //     fullName !== "" &&
  //     currentAddressPincode !== "" &&
  //     propertyType !== "" &&
  //     propertyJurisdiction !== "" &&
  //     numberOfOwners !== "" &&
  //     propertyAge !== "" &&
  //     propertyOwnership !== "" &&
  //     propertyPin !== "" &&
  //     propertyValuation !== "";

  //   setIsFormValid(isValid);
  // };





  const handleClear = () => {
    navigate("/home");
  };

  useEffect(() => {
    const pinCodeInput = document.getElementById("pin-code-inpt");
    if (pinCodeInput) {
      pinCodeInput.focus();
    }
  }, []);



  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  const [propertyForms, setPropertyForms] = useState([
    {
      propertyID: "",
      fullName: "",
      currentAddressPincode: "",
      propertyType: "",
      propertyJurisdiction: "",
      propertyOwnership: "",
      propertyPin: "",
      numberOfOwners: "",
      propertyAge: "",
      propertyValuation: "",
    },
  ]);

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   let updatedValue = value;

  //   // Apply validation specifically for propertyValuation to allow only numbers
  //   if (name === "propertyValuation") {
  //     updatedValue = updatedValue.replace(/[^0-9]/g, ""); 
  //   }

  //   const updatedForms = [...propertyForms];
  //   updatedForms[index][name] = updatedValue;
  //   setPropertyForms(updatedForms);

  //   // Additional validation for other fields (e.g., currentAddressPincode)
  //   if (name === "currentAddressPincode") {
  //     updatedValue = updatedValue.replace(/\D/g, "").slice(0, 6);
  //     const isPinCodeValid = /^\d{6}$/.test(updatedValue);
  //     if (!isPinCodeValid) {
  //       setPinCodeError("Pincode must be 6 digits");
  //     } else {
  //       setPinCodeError("");
  //     }
  //   }

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: updatedValue,
  //   }));

  //   validateForm(); // Make sure validateForm() is defined elsewhere
  // };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let updatedValue = value;
  
    const updatedForms = [...propertyForms];
  
    // Apply validation specifically for propertyValuation to allow only numbers
    if (name === "propertyValuation") {
      updatedValue = updatedValue.replace(/[^0-9]/g, "").slice(0, 10); // Remove any non-numeric characters and limit updto 10 digit
    }
  
    // If propertyOwnership is Solo, set numberOfOwners to 1 and disable it
    if (name === "propertyOwnership") {
      if (value === "Solo") {
        updatedForms[index].numberOfOwners = "1"; // Set numberOfOwners to 1 if Solo
      } else {
        updatedForms[index].numberOfOwners = ""; // Reset the numberOfOwners if not Solo
      }
    }
  
    // Specific validation for propertyPin to ensure it's numeric and no more than 6 digits
    if (name === "propertyPin") {
      updatedValue = updatedValue.replace(/\D/g, "").slice(0, 6); // Only allow 6 digits
  
      const updatedPinCodeErrors = [...pinCodeErrors]; // Create a copy of the pinCodeErrors array
      let errorMessage = ""; // Initialize error message
  
      // Validation for pin code: must be exactly 6 digits, not all zeros, and must not start with zero
      const isPinCodeValid = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid) {
        errorMessage = "Pincode must be exactly 6 digits";
      } else if (updatedValue === "000000") {
        errorMessage = "Pincode is Invalid";
      } else if (updatedValue.startsWith("0")) {
        errorMessage = "Pincode is Invalid";
      }
  
      updatedPinCodeErrors[index] = errorMessage; // Set the error message
      setPinCodeErrors(updatedPinCodeErrors); // Update pin code errors for all property forms
    }
  
    updatedForms[index][name] = updatedValue; // Update the value in the form
    setPropertyForms(updatedForms); // Update the property form data
  
    // Update formData with the current field change
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };
  



  // const handleChange1 = (e) => {
  //   const { name, value } = e.target;
  //   const updatedForms = [...propertyForms];
  //   updatedForms[name] = value;
  //   setPropertyForms(updatedForms);
  // };

  // const handleChange1 = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "currentAddressPincode") {
      updatedValue = updatedValue.replace(/\D/g, "").slice(0, 6);
      const isPinCodeValid1 = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid1) {
        setPinCodeError1("Pincode must be 6 digits");
      } else {
        setPinCodeError1("");
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddProperty = () => {
    setPropertyForms([
      ...propertyForms,
      {
        propertyID: "",
        fullName: "",
        currentAddressPincode: "",
        propertyType: "",
        propertyJurisdiction: "",
        propertyOwnership: "",
        propertyPin: "",
        numberOfOwners: "",
        propertyAge: "",
        propertyValuation: "",
      },
    ]);
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, [formData, propertyForms]);

  const validateForm = () => {
    const { fullName, currentAddressPincode } = formData;

    // Basic validation for the primary form
    const isMainFormValid = fullName !== "" && currentAddressPincode !== "";

    // Validate each property form
    const areAllPropertiesValid = propertyForms?.every((form) => {
      const {
        propertyType,
        propertyJurisdiction,
        numberOfOwners,
        propertyAge,
        propertyOwnership,
        propertyPin,
        propertyValuation,
      } = form;

      // Check if propertyPin contains exactly 6 digits
      const isPinValid = /^\d{6}$/.test(propertyPin) && propertyPin !== "000000" && !propertyPin.startsWith("0");

      return (
        propertyType !== "" &&
        propertyJurisdiction !== "" &&
        // numberOfOwners !== "" &&
        propertyAge !== "" &&
        propertyOwnership !== "" &&
        propertyPin !== "" &&
        isPinValid && // Ensure pincode is valid
        propertyValuation !== ""
      );
    });

    // Set the form validity based on both the main form and all properties
    setIsFormValid(isMainFormValid && areAllPropertiesValid);
  };

  const handleRemoveProperty = (index) => {
    const updatedForms = propertyForms.filter((_, i) => i !== index);
    setPropertyForms(updatedForms);
  };

  const handleContinue = async (values, setSubmitting) => {
    setIsButtonClicked(true);
    setIsLoading(true);
    // setShowEligibilityCheckForm(true);

    const data = {
      pinCode: formData.currentAddressPincode,
      fullName: formData.fullName,
      property: propertyForms?.map(form => ({
        numberOfOwners: form.numberOfOwners,
        propertyAge: form.propertyAge,
        propertyOwnership: form.propertyOwnership,
        propertyPin: form.propertyPin,
        propertyType: form.propertyType,
        propertyJurisdiction: form.propertyJurisdiction,
        propertyValuation: form.propertyValuation,
        propertyID: form.propertyID,
      }))
    };

    try {
      const response = await propertyLoanStoreDetailsAPI(data);

      if (response.businessStatusCode === 2) {
        changeStatus('ELIGIBILITY_DETAILS')
        toast.success("Applicant Details Added Successfully");
      } else if (
        response.businessStatusCode === 1 &&
        response.businessStatusSubCode === 1.1
      ) {
        toast.info(response.message);
      } else if (
        response.businessStatusCode === 1 &&
        response.businessStatusSubCode === 1.3
      ) {
        toast.warning(response.message);
        navigate("/home");
      } else {
        setShowEligibilityCheckForm(false);
        setIsOpen(true);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };
  return (
    <>
      {!showEligibilityCheckForm ? (
        <>
          <h6 className="text mt-4">Initial Applicant Details</h6>

          <form
            className="mt-4 border border-dark p-3"
            style={{ borderRadius: "10px" }}
          >
            <div className="row mt-2 ">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={(e) => handleChange1(e)}
                  label="Full Name"
                  fullWidth
                  variant="outlined"
                  disabled
                  className="form-control fs-5 custom-disabled-input"
                />
                <span className="msg">As per PAN</span>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="currentAddressPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentAddressPincode || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      handleChange1(e);
                    }
                  }}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  inputMode="numeric"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(pinCodeError1)}
                  helperText={pinCodeError1}
                  autoFocus
                />
              </div>
            </div>
          </form>

          <h6 className="text mt-4">Basic Property Details</h6>
          {propertyForms?.map((formData, index) => (
            <form
              key={index}
              className="mt-4 border border-dark p-3"
              style={{ borderRadius: "10px" }}
            >
              <p
                className="col-md-auto"
                style={{
                  fontSize: "16px",
                  // marginTop: "-25px",
                  marginLeft: "7px",
                }}
              >
                Property - {index + 1}
              </p>
              <div className="row mt-2 ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyJurisdiction-label">
                      Property Jurisdiction
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyJurisdiction-label"
                      id="propertyJurisdiction"
                      name="propertyJurisdiction"
                      value={formData.propertyJurisdiction || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyJurisdictionDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyType-label">
                      Property Type
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyType-label"
                      id="propertyType"
                      name="propertyType"
                      value={formData.propertyType || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyTypeDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>

              <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyOwnership-label">
                      Property Owned By
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyOwnership-label"
                      id="propertyOwnership"
                      name="propertyOwnership"
                      value={formData.propertyOwnership || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyOwnerDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="numberOfOwners-label">
                      Number of Owners in the Property
                    </ArthaInputLabel>
                    <Select
                      labelId="numberOfOwners-label"
                      id="numberOfOwners"
                      name="numberOfOwners"
                      value={formData.numberOfOwners || ""}
                      onChange={(e) => handleChange(e, index)}
                      disabled={!formData.propertyOwnership || formData.propertyOwnership === "Solo"} // Disable if propertyOwnership is Solo
                    >
                      {propertyOwnerNumberDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>

                </div>
              </div>

              <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <ArthaTextField
                    type="text"
                    name="propertyValuation"
                    value={formData.propertyValuation || ""}
                    onChange={(e) => handleChange(e, index)}
                    label="Approximate Property Valuation (₹)"
                    fullWidth
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyAge-label">
                      Property Age
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyAge-label"
                      id="propertyAge"
                      name="propertyAge"
                      value={formData.propertyAge || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyAgeDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>
              <div className="row mt-3 mb-2 mt-md-2">
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
                  <ArthaTextField
                    name="propertyPin"
                    type="text"
                    maxLength="6"
                    value={formData.propertyPin || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 6) {
                        handleChange(e, index);
                      }
                    }}
                    label="Property Pincode"
                    id="pin-code-inpt"
                    fullWidth
                    inputMode="numeric"
                    error={Boolean(pinCodeErrors[index])} // Access error by index
                    helperText={pinCodeErrors[index]} // Display error for this property
                  />
                </div>
                <div className="col-sm-12 col-md-6 mt-md-3 col-lg-6 text-end">
                  {propertyForms.length > 1 && (
                    <ArthaClearButton
                      variant="contained"
                      onClick={() => handleRemoveProperty(index)}
                    >
                      Remove Property
                    </ArthaClearButton>
                  )}
                </div>
              </div>
              <PinCodeValidation isOpen={isOpen} closeModal={closeModal} />
            </form>
          ))}

          <div className="col-12 mt-4">
            <ArthaClearButton variant="contained" onClick={handleAddProperty}>
              Add Property
            </ArthaClearButton>
          </div>
          <div className="row mt-4 mx-auto">
            <div className="col-6 d-flex justify-content-end mt-4">
              <ArthaClearButton variant="contained" onClick={handleClear}>
                Cancel
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start mt-4">
              <ArthaContinueButton
                variant="contained"
                onClick={handleContinue}
                disabled={!isFormValid}
                style={{
                  backgroundColor: isFormValid
                    ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                    : "rgb(255 98 0 / 39%)",
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Continue"
                )}
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : (
        <EligibilityCheckForm
          onCancel={() => setShowEligibilityCheckForm(false)}
        />
      )}
    </>
  );
};

export default BasicPropertyDetails;
