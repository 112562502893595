import React, { useRef, useState } from "react";
import personalLoanIcon from "../DashboardIcons/Personal Loan 2.svg";
import HomeLoanIcon from "../DashboardIcons/Home Loan 2.svg";
import EduLoanIcon from "../DashboardIcons/Education Loan 2.svg";
import BussinessLoanIcon from "../DashboardIcons/Business Loan 2.svg";
import LeftScroll from "../DashboardIcons/Chev1_circle_left.png";
import RightScroll from "../DashboardIcons/Chev1_circle_right.png";
import CreditScoreIcon from "../public/group-48095437.svg";
import { useEffect } from "react";
import RightArrow from "../DashboardIcons/arrow_right_alt.png";
import Drawer from "../Drawer";
import GreenDot from "../DashboardIcons/greenDot.png";
import GrayDot from "../DashboardIcons/grayDot.png";
import Goibibo from "../DashboardIcons/Goibibo.jpg";
import localizedFormat from "dayjs/plugin/localizedFormat";
import MakeMyTrip from "../DashboardIcons/MakeMyTrip.jpg";
import HubLot from "../DashboardIcons/HubLot.jpg";
import NetFlix from "../DashboardIcons/NetFlix.jpg";
import axios from "axios";
import UpArrow from "../DashboardIcons/arrow_outward.png";
import ForwardArrow from "../DashboardIcons/arrow_forward.svg";
import ElegibilityCheck from "../DashboardIcons/Emi Calc Web.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./dashboard.css";
import ArthaCancelButton from "../../fields/ArthaCancelButton";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import { useNavigate } from "react-router-dom";
import ArthaTextField from "../../fields/ArthaTextField";
import { fontSize, width } from "@mui/system";
import ReactApexChart from "react-apexcharts";
import { Opacity } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { handleSignOut } from "../../Loan/PersonalLoan/SessionExpiredApi";
import {
  fetchAllLoanApplicationsAPI,
  existingSessionApplyAPI,
  fetchUsername,
  businessLoanFetchDataAPI,
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const Dashboard = () => {
  const [toggle, setToggle] = useState(1);
  function updateToggle(id) {
    setToggle(id);
  }

  const [loanApplications, setLoanApplications] = useState([]);
  const [enableApplyButton, setEnableApplyButton] = useState([]);
  const [response4, setResponse4] = useState();
  const navRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        const container = navRef.current;
        // Check if there is more content to the left
        setShowLeftArrow(container.scrollLeft > 0);
        // Check if there is more content to the right
        setShowRightArrow(
          container.scrollLeft < container.scrollWidth - container.clientWidth
        );
      }
    };

    if (navRef.current) {
      navRef.current.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (navRef.current) {
        navRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleNav = (direction) => {
    if (navRef.current) {
      const scrollAmount = 200;
      const scrollContainer = navRef.current;
      if (direction === "left") {
        scrollContainer.scrollBy({
          left: -scrollAmount,
          behavior: "smooth",
        });
      } else {
        scrollContainer.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  const [loanToggle, setLoanToggle] = useState(1);
  const navigate = useNavigate();
  function updateLoansToggle(id) {
    setLoanToggle(id);
  }
  const [onGoingApplications, setOnGoingApplications] = React.useState([
    "MS",
    "Rutu",
    "Raina",
  ]);
  const [postApplications, setPostApplications] = React.useState([
    "MS",
    "Rutu",
    "Raina",
  ]);
  const [myloans, setMyloans] = React.useState([]);
  const [username, setUsername] = useState("");
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }
    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 3 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      // Make API call to fetch the username
      try {
        const response = await fetchUsername();
        // Assuming the response data is an object with a 'username' property
        setUsername(response.data.username);
        // localStorage.setItem("user-id", response.data.customerId);
      } catch (error) {
        handleApiError(error);
        console.error("API call error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAllLoanApplicationsAPI();
        console.log("response", response);
        if (response.data.businessStatusCode === 2) {
          const applications = response.data.loanApplications || []; // Access loanApplications directly from response
          setLoanApplications(applications);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          type: "LOANS_AVAILABLE",
          key: "LOANS_AVAILABLE",
          source: "json_value",
        };

        const response = await businessLoanFetchDataAPI(payload);
        console.log("response", response);
        setResponse4(response);
        if (response?.businessStatusCode === 2) {
          setEnableApplyButton(response?.data?.[0]?.values);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleApply = async (e, loanType) => {
    const data = {
      loanType: loanType,
    };
    try {
      const response = await existingSessionApplyAPI(data);

      if (response.status === 200 && response.data.businessStatusCode === 1) {
        Swal.fire({
          icon: "warning",
          text: response.data.message,
          showCancelButton: false,
          confirmButtonText: "Ok",
          showConfirmButton: true,
        });
      } else if (
        response.status === 200 &&
        response.data.businessStatusCode === 2
      ) {
        toast.success(response.data.message);
        if (loanType === "Personal Loan") {
          navigate("/home/personalloans");
        } else if (loanType === "Business Loan") {
          navigate("/home/businessloan");
        } else if ((loanType === "Loan Against Property")) {
          navigate("/home/loanagainstproperty");
        }
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    }
  };

  const handleViewDetails = (
    loanApplicationId,
    apId,
    loanApplicationStatus,
    type
  ) => {
    // Assuming you are using Gatsby's navigate function for navigation
    if (type === "Personal Loan") {
      navigate("/home/personalloans", {
        state: {
          loanApplicationId: loanApplicationId,
          apId: apId,
          loanApplicationStatus: loanApplicationStatus,
          type: type,
        },
      });
    } else if (type === "Business Loan") {
      navigate("/home/businessloan", {
        state: {
          loanApplicationId: loanApplicationId,
          apId: apId,
          loanApplicationStatus: loanApplicationStatus,
        },
      });
    } else {
      navigate("/home/loanagainstproperty", {
        state: {
          loanApplicationId: loanApplicationId,
          apId: apId,
          lapLoanApplicationStatus: loanApplicationStatus,
        },
      });
    } 
  };

  // const handleContinue = async () => {
  //   const data = {
  //     income: formData.monthlyIncome,
  //     emi: formData.existingObligationEMI,
  //    dob:formData.dob
  //   };
  //   axios
  //     .post("/personal-loan/loan-eligibility-calculator", data)
  //     .then((response) => {
  //       console.log("response", response);
  //       const {
  //         interestRate,
  //         maxLoanAmount,
  //         maxTenure,
  //         minimumLoan,
  //         tentativeEmi,
  //         minimumTenure,
  //         loanAmount, loanTenure, tenure
  //       } = response.data;
  //       console.log("interestRate", interestRate);
  //       console.log("tenure", tenure);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching loan data:", error);
  //     });
  // };

  let response = {
    minimumLoan: 18000,
    interestRate: 0.15,
    tenure: [
      {
        EMIPerLakh: 9025,
        MaXLoanAmount: 192780,
        Tenure: 12,
      },
      {
        EMIPerLakh: 4848,
        MaXLoanAmount: 358861,
        Tenure: 24,
      },
      {
        EMIPerLakh: 3466,
        MaXLoanAmount: 501942,
        Tenure: 36,
      },
      {
        EMIPerLakh: 2783,
        MaXLoanAmount: 625207,
        Tenure: 48,
      },
      {
        EMIPerLakh: 2378,
        MaXLoanAmount: 731401,
        Tenure: 60,
      },
    ],
    httpResponseCode: 200,
    businessStatusCode: 2,
  };

  const minimumLoan = response.minimumLoan;
  const tenureDetails = response.tenure;
  const maxLoanAmount1 = tenureDetails?.[0]?.MaXLoanAmount;
  const loanTenure1 = tenureDetails?.[0]?.Tenure;
  const loanAmount1 = ""; // Fixed initial loan amount

  const [loanTenure, setLoanTenure] = useState(loanTenure1);
  const [loanAmount, setLoanAmount] = useState(loanAmount1);

  function getLoanDetailsForTenure(selectedTenure) {
    const tenureDetails = response.tenure;

    // Find the tenure object with the matching value
    const selectedTenureDetails = tenureDetails.find(
      (tenure) => tenure.Tenure === selectedTenure
    );

    if (selectedTenureDetails) {
      const maxLoanAmount = Math.round(
        (selectedTenureDetails.MaXLoanAmount / 25000) * 25000
      );
      const emiPerLakh = selectedTenureDetails.EMIPerLakh;

      // Use maxLoanAmount and emiPerLakh as needed
      console.log(
        `For Tenure ${selectedTenure}: Max Loan Amount - ${maxLoanAmount}, EMI Per Lakh - ${emiPerLakh}`
      );
    } else {
      console.log(`No details found for Tenure ${selectedTenure}`);
    }
  }

  const handleTenureChange = (event) => {
    const selectedTenure = parseInt(event.target.value, 10);
    //setLoanTenure(selectedTenure);
    setLoanTenureDigit(selectedTenure);
    let tenure = responseOfEligibility.tenure;
    setMinAmount(25000);
    const tenure12Object = tenure.find((item) => item.Tenure == selectedTenure);

    if (tenure12Object && tenure12Object.MaXLoanAmount !== undefined) {
      const maxLoanAmount = Math.round(
        (tenure12Object.MaXLoanAmount / 25000) * 25000
      );
      setMaxAmount(maxLoanAmount);
    }
  };

  const handleLoanAmountChange = (event) => {
    const selectedLoanAmount = parseInt(event.target.value, 10);
    setLoanAmount(selectedLoanAmount);
    setLoanAmountCalculation(selectedLoanAmount);
  };

  const [eToggle, setEToggle] = useState(1);
  const updateEligibilityToggle = (id) => {
    setEToggle(id);
  };
  const [formData, setFormData] = useState({
    monthlyIncome: "",
    existingObligationEMI: "",
    dob: null,
  });

  //PieChart Code

  const [chartData, setChartData] = useState({
    series: [90, 90],
    options: {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          donut: {
            size: "60%",
          },
          stroke: {
            show: true,
            width: 0, // Set this to 0 to remove the margin
          },
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },

      labels: [" Total Obligation/EMI", "Balance Amount"],
      colors: ["#808080", "#28A745"], // Gray, Green
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: false, // Hide the values by default
      },
      legend: {
        show: false, // Hide legend (colored bullets and labels)
      },
    },
  });
  useEffect(() => {}, [formData]);

  //Validatio

  const [isFormValid, setIsFormValid] = useState(false);

  const [isCalculateEnabled, setCalculateEnabled] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Sanitize the value and convert it to a number
    const sanitizedValue = parseInt(
      value.replace(/[^\d]/g, "").slice(0, 8),
      10
    );
    if (isNaN(sanitizedValue)) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    }
    validateForm();
  };

  dayjs.extend(localizedFormat);
  const minDate = dayjs().subtract(18, "year").toDate();
  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("DD/MM/YYYY") : null;
    setFormData((prevFormData) => ({
      ...prevFormData,
      dob: formattedDate,
    }));
  };

  const validateForm = () => {
    const isValid =
      formData.monthlyIncome !== "" &&
      formData.existingObligationEMI !== "" &&
      formData.dob !== null;
    setIsFormValid(isValid);
  };

  const handleReset = () => {
    setIsFormVisible(false);
    setIsResetClicked(true);
    setFormData({
      monthlyIncome: "",
      existingObligationEMI: "",
      dob: null,
    });
    setIsFormValid(false);
  };
  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [responseOfEligibility, setResponseOfEligibility] = React.useState();
  const [loanTenureDigit, setLoanTenureDigit] = React.useState(12);
  const [minAmount, setMinAmount] = React.useState();
  const [maxAmount, setMaxAmount] = React.useState();
  const [loanAmountCalculation, setLoanAmountCalculation] = React.useState();
  const [emiperLakh, setEmiperLakh] = React.useState();
  const [emi, setEmi] = React.useState();

  useEffect(() => {
    if (responseOfEligibility) {
      let tenure = responseOfEligibility.tenure;
      setMinAmount(25000);
      const selectedTenureObject = tenure.find(
        (item) => item.Tenure === loanTenureDigit
      );

      if (selectedTenureObject) {
        // Calculate the nearest multiple of 25000 that is less than or equal to MaXLoanAmount
        const nearestMultipleOf25000 =
          Math.floor(selectedTenureObject.MaXLoanAmount / 25000) * 25000;
        setMaxAmount(nearestMultipleOf25000);
        setEmiperLakh(selectedTenureObject.EMIPerLakh);
      }
    }
  }, [responseOfEligibility, loanTenureDigit, loanAmountCalculation]);

  useEffect(() => {
    calculateEmi();
  }, [emiperLakh, loanAmountCalculation]);

  useEffect(() => {
    if (responseOfEligibility) {
      setLoanAmountCalculation(25000);
    }
  }, [responseOfEligibility]);
  const [finalEMI, setFinalEMI] = React.useState();
  const [finalAmount, setFinalAmount] = React.useState();
  const calculateEmi = () => {
    // EMI Calculation logic (you can replace this with your own calculation)
    const principal = loanAmountCalculation || 0;
    const emiperklakh = emiperLakh || 0;

    const emiValue = (principal * emiperklakh) / 100000;
    setEmi(Math.round(emiValue));
    if (emiValue > 0) {
      let tempAmount =
        formData.monthlyIncome - (formData.existingObligationEMI + emiValue);

      if (tempAmount < 0) {
        tempAmount = Math.abs(tempAmount); // Convert negative value to its absolute value
      }
      let finalAMIData = formData.existingObligationEMI + Math.round(emiValue);
      let finalAmountData = Math.round(tempAmount);
      setFinalEMI(finalAMIData);
      setFinalAmount(finalAmountData);
      setChartData((prevChartData) => ({
        ...prevChartData,
        series: [parseInt(finalAMIData), parseInt(finalAmountData)],
      }));
    }
  };

  const handleCheckEligibilityClick = async () => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        formData.existingObligationEMI + emi,
        formData.monthlyIncome - (formData.existingObligationEMI + emi),
      ],
    }));
    setFinalEMI(formData.existingObligationEMI + emi);
    setFinalAmount(
      formData.monthlyIncome - (formData.existingObligationEMI + emi)
    );
    setIsFormVisible(true);
    setIsResetClicked(false);
    try {
      const requestBody = {
        income: formData.monthlyIncome.toString(),
        emi: formData.existingObligationEMI.toString(),
        dob: formData.dob,
      };
      console.log(requestBody);
      const response = await axios.post(
        "/personal-loan/loan-eligibility-calculator",
        requestBody,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200 && response.data.businessStatusCode === 2) {
        setResponseOfEligibility(response.data);
        setIsFormVisible(!isFormVisible);
        setIsResetClicked(false);
      } else {
        // API error: Set the error message
        toast.error(response.data.message);
      }
    } catch (error) {
      // Network error
      handleApiError(error);
      // Print the error in the console
      console.error(error);
    }
  };

  const [isResetClicked, setIsResetClicked] = useState(false);
  const statusRegex =
    /(REJECTED|APPLICATION_UNDER_REVIEW|DISBURSED|READY_FOR_DISBURSEMENT)$/;
  const statusRegex1 = /(REJECTED|DISBURSED)$/;
  return (
    <>
      <Drawer 
        response4={response4}
      />
      <div className="dashboard">
        <div className="row mt-4 dashContainer">
          <div className="col-12 col-md-8 mt-5">
            <div className="row dashboardHeader">
              <div className="col-12 dashboardHeader2">
                <div className="row">
                  <div className="col-9">
                    <h6>Welcome {username}</h6>
                    <p className="dashboardmsg">
                      We will build your profile as you start applying or you
                      can complete it now
                    </p>
                  </div>
                  <div className="col-3 mt-2 d-flex justify-content-center">
                    <ArthaCancelButton
                      type="button"
                      className="custom_btn"
                      style={{
                        width: "auto !important",
                        color: "gray",
                      }}
                    >
                      Complete Profile
                    </ArthaCancelButton>
                  </div>
                </div>
              </div>
            </div>

            {loanApplications.length > 0 && (
              <div className="row  myAppSection mt-2">
                <div className="col-12 mt-2" style={{ paddingLeft: "9px" }}>
                  <h6>
                    My Application <img src={RightArrow} />
                  </h6>
                </div>
                <div className="col-md-12">
                  <nav>
                    <div className="" id="nav-tab" role="tablist">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-4">
                            <span
                              onClick={() => updateToggle(1)}
                              className={` ${
                                toggle === 1 ? "activeDashtab" : ""
                              }`}
                              id="nav-home-tab"
                              style={{cursor:"pointer"}}
                            >
                              Ongoing Applications(
                              {
                                loanApplications.filter(
                                  (application) =>
                                    !statusRegex1.test(
                                      application.loanApplicationStatus
                                    )
                                ).length
                              }
                              )
                            </span>
                          </div>
                          <div className="col-6 col-md-4">
                            <span
                              onClick={() => updateToggle(2)}
                              className={`${
                                toggle === 2 ? "activeDashtab" : ""
                              }`}
                              id="nav-profile-tab"
                              style={{cursor:"pointer"}}
                            >
                              Past Applications(
                              {
                                loanApplications.filter((application) =>
                                  statusRegex1.test(
                                    application.loanApplicationStatus
                                  )
                                ).length
                              }
                              )
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div
                    className={
                      toggle === 1 ? "showcontent" : "ongoingApplications"
                    }
                  >
                    <div>
                      {loanApplications.filter(
                        (application) =>
                          !statusRegex1.test(application.loanApplicationStatus)
                      ).length === 0 ? (
                        <div
                          className=" text-center"
                          style={{ paddingTop: "100px" }}
                        >
                          {" "}
                          It looks like you don't have any existing loan
                          applications with us at the moment. To begin a new
                          loan application, please click the 'Apply' button for
                          your desired loan type on the dashboard.
                        </div>
                      ) : (
                        loanApplications
                          .filter(
                            (application) =>
                              !statusRegex1.test(
                                application.loanApplicationStatus
                              )
                          )
                          .map((application) => (
                            <div
                              key={application.loanApplicationId}
                              className="row dashboardHeader3"
                            >
                              <div
                                className="col-12"
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                {application.type} |{" "}
                                {application.externalLoanApplicationId}
                              </div>
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="myapplicationTable">
                                    <thead>
                                      <tr>
                                        <th>Date Applied</th>
                                        <th>Loan Amount</th>
                                        <th>Tenure</th>
                                        <th>Date Disbursed</th>
                                        <th
                                          style={{
                                            width: "70px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          rowSpan={2}
                                          style={{ textAlign: "right" }}
                                        ></th>
                                        <th
                                          rowSpan={2}
                                          style={{ textAlign: "right" }}
                                        >
                                          <a
                                            href="/home/personalloans"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                statusRegex.test(
                                                  application.loanApplicationStatus
                                                )
                                              ) {
                                                return;
                                              }
                                              handleViewDetails(
                                                application.loanApplicationId,
                                                application.apId,
                                                application.loanApplicationStatus,
                                                application.type
                                              );
                                            }}
                                            className={`viewDetails ${
                                              statusRegex.test(
                                                application.loanApplicationStatus
                                              )
                                                ? "disabled"
                                                : ""
                                            }`}
                                            disabled={statusRegex.test(
                                              application.loanApplicationStatus
                                            )}
                                          >
                                            View Details
                                          </a>
                                        </th>
                                      </tr>
                                      <tr>
                                        <td>{application.dateCreated}</td>
                                        <td>{application.loanAmount}</td>
                                        <td>{application.loanTenure}</td>
                                        <td>{application.dateAccepted}</td>
                                        <td
                                          style={{
                                            width: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          {application.loanApplicationStatus}
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  </div>

                  <div
                    className={toggle === 2 ? "showcontent" : "pastApplication"}
                  >
                    <div>
                      {loanApplications.filter((application) =>
                        statusRegex1.test(application.loanApplicationStatus)
                      ).length === 0 ? (
                        <div
                          className=" text-center"
                          style={{ paddingTop: "100px" }}
                        >
                          {" "}
                          It looks like you don't have any past loan
                          applications with us at the moment. To begin a new
                          loan application, please click the 'Apply' button for
                          your desired loan type on the dashboard.
                        </div>
                      ) : (
                        loanApplications
                          .filter((application) =>
                            statusRegex1.test(application.loanApplicationStatus)
                          )
                          .map((application) => (
                            <div
                              key={application.loanApplicationId}
                              className="row dashboardHeader3"
                            >
                              <div
                                className="col-12"
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                {application.type} |{" "}
                                {application.externalLoanApplicationId}
                              </div>
                              <div className="col-12">
                                <div className="table-responsive">
                                  <table className="myapplicationTable">
                                    <thead>
                                      <tr>
                                        <th>Date Applied</th>
                                        <th>Loan Amount</th>
                                        <th>Tenure</th>
                                        <th>Date Disbursed</th>
                                        <th
                                          style={{
                                            width: "70px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          rowSpan={2}
                                          style={{ textAlign: "right" }}
                                        ></th>
                                        <th
                                          rowSpan={2}
                                          style={{ textAlign: "right" }}
                                        >
                                          <a
                                            href="/home/personalloans"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                statusRegex.test(
                                                  application.loanApplicationStatus
                                                )
                                              ) {
                                                return;
                                              }
                                              handleViewDetails(
                                                application.loanApplicationId,
                                                application.apId,
                                                application.loanApplicationStatus,
                                                application.type
                                              );
                                            }}
                                            className={`viewDetails ${
                                              statusRegex.test(
                                                application.loanApplicationStatus
                                              )
                                                ? "disabled"
                                                : ""
                                            }`}
                                            disabled={statusRegex.test(
                                              application.loanApplicationStatus
                                            )}
                                          >
                                            View Details
                                          </a>
                                        </th>
                                      </tr>
                                      <tr>
                                        <td>{application.dateCreated}</td>
                                        <td>{application.loanAmount}</td>
                                        <td>{application.loanTenure}</td>
                                        <td>{application.dateAccepted}</td>
                                        <td
                                          style={{
                                            width: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          {application.loanApplicationStatus}
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))
                      )}
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* My Loans Section */}
            {/* <div className="row  myLoanSection mt-2">
              <div className="col-12 mt-2">
                <h6>
                  My Loans <img src={RightArrow} style={{ fill: "#FD5F07" }} />
                </h6>
              </div>
              <div className="col-md-12">
                <nav>
                  <div className="" id="nav-tab" role="tablist">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-3">
                          {" "}
                         <span
  onClick={() => updateLoansToggle(1)}
  className={` ${loanToggle === 1 ? "activeDashtab" : ""}`}
  id="nav-home-tab"
>
  Ongoing Loans(1)
</span>

                        </div>
                        <div className="col-3">
                          {" "}
                          <span
  onClick={() => updateLoansToggle(2)}
  className={` ${loanToggle === 2 ? "activeDashtab" : ""}`}
  id="nav-profile-tab"
>
  Past Loans(2)
</span>

                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div
                  className={
                    loanToggle === 1 ? "showcontent " : "ongoingApplications"
                  }
                >
                  <div className="dashboardHeader3">
                    <div className="row ">
                      <div className="col-12">Personal loan #12344123</div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <table className="myapplicationTable">
                          <thead>
                            <tr>
                              <th>Your EMI/Month</th>
                              <th>Next EMI Date </th>
                              <th>Loan Amount (Total)</th>
                              <th>Loan Amount (Balance)</th>
                              <th rowSpan={2}>View Details</th>
                            </tr>
                            <tr>
                              <td>9334</td>
                              <td>25/12/23</td>
                              <td>3,00,000</td>
                              <td>2,63,000</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    loanToggle === 2 ? "showcontent" : "pastApplication"
                  }
                >
                  <div className="dashboardHeader3  ">
                    <div className="row ">
                       
                      <div className="col-12">Personal loan #12344</div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <table className="myapplicationTable">
                          <thead>
                            <tr>
                              <th>Your EMI/Month</th>
                              <th>Next EMI Date </th>
                              <th>Loan Amount (Total)</th>
                              <th>Loan Amount (Balance)</th>
                              <th rowSpan={2}>View Details</th>
                            </tr>
                            <tr>
                              <td>9334</td>
                              <td>25/12/23</td>
                              <td>3,00,000</td>
                              <td>2,63,000</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div> */}

            <div className="row loanSection mt-2 loans">
              <span className="loanMsg p-0">
                Loan Types <img src={RightArrow} className="orangeArrow" />{" "}
              </span>

              <div className="col-12 mt-2 ">
                <div
                  ref={navRef}
                  style={{ display: "flex", overflowX: "auto" }}
                  className="noScroll"
                >
                  <img
                    src={LeftScroll}
                    onClick={() => handleNav("left")}
                    style={{ display: showLeftArrow ? "block" : "none" }}
                    className="leftScroll"
                  />

                  <div className="col-9 col-md-3  loanBoxes">
                    <div className="row mt-2">
                      <div className="col-9  ">
                        <img src={personalLoanIcon} />
                        <span className="LoanHeading">Personal Loan</span>
                      </div>
                      <div className="col-3">
                        <img src={UpArrow} />
                      </div>
                      <div className="col-12">
                        <span className="loandiscription">
                          Avail personal loan for holiday, home renovation and
                          marriage at attractive interest.
                        </span>
                      </div>
                      <div className="col-12 pb-1">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-start">
                            <span className="offer">Offers</span>
                          </div>
                          <div className="col-6"></div>

                          <div className="col-3 d-flex justify-content-end">
                            {enableApplyButton?.includes(
                              "Personal Loan"
                            ) ? (
                              <span
                                className="apply"
                                style={{
                                  pointerEvents: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleApply(e, "Personal Loan")}
                              >
                                Apply
                              </span>
                            ) : (
                              <span className="offer">Coming Soon...</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 col-md-3  loanBoxes">
                    <div className="row mt-2">
                      <div className="col-9 ">
                        <img src={BussinessLoanIcon} />
                        <span className="LoanHeading">Business Loan</span>
                      </div>
                      <div className="col-3">
                        <img src={UpArrow} />
                      </div>
                      <div className="col-12">
                        <span className="loandiscription">
                          Avail personal loan for holiday, home renovation and
                          marriage at attractive interest.
                        </span>
                      </div>
                      <div className="col-12 pb-1">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-start">
                            <span className="offer">Offers</span>
                          </div>
                          <div className="col-6"></div>
                          <div className="col-3 d-flex justify-content-end">
                            {enableApplyButton?.includes(
                              "Business Loan"
                            ) ? (
                              <span
                                className="apply"
                                style={{
                                  pointerEvents: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleApply(e, "Business Loan")}
                              >
                                Apply
                              </span>
                            ) : (
                              <span className="offer">Coming Soon...</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 col-md-3  loanBoxes">
                    <div className="row mt-2">
                      <div className="col-9 ">
                        <img src={HomeLoanIcon} />
                        <span className="LoanHeading">
                          Loan Against Property
                        </span>
                      </div>
                      <div className="col-3">
                        <img src={UpArrow} />
                      </div>
                      <div className="col-12">
                        <span className="loandiscription">
                          Avail personal loan for holiday, home renovation and
                          marriage at attractive interest.
                        </span>
                      </div>
                      <div className="col-12 pb-1">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-start">
                            <span className="offer">Offers</span>
                          </div>
                          <div className="col-6"></div>
                          <div className="col-3 d-flex justify-content-end">
                            {enableApplyButton?.includes("LAP") ? (
                              <span
                                className="apply"
                                style={{
                                  pointerEvents: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleApply(e, "Loan Against Property")}
                              >
                                Apply
                              </span>
                            ) : (
                              <span className="offer">Coming Soon...</span>
                            )}

                            {/* <a href="/home" className="apply"
                              style={{
                                pointerEvents: "auto",
                                cursor: "pointer",
                              }}>Apply</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 col-md-3 loanBoxes">
                    <div className="row mt-2">
                      <div className="col-9 ">
                        <img src={EduLoanIcon} />
                        <span className="LoanHeading">Education Loan</span>
                      </div>
                      <div className="col-3">
                        <img src={UpArrow} />
                      </div>
                      <div className="col-12">
                        <span className="loandiscription">
                          Avail personal loan for holiday, home renovation and
                          marriage at attractive interest.
                        </span>
                      </div>
                      <div className="col-12 pb-1">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-start">
                            <span className="offer">Offers</span>
                          </div>
                          <div className="col-6"></div>
                          <div className="col-3 d-flex justify-content-end">
                            {/* <span className="apply">Apply</span> */}
                            {enableApplyButton?.includes(
                              "Education Loan"
                            ) ? (
                              <span
                                className="apply"
                                style={{
                                  pointerEvents: "auto",
                                  cursor: "pointer",
                                }}
                                // onClick={(e) => handleApply(e, "Education Loan")}
                              >
                                Apply
                              </span>
                            ) : (
                              <span className="offer">Coming Soon...</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    src={RightScroll}
                    onClick={() => handleNav("right")}
                    style={{ display: showRightArrow ? "block" : "none" }}
                    className="rightScroll"
                  />
                </div>
              </div>
            </div>

            {/* eligibility Section */}
            <div className="row loanSection mt-2 loans">
              <span className="loanMsg p-0">Eligibility Check </span>

              <div className="col-12 p-0 mt-2">
                <div className="row">
                  <div className="col-12">
                    <span
                      onClick={() => updateEligibilityToggle(1)}
                      className={`   ${
                        eToggle === 1 ? "eligibilityActive" : "smalltabheading"
                      }`}
                    >
                      Personal Loan
                    </span>
                    <span
                      onClick={() => updateEligibilityToggle(2)}
                      className={`  ${
                        eToggle === 2 ? "eligibilityActive" : "smalltabheading"
                      }`}
                    >
                      Home Loan
                    </span>
                    <span
                      onClick={() => updateEligibilityToggle(3)}
                      className={` ${
                        eToggle === 3 ? "eligibilityActive" : "smalltabheading"
                      }`}
                      id="nav-home-tab"
                    >
                      Business Loan
                    </span>
                    <span
                      onClick={() => updateEligibilityToggle(4)}
                      className={` ${
                        eToggle === 4 ? "eligibilityActive" : "smalltabheading"
                      }`}
                      id="nav-home-tab"
                    >
                      Education Loan
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={
                  eToggle === 1
                    ? "showEligibiltyCheckBox"
                    : "hideEligibilityCheckBox"
                }
              >
                <div className="col-12 p-0 mt-2">
                  <div className="row ">
                    <div className="col-12 col-md-8">
                      <span className="smallLable p-0">Financial Details </span>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <ArthaTextField
                            id="outlined-basic"
                            name="monthlyIncome"
                            label="₹ Monthly Income"
                            fullWidth
                            variant="outlined"
                            value={formData.monthlyIncome}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <ArthaTextField
                            id="outlined-basic"
                            name="existingObligationEMI"
                            label="₹ Existing Obligation EMI"
                            fullWidth
                            variant="outlined"
                            value={formData.existingObligationEMI}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              id="outlined-basic"
                              label="Date Of Birth"
                              name="Date OF Birth"
                              fullWidth
                              format="DD/MM/YYYY" // Set the desired format here
                              onChange={(date) => handleDateChange(date)}
                              value={
                                formData.dob
                                  ? dayjs(formData.dob, "DD/MM/YYYY")
                                  : null
                              }
                              style={{ fontSize: "12px" }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                       
                      </div>

                      <div className="col-12 col-md-6 mt-4">
                          <div className="row">
                            <div className="col-6 mt-2">
                              <ArthaCancelButton
                                onClick={handleReset}
                                style={{
                                  width: "70%",
                                  padding: "0",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                Reset
                              </ArthaCancelButton>
                            </div>
                            <div className="col-6 mt-2">
                              <ArthaApplyButton
                                disabled={!isFormValid}
                                style={{
                                  width: "70%",
                                  fontSize: "10px",
                                  color: isFormValid ? "white" : "white",
                                  opacity: isFormValid ? 1 : 0.37,
                                }}
                                onClick={handleCheckEligibilityClick} // Add this onClick handler
                              >
                                Check Eligibility
                              </ArthaApplyButton>
                            </div>
                          </div>
                        </div>

                      {isFormVisible && !isResetClicked && (
                        <div className="row mt-2">
                          <span className="smallLable ps-3 ">
                            Eligibility Loan{" "}
                          </span>
                          <br />
                          <span className="smallLable ps-3 mb-2">
                            Maximum Loan amount and tenure eligible as per
                            financial details{" "}
                          </span>
                          <div className="col-12 col-md-6">
                            <p className="small-heading mb-0">
                              Eligible Tenure
                            </p>
                            <h6 className="mt-2">12 - 60 months</h6>
                            <input
                              type="text"
                              className="form-control"
                              value={loanTenureDigit}
                              readOnly
                              //  disabled={!!loanTenure}
                              onChange={handleTenureChange}
                            />
                            <input
                              type="range"
                              className="form-range range-slider"
                              min="12"
                              max="60"
                              step={12}
                              value={loanTenureDigit}
                              onChange={handleTenureChange}
                            />

                            <div className="d-flex">
                              <p className="small-heading">12</p>
                              <p className="small-heading ms-auto">24</p>
                              <p className="small-heading ms-auto">36</p>
                              <p className="small-heading ms-auto">48</p>
                              <p className="small-heading ms-auto">60</p>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <p className="small-heading mb-0">
                              Eligible Loan Amount
                            </p>
                            <h6 className="mt-2">
                              ₹{minAmount} - ₹{maxAmount}
                            </h6>
                            <input
                              type="text"
                              className="form-control"
                              value={loanAmountCalculation}
                              readOnly
                              // disabled={!!loanAmount}
                              onChange={handleLoanAmountChange}
                            />

                            <input
                              type="range"
                              className="form-range range-slider"
                              min={minAmount}
                              max={maxAmount}
                              step={25000}
                              value={loanAmountCalculation}
                              onChange={handleLoanAmountChange}
                            />
                            <div className="d-flex">
                              <p className="small-heading">₹{minAmount}</p>
                              <p className="small-heading ms-auto">
                                ₹{maxAmount}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <span
                        className=" p-0"
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        Monthly Income Breakup{" "}
                      </span>
                      <div id="chart">
                        <ReactApexChart
                          options={chartData.options}
                          series={chartData.series}
                          type="donut"
                        />
                      </div>
                      <div className="row">
                        <div className="col-7 p-0">
                          <img src={GreenDot} alt="" />{" "}
                          <span style={{ fontSize: "12px" }}>
                            Balance Amount
                          </span>
                        </div>
                        <div className="col-5 p-0">
                          <span className="rsSymbol">
                            ₹{" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "bold",
                              }}
                            >
                              {finalAmount}
                            </span>
                          </span>
                        </div>
                        <div className="col-7 p-0 mt-2  ">
                          <img src={GrayDot} alt="" />
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            Total Obligation/EMI
                          </span>
                        </div>
                        <div className="col-5 mt-2 p-0">
                          <span
                            className="rsSymbol"
                            style={{ color: "#808080 !important" }}
                          >
                            ₹{" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {finalEMI}
                            </span>
                          </span>
                        </div>
                        <div className="col-12 text-center p-0 mt-5  ">
                          <span
                            style={{
                              color: "black",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            EMI:{" "}
                          </span>
                          <span style={{ color: "#28A745 !important" }}>
                            ₹{" "}
                            <span
                              style={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {emi}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {isFormVisible && !isResetClicked && (
                    <span
                      style={{
                        color: "green",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      "Ready to get started on your loan journey? Just click
                      ‘Apply' in the 'Loan Types’ section that suits your needs,
                      and let's get your application started!"
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-5">
            <div className="row">
              <div className="col-12">
                <div className="creditScore">
                  <h6>Credit Score</h6>
                  <p className="creditscore-subheading">
                    Get you Free CIBIL score and report instantly
                  </p>
                  <div className="row col-12 d-flex justify-content-center my-2">
                    <ArthaApplyButton
                      type="button"
                      className="custom_btn mx-auto"
                    >
                      Check Score
                    </ArthaApplyButton>
                  </div>
                  <p className="creditscore-subheading2">
                    Your Personal Information is 100% secured with us. We do not
                    share your data with any third party.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <div className="creditScore">
                  <h6>Exciting Deals</h6>

                  <img
                    src={Goibibo}
                    className="offerImages"
                    alt="Image Not Found"
                  />
                  <img
                    src={MakeMyTrip}
                    className="offerImages"
                    alt="Image Not Found"
                  />
                  <img
                    src={HubLot}
                    className="offerImages"
                    alt="Image Not Found"
                  />
                  <img
                    src={NetFlix}
                    className="offerImages"
                    alt="Image Not Found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
