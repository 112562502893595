import React, { useEffect, useState, useRef } from "react";
import AdharOTPVerification from "./AdharOTPVerification";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaTextField from "../../fields/ArthaTextField";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import KYCDetailsForm from "./KYCDetailsForm";
import { handleSignOut } from "./SessionExpiredApi";
import { businessLoanInitiateKycAutoAPI } from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";


export default function KYCAdharVerification ( { onContinue, onBack, onCancel, setTenureFlag } ) {
  const [ formData, setFormData ] = useState( {
    personName: "",
    gender: "",
    residenceOwner: "",
  } );
  const [ showAdharOTPVerification, setShowAdharOTPVerification ] =
    useState( false );
  const [ showKYCDetailsForm, setShowKYCDetailsForm ] = useState( false );
  const [ uid, setUid ] = useState( "" );
  const [ error, setError ] = useState( "" );
  const [ isContinueButtonDisabled, setIsContinueButtonDisabled ] =
    useState( true );
  const navigate = useNavigate();

  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error?.response?.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error?.response?.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const handleBack = () => {
    onBack();
  };

  const handleContinue = async () => {

    if ( uid.trim() === '000000000000' ) {
      setError( "Please enter a valid Aadhaar number." );
      return;
    }

    const data = {
      uid: uid,
    };

    try {
      const response = await businessLoanInitiateKycAutoAPI( data ); 
        if ( response.data.httpResponseCode === 200 && response.data.businessStatusCode === 2 ) {
          setShowAdharOTPVerification( true );
          // navigate( AdharOTPVerification, {
          //   state: {
          //     ap_id, loan_app_id, uid
          //   },
          // } );
        } else {
          setError( "Please check your Aadhaar number and try again." );
        }
    } catch ( error ) {
      handleApiError(error);
      console.error( 'Error fetching loan data:', error );
    }
  };
 
  const handleBackForOTP = () => {
    setShowAdharOTPVerification( false );
  }
  const handleAdharChange = ( e ) => {
    const adharValue = e.target.value.replace( /\D/g, "" );
    const formattedAdhar = adharValue.replace(
      /(\d{4})(\d{4})(\d{4})/,
      "$1 $2 $3"
    );
    setUid( adharValue );
    setFormData( { ...formData, personName: formattedAdhar } );
    setError( "" );
    setIsContinueButtonDisabled( adharValue.trim().length !== 12 );
  };

  return (
    <>
    {/* { showKYCDetailsForm ? (
        <KYCDetailsForm
          onContinue={onContinue}
          onCancel={onCancel}
          setTenureFlag={setTenureFlag}
        />
      ) :  */}
      {showAdharOTPVerification ? (
        <AdharOTPVerification
          onContinue={onContinue}
          setTenureFlag={setTenureFlag}
          handleBackForOTP={handleBackForOTP}
          onCancel={onCancel}
          uidprops={uid}
          onBack={onBack}
        />
      ) : (
        <>
          <div className="KYCAdharVerification">
            <div className="AdharKYCheading">
              <h6>We couldn't find CKYC linked to your PAN.</h6>
              <h6>Please Verify your Aadhaar for KYC</h6>
            </div>
            <div className=" row justify-content-center mt-5">
              <div className="col-12 col-md-6">
                <ArthaTextField
                  name="adharNumber"
                  fullWidth
                  label="Aadhaar Card No."
                  value={uid}
                  onChange={handleAdharChange}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 12,
                  }}
                  error={Boolean( error )}
                  helperText={error}
                  className={Boolean( error ) ? "error-border" : ""} // Apply red border class if there's an error
                />
                <p className="msg">OTP verification will be needed</p>
              </div>

            </div>

            <div className="row mt-5">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton type="button" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={isContinueButtonDisabled}
                  style={{
                    color: "white",
                    backgroundColor: "rgb(255, 98, 0)",
                    opacity: isContinueButtonDisabled ? 0.39 : 1,
                  }}
                >
                  Get OTP
                </ArthaContinueButton>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}