import React, { useState, useRef, useEffect } from "react";
import { Button, Checkbox, Dialog } from '@mui/material';
import TermsPopup from "../Loan/BusinessLoan/TermsPopup";
import ArthaContinueButton from "../fields/ArthaContinueButton";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { consentMobileOtpAPI, consentMobileVerifyAPI, fetchCustomerContentAPI } from "../actioncreators/actioncreators"
import { FaCheckCircle } from "react-icons/fa";
import LoadingSpinner from "../fields/spinner";
import { useLocation } from "react-router-dom";
import { green } from "@mui/material/colors";
const CustomerConsent = ({ }) => {
  const [consentId, setConsentId] = useState(null); // Store the consentId in state
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [loanType, setLoanType] = useState("");
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
  const [roUserName, setRoUserName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPan, setCustomerPan] = useState("");
  const [cutomerMobile, setCustomerMobile] = useState("");
  const navigate = useNavigate();
  const [isOtpResend, setOtpResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading2, setIsLoading2] = useState(false);
  const [isMobileVerify, setIsMobileVerify] = useState(false);
  const [resendTimer, setResendTimer] = useState(120);
  const [isResendClickable, setIsResendClickable] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [apiError, setApiError] = useState("");

  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const handleAcceptTerms = () => {
    setIsTermsAccepted(true);
    closeModal();
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    window.close();
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const handleAgreeChange = () => {
    setIsAgreed(true);
  };


  useEffect(() => {
    if (resendTimer > 0) {
      const intervalId = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer <= 0) {
            clearInterval(intervalId);
            setIsResendClickable(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [resendTimer]);

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const consentIdFromUrl = searchParams.get('id'); // Extract consentId from URL

      if (consentIdFromUrl) {
        setConsentId(consentIdFromUrl); // Save the consentId in state

        try {
          const response = await fetchCustomerContentAPI(consentIdFromUrl); // Fetch customer content using consentId
          console.log("Response is:", response);

          if (response.httpResponseCode === 200 && response.businessStatusSubCode === 2.1) {
            setRoUserName(response.consentData.RO_User_Name);
            setLoanType(response.consentData.Loan_Type);
            setCustomerName(response.consentData.Customer_Name);
            setCustomerPan(response.consentData.Customer_PAN);
            setCustomerMobile(response.consentData.Customer_Mobile);
            setIsMobileVerify(true);
            setIsTermsAccepted(true);
          } else if (response?.httpResponseCode === 200 && response?.businessStatusCode === 2) {
            setRoUserName(response.consentData.RO_User_Name);
            setLoanType(response.consentData.Loan_Type);
            setCustomerName(response.consentData.Customer_Name);
            setCustomerPan(response.consentData.Customer_PAN);
            setCustomerMobile(response.consentData.Customer_Mobile);
          }
        } catch (error) {
          console.error('Error fetching customer content:', error);
        }
      }
    };

    fetchData();
  }, [location.search, fetchCustomerContentAPI]);


  useEffect(() => {
    inputRefs.current[0].current?.focus();
    const timerStartTime = parseInt(localStorage.getItem("timerStartTime"));
    if (timerStartTime) {
      const elapsedTime = Math.floor((Date.now() - timerStartTime) / 1000);
      const remainingTime = Math.max(0, 120 - elapsedTime);
      setResendTimer(remainingTime);
    } else {
      saveTimerStartTime(); // Call saveTimerStartTime if timerStartTime doesn't exist in local storage
    }
  }, []);

  const saveTimerStartTime = () => {
    localStorage.setItem("timerStartTime", Date.now().toString());
  };

  const SendOtp = async () => {
    setOtp(["", "", "", "", "", ""]);
    setResendTimer(120);
    localStorage.removeItem("timerStartTime");
    saveTimerStartTime();

    const payload = {
      consentId: consentId, // Use consentId from state
    };

    try {
      // setOpenOTPDialog(true);
      const response = await consentMobileOtpAPI(payload);
      if (response?.httpResponseCode === 200) {
        setOpenOTPDialog(true);
        setIsInvalidOtp(false);
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      handleApiError(error);
      setApiError("An error occurred while communicating with the server.");
    }
  };


  const handleReSendOtp = async () => {
    setOtp(["", "", "", "", "", ""]);
    setResendTimer(120);
    localStorage.removeItem("timerStartTime");
    saveTimerStartTime();

    const payload = {
      consentId: consentId, // Use consentId from state
    };

    try {
      // setOpenOTPDialog(true);
      const response = await consentMobileOtpAPI(payload);
      // setOpenOTPDialog(true);
      // setIsInvalidOtp(false);
      if (response.httpResponseCode === 200 && response.businessStatusCode === 2) {
        setOpenOTPDialog(true);
        setIsInvalidOtp(false);
        // setOtpResend(true);
        toast.success("OTP Resend Successfully");
      } else {
        toast.error(response.data.message);
        setApiError("Login failed. Invalid credentials.");
      }
    } catch (error) {
      handleApiError(error);
      setApiError("An error occurred while communicating with the server.");
    }
  };

  const [isInvalidOtp, setIsInvalidOtp] = useState(true);
  // useEffect(() => {
  //   if (inputRefs.current[0]) {
  //     inputRefs.current[0].focus();
  //   }
  // }, [])
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Updated to have six elements in the array

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsButtonClicked(true);
    setOpenOTPDialog(false);

    const requestBody = {
      consentId: consentId, // Use consentId from state
      otp: otp.join(""),    // Join the otp array into a string
    };

    if (otp.join("") === "") {
      setIsInvalidOtp(true);
      inputRefs.current[0].current?.focus();
      setIsLoading(false);
      setIsButtonClicked(false);
      return;
    }

    try {
      const response = await consentMobileVerifyAPI(requestBody);
      if (response?.httpResponseCode === 200 && response.businessStatusCode === 2) {
        setIsMobileVerify(true);
        setOpenOTPDialog(false);
      } else if (response.httpResponseCode === 200 && response.businessStatusCode === 1) {
        setIsInvalidOtp(true);
        setOpenOTPDialog(true);
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      handleApiError(error);
      setApiError("An error occurred while communicating with the server.");
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };


  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== "" && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].current?.focus();
      }

      if (newOtp.join("").length === 1) {
        setIsInvalidOtp(false);
      }
      setIsVerifyButtonDisabled(newOtp.join("").length !== 6);
    }
  };

  const otpString = otp.join('');
  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].current?.focus();
    }
  };


  return (
    <>
      <div className="container py-3 mx-auto">
        <div className="row px-5 py-3 border bg-white rounded-lg shadow-lg ">
          <h3 style={{ color: "orange", fontWeight: "bold" }} className="text text-center">Customer Consent</h3>

          <div className="text-justify mx-3 my-1">
            <p className="leading-relaxed text-gray-700">
              I have verified the following details related to my loan application. I provide consent to Relationship Officer
              <span className="font-semibold"> {roUserName} </span> to complete the
              <span className="font-semibold"> {loanType}</span> application along with all personal details and documents on my behalf.
            </p>
          </div>

          <style>
            {`
          .custom-list-item::marker {
            font-size: 1.5rem; /* Adjust the size as needed */
          }
          .terms-conditions {
            color: blue;
            cursor: pointer;
            text-decoration: underline; /* Underline for better visibility */
          }
            .font-semibold {
            font-weight: 900; /* Increase the font-weight */
          }
        `}

          </style>

          <ul className="mb-4 ps-5 ">
            <li className="custom-list-item">
              <p><strong className="font-bold">Name :</strong> {customerName}</p>
            </li>
            <li className="custom-list-item">
              <p><strong className="font-bold">PAN :</strong> {customerPan}</p>
            </li>
            <li className="custom-list-item">
              <p><strong className="font-bold">Mobile :</strong> {cutomerMobile}</p>
            </li>
          </ul>

          <div className="ps-4" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontFamily: "Arial, Helvetica, sans-serif", fontSize: '16px', fontWeight: 'bold' }}>
            <input
              type="checkbox"
              id="agree"
              onChange={handleAgreeChange}
              checked={isTermsAccepted}
              disabled={isTermsAccepted}
              style={{
                marginRight: '8px',
                cursor: !isTermsAccepted ? "not-allowed" : "pointer",
                width: '20px',
                height: '15px'
              }}
            />
            <span style={{ marginRight: '8px' }}>I agree to all</span>
            <a
              onClick={openPopup}
              style={{ color: '#0000FF', textDecoration: 'underline', fontStyle: 'italic', cursor: 'pointer', marginRight: '4px' }}
            >
              Privacy policy,
            </a>
            <a
              onClick={openPopup}
              style={{ color: '#0000FF', textDecoration: 'underline', fontStyle: 'italic', cursor: 'pointer', marginRight: '4px' }}
            >
              Terms and Conditions,
            </a>
            <a
              onClick={openPopup}
              style={{ color: '#0000FF', textDecoration: 'underline', fontStyle: 'italic', cursor: 'pointer', marginRight: '8px' }}
            >
              Finaleap Finserv Terms and Conditions
            </a>
            <span>for the same.</span>
            <TermsPopup
              isOpen={isOpen}
              closeModal={closeModal}
              onAcceptTerms={() => handleAcceptTerms(setIsTermsAccepted)}
              isTermsAccepted={isTermsAccepted}
            />
          </div>

          <hr style={{ width: "100%", margin: "20px", marginTop: "20px", borderColor: "#000000", borderWidth: "1px" }} className="mx-auto" />

          {/* Conditionally render content below the HR based on isConsentGiven */}
          {!isMobileVerify ? (
            <>
              <p style={{ fontWeight: "bold" }} className="text mt-1">Please click the 'Provide Consent' button to receive OTP on your mobile number.</p>

              <div className="col-12 d-flex justify-content-center my-2">
                <ArthaContinueButton
                  variant="contained"
                  onClick={SendOtp}
                  disabled={!isTermsAccepted}
                >
                  Provide Consent
                </ArthaContinueButton>
              </div>
            </>
          ) : (
            <>
              <div className="container-fluid mb-4">
                <div className="row align-items-center">
                  <div className="col-12 col-sm-8 d-flex align-items-center text-success mb-3 mb-sm-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="120" fill="currentColor" className="bi bi-check me-3" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                    </svg>
                    <h5 className="fs-2 font-bold m-0 mt-4" style={{ fontSize: "100px", color: "green" }}>
                      Thank You for providing consent !!
                    </h5>
                  </div>
                  <div className="col-12 col-sm-4 mt-2 text-center text-sm-end">

                    <ArthaContinueButton
                      style={{ width: "180px" }}
                      onClick={handleClose}
                    >
                      Close
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Dialog
        open={isOpenOTPDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '500px', // Adjust the width as needed
            height: '400px', // Adjust the height as needed
            maxWidth: 'none', // Ensure the width is not constrained by the default maxWidth
          },
        }}
      >
        <div className="login-form-new mt-4 ">
          <b className="title mb-3 mt-2 d-flex justify-content-center">Verify OTP</b>
          <div className="enterotp d-flex justify-content-center">
            <div className="row">
              <div className=" col-12 enter-the-6-digit">
                Enter the 6-digit code we have sent to{" "}
              </div>
              <h6 className="verify-phone-number d-flex justify-content-center" style={{ fontWeight: "900" }}>
                +91 {cutomerMobile}
                {/* <a href="/">
            <FaEdit className="fa-edit-icon ms-2" />
          </a> */}
              </h6>
            </div>
          </div>
          <div className="Verify-OTP d-flex justify-content-center">
            <div className="Verify-OTP-section ">

              <div className="otp-inputs">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    className={`loginOtp-input ${isInvalidOtp ? "error" : ""}`}
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(index, e)}
                    ref={inputRefs.current[index]}
                  />
                ))}
                {isInvalidOtp && (
                  <p style={{ color: "red", fontSize: "10px" }}>Invalid OTP</p>
                )}

                {/* {isOtpResend && (
              <p style={{ color: "green", fontSize: "10px" }}>
                OTP Resend Successfully
              </p>
            )} */}
              </div>

              <br />
              {resendTimer > 0 ? (
                <h6 className="text-center">
                  Resend OTP in:
                  {" "}{resendTimer < 10 ? `0${resendTimer}` : resendTimer} seconds
                </h6>
              ) : (
                <h6 className="text-center">
                  Didn't receive any code?{" "}
                  <a
                    style={{ color: "rgb(255, 136, 0)", textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleReSendOtp} disabled={!isResendClickable}
                  >
                    <h6 className="resend-otp1">Resend OTP</h6>
                  </a>
                </h6>

              )}

            </div>
          </div>
          <a
            href={isInvalidOtp ? "invalidotp" : "/home"}
            className="btn btn-rounded verify-proceed-btn mt-4 d-flex justify-content-center ml-auto mr-auto"
            onClick={handleVerifyClick}
            style={{
              background: "rgb(253, 95, 7)",
              width: "250px",
              borderRadius: "20px",
              // marginTop: "15px",
              // marginLeft: "130px",
              // marginBottom: "10px",
              fontWeight: 700,
              textTransform: "none",
              color: isVerifyButtonDisabled ? "white" : "white",
              opacity: isVerifyButtonDisabled || isButtonClicked ? "0.34" : "1",
              pointerEvents: isVerifyButtonDisabled || isButtonClicked ? "none" : "auto",
            }}
            disabled={isVerifyButtonDisabled}
          >
            {isLoading ? (
              <LoadingSpinner style={{ pointerEvents: 'none' }} />
            ) : (' Verify & Proceed'
            )}
          </a>
        </div>
      </Dialog>

    </>
  );
};

export default CustomerConsent;