import React, { useEffect, useState, useRef } from "react";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import KYCAdharVerification from "./KYCAdharVerification";
import KYCDetailsForm from "./KYCDetailsForm";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import Slider from "@mui/material/Slider";
import {
  propertyLoanStoreDecisionEngine1DetailsAPI,
  propertyLoanFetchDataAPI,
  propertyLoanFetchDe1Details,
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
export default function EligibilityTenureForm({
  onContinue,
  onCancel,
  ckycSuccess,
  setTenureFlag,
  changeStatus,
}) {
  const navigate = useNavigate();
  const [showKYCDetailsForm, setShowKYCDetailsForm] = useState(false);
  const [showKYCAdharVerification, setShowKYCAdharVerification] =
    useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [response, setResponse] = React.useState({});
  const [loanAmount1, setLoanAmount] = useState();
  const [minimumLoan, setMinAmount] = useState();
  const [loanTenure1, setTenure] = useState();
  const [tenure1, setTenure1] = useState([]);
  const [emi, setEmi] = useState();
  const [emiPerLakh, setEmiPerLakh] = useState();
  const [maxLoanAmount1, setMaxLoanAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [tenureDropdown, setTenureDropdown] = React.useState([]);

  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchDe1Details();
        setResponse(response);
        setLoanAmount(response?.loanAmount);
        setTenure(response?.loanTenure);
        setEmi(response?.tentativeEmi || 0);
        setTenure1(response?.tenure);
        setMinAmount(response?.minimumLoan);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);
  // Fetching dropdown Values
  useEffect(() => {
    let apiPayload = {
      source: "json_value",
      key: "TENURE_MONTHS",
      type: "LAP_LOAN",
    };
    fetchDropDownValues(apiPayload, "TENURE_MONTHS");
  }, []);

  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    setTenureDropdown([]);
    try {
      const response = await propertyLoanFetchDataAPI(apiPayload);
      if (dropdownType === "TENURE_MONTHS") {
        setTenureDropdown(response?.data?.[0]?.values);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };
  const minTenure = Math.min(...tenureDropdown);
  const maxTenure = Math.max(...tenureDropdown);

  useEffect(() => {
    calculateEmi();
  }, [loanAmount1, loanTenure1, emiPerLakh]);

  useEffect(() => {
    // Set tenure state only if it's not already set
    if (!tenure1) {
      setTenure(response?.loanTenure);
    }
    // Map values from the tenure array based on the initial loanTenure
    const initialTenureObj =
      tenure1 && tenure1.find((obj) => obj.Tenure === loanTenure1);

    if (initialTenureObj) {
      setEmiPerLakh(initialTenureObj.EMIPerLakh);
      const roundedMaxLoanAmount1 =
        Math.floor(initialTenureObj.MaXLoanAmount / 1000) * 1000;
      setMaxLoanAmount(roundedMaxLoanAmount1);
    }
  }, [loanAmount1, emiPerLakh, tenure1, loanTenure1]);

  useEffect(() => {
    // Update loanAmount1 based on the condition
    if (loanAmount1 > maxLoanAmount1) {
      setLoanAmount(maxLoanAmount1);
    }
  }, [loanAmount1, maxLoanAmount1]);

  useEffect(() => {
    // Calculate EMI when either loan amount or tenure changes
    calculateEmi();
  }, [loanAmount1, loanTenure1]);

  useEffect(() => {
    // Update maxLoanAmount when tenure changes
    setMaxLoanAmount(getMaxLoanAmount(loanTenure1));
  }, [loanTenure1]);

  const calculateEmi = () => {
    // EMI Calculation logic (you can replace this with your own calculation)
    const principal = loanAmount1 || 0;
    const emiperklakh = emiPerLakh || 0;

    const emiValue = (principal * emiperklakh) / 100000;
    setEmi(Math.round(emiValue));
  };

  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading(true);

    const data = {
      loanAmount: loanAmount1?.toString(),
      loanTenure: loanTenure1?.toString(),
    };

    try {
      const response = await propertyLoanStoreDecisionEngine1DetailsAPI(data);
      if (response?.data) {
        console.log("businessStatusCode", response.data.businessStatusCode);

        if (response?.data?.businessStatusCode === 2) {
          changeStatus("INITIAL_LOGIN_FEE_PENDING");
        } else if (
          response.data.businessStatusSubCode === 1.1 ||
          response.data.businessStatusSubCode === 2.1
        ) {
          setActiveStep(2);
          setShowKYCAdharVerification(true);
          ckycSuccess(false);
          onContinue();
        } else {
          navigate("/home/completedapplication");
        }
      } else {
        navigate("/home/completedapplication");
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleLoanAmountChange = (event) => {
    setLoanAmount(parseInt(event.target.value, 10));
  };

  const getMaxLoanAmount = (selectedTenure) => {
    if (Array.isArray(tenure1)) {
      const selectedTenureObj = tenure1.find(
        (obj) => obj.Tenure === selectedTenure
      );
      if (selectedTenureObj) {
        const emiPerLakh = selectedTenureObj.EMIPerLakh;
        setEmiPerLakh(emiPerLakh);
        const roundedMaxLoanAmount1 =
          Math.floor(selectedTenureObj.MaXLoanAmount / 1000) * 1000;
        return roundedMaxLoanAmount1;
      } else {
        // Handle the case where the selected tenure is not found
        return maxLoanAmount1; // or provide a default value
      }
    } else {
      // Handle the case where tenure is not an array
      return maxLoanAmount1; // or provide a default value
    }
  };

  const handleTenureChange = (event) => {
    const newTenure = parseInt(event.target.value, 10);
    setTenure(newTenure);
    const newMaxLoanAmount = getMaxLoanAmount(newTenure);
    setMaxLoanAmount(newMaxLoanAmount);
  };

  const handleBack = (event) => {
    changeStatus("ELIGIBILITY_DETAILS");
  };
  return (
    <>
      {showKYCDetailsForm && activeStep === 2 ? (
        <KYCDetailsForm
          onContinue={onContinue} // Pass onContinue function to KYCDetailsForm
          onCancel={onCancel} // Pass onCancel function to KYCDetailsForm
        />
      ) : showKYCAdharVerification && activeStep === 2 ? (
        <KYCAdharVerification
          setTenureFlag={setTenureFlag}
          onContinue={onContinue} // Pass onContinue function to KYCAdharVerification
          onCancel={onCancel} // Pass onCancel function to KYCAdharVerification
        />
      ) : (
        <>
          <div className="tenure-heading">
            <h6 className="text">
              Congrats! Here's Your eligible amount & tenure
            </h6>
            <p className="small-heading">
              Loan Amount & tenure set here will be used for a loan offer in the
              subsequent step
            </p>
          </div>

          <form className="row mt-4">
            <div className="tenure">
              <div className="row">
                <div className="col-12 col-md-6">
                  <p className="small-heading"> Approximate EMI/Month </p>
                  <h4>₹ {emi?.toLocaleString("en-IN")}</h4>
                  <p className="small-heading">
                    *EMI is subject to change based on further checks
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <p className="small-heading mb-0">Eligible Tenure</p>
                  <h6 className="mt-2">
                    {minTenure} - {maxTenure} months
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    value={loanTenure1}
                    disabled={!!response?.loanTenure}
                    onChange={handleTenureChange}
                  />
                  <Slider
                    style={{ color: "#10215F" }}
                    className="form-range range-slider"
                    defaultValue={loanTenure1}
                    min={minTenure} // Set the minimum value dynamically
                    max={maxTenure} // Set the maximum value dynamically
                    step={12} // Adjust step value as needed
                    onChange={handleTenureChange}
                  />

                  <div className="d-flex">
                    {tenureDropdown?.map((item, index) => (
                      <p
                        key={index}
                        className={`small-heading ${
                          !tenureDropdown?.includes(item) ? "d-none" : ""
                        } ${index !== 0 ? "ms-auto" : ""}`}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <p className="small-heading mb-0">Eligible Loan Amount</p>
                  <h6 className="mt-2">
                    ₹ {minimumLoan?.toLocaleString("en-IN")} - ₹{" "}
                    {maxLoanAmount1?.toLocaleString("en-IN")}
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    value={loanAmount1?.toLocaleString("en-IN")}
                    disabled={!response?.loanAmount1}
                    onChange={handleLoanAmountChange}
                  />

                  {/* <Slider
                    style={{ color: "#10215F" }}
                    className="form-range range-slider"
                    min={minimumLoan}
                    max={maxLoanAmount1}
                    step={25000}
                    defaultValue={loanAmount1}
                    onChange={handleLoanAmountChange}
                  /> */}
                  <Slider
                    style={{ color: "#10215F" }}
                    className="form-range range-slider"
                    min={minimumLoan}
                    max={maxLoanAmount1}
                    step={25000}
                    value={loanAmount1} // Use value instead of defaultValue
                    onChange={handleLoanAmountChange}
                  />

                  <div className="d-flex">
                    <p className="small-heading">
                      ₹{minimumLoan?.toLocaleString("en-IN")}
                    </p>
                    <p className="small-heading ms-auto">
                      ₹{maxLoanAmount1?.toLocaleString("en-IN")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton type="button" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  type="button"
                  onClick={handleContinue}
                  style={{
                    opacity: isButtonClicked ? 0.39 : 1,
                    pointerEvents: isButtonClicked ? "none" : "auto",
                  }}
                  // disabled
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Continue"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
