import React, { useState, useEffect } from "react";
import {
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogContent,
} from "@mui/material";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import ArthaTextField from "../../fields/ArthaTextField";
import { toast } from "react-toastify";
import { handleSignOut } from "./SessionExpiredApi";
import { useNavigate } from "react-router-dom";
import { propertyLoanFetchDataAPI } from "../../actioncreators/actioncreators";

const AddBank = ({ openBankDialog, onCancel, onBankDataSave, firmDetails }) => {
    const initialFormState = { bankName: "", accType: "", accNo: "", accNoError: "" };
    // const [savedBankForms, setSavedBankForms] = useState([]);
    const [savedState, setSavedState] = useState(null);
    const [bankForms, setBankForms] = useState([initialFormState]);
    const [initialBankForms, setInitialBankForms] = useState(initialFormState);
    const [bankNames, setBankNames] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true); // State to manage save button disabled status

    useEffect(() => {
        if (firmDetails.bankAccDetails) {
            const bankAccNames = firmDetails.bankAccDetails.map((acc) => ({
                accType: acc.accType,
                accNo: acc.accNo,
                bankName: acc.bankName,
                accNoError: ""
            }));
            // setInitialBankForms(bankAccNames); // Set initial form state
            onBankDataSave(bankAccNames);
            setSavedState(bankAccNames);
            setBankForms(bankAccNames);
        }
    }, [firmDetails]);

    useEffect(() => {
        if (openBankDialog) {
            // If there's a saved state, use it; otherwise use empty template
            if (savedState) {
                setBankForms(JSON.parse(JSON.stringify(savedState))); // Deep copy to prevent reference issues
            } else {
                setBankForms([{ ...initialFormState }]);
            }
        }
    }, [openBankDialog, savedState]);

    useEffect(() => {
        // fetchBankNames();
    }, []);

    useEffect(() => {
        // Check if any form is incomplete or has an error
        const anyFormIncomplete = bankForms.some(
            (form) =>
                form.bankName === "" ||
                form.accType === "" ||
                form.accNo === "" ||
                form.accNoError
        );
        // Disable save button if any form is incomplete
        setIsSaveDisabled(anyFormIncomplete);
    }, [bankForms]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            const payload = {
                type: "BANK_NAME_LIST",
                key: "BANKS",
                source: "json_value",
            };

            try {
                const dropdownResponse = await propertyLoanFetchDataAPI(payload); // Replace with your dropdown options API call function
                // Flatten the nested "banks" arrays
                const banks = dropdownResponse.data.flatMap((item) => item.banks);
                setBankNames(banks);
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        // Handle unauthorized error
                        await handleSignOut();
                        toast.error(
                            "Your Session has expired.You will be redirected to Login Page."
                        );
                        navigate("/");
                    } else if (error.response.status === 429) {
                        // Handle rate limit exceeded error
                        toast.error(
                            "Too Many Requests: You have exceeded the rate limit. Please try again later."
                        );
                    } else {
                        // Handle other server errors
                        toast.error(
                            "There appears to be a technical issue connecting to our servers. Could you please try again later."
                        );
                    }
                }
                console.error("Error fetching dropdown options:", error);
                // setError(error.message);
            }
        };

        fetchDropdownOptions();
    }, []);

    const handleAddForm = () => {
        if (bankForms.length < 5) {
            setBankForms([...bankForms, { ...initialFormState }]);
        }
    };

    const handleFormChange = (index, field, value) => {
        const updatedForms = [...bankForms];
        if (field === "accNo") {
            if (/^[a-zA-Z0-9]*$/.test(value)) {
                updatedForms[index][field] = value;
                updatedForms[index].accNoError = "";
            } else {
                updatedForms[index].accNoError = "Account number cannot contain special characters";
            }
        } else {
            updatedForms[index][field] = value;
        }
        setBankForms(updatedForms);
    };
    // const handleFormChange = (index, field, value) => {
    //     const updatedForms = [...bankForms];

    //     if (field === "accNo") {
    //       // Validate account number (allow only alphanumeric)
    //       if (/^[a-zA-Z0-9]*$/.test(value)) {
    //         updatedForms[index][field] = value;
    //         updatedForms[index].accNoError = ""; // Clear error for this field
    //       } else {
    //         updatedForms[index].accNoError = "Account number cannot contain special characters"; // Set error for this field
    //       }
    //     } else {
    //       updatedForms[index][field] = value;
    //     }
    //     setBankForms(updatedForms);
    //   };
    const save = () => {
        onBankDataSave(bankForms);
    };
    const handleSave = () => {
        // Update the saved state with the current forms
        setSavedState(JSON.parse(JSON.stringify(bankForms))); // Deep copy to prevent reference issues
        onBankDataSave(bankForms);
    };

    // Cancel button handler to reset the bank forms
    //  const cancel = () => {
    //     setBankForms(initialBankForms); // Reset the bankForms to the initial state
    //     onCancel(); // Call the onCancel function passed via props to close the dialog
    //   };
    const handleCancel = () => {
        // Reset to the last saved state or empty template
        if (savedState) {
            setBankForms(JSON.parse(JSON.stringify(savedState))); // Deep copy to prevent reference issues
        } else {
            setBankForms([{ ...initialFormState }]);
        }
        onCancel();
    };

    const handleRemoveForm = (index) => {
        if (bankForms.length > 1) {
            setBankForms(bankForms.filter((_, i) => i !== index));
        }
    };

    return (
        //     <Dialog open={openBankDialog}>
        //     <DialogContent className="modal row overflow-auto">
        //       <div className="modal-content col-md-6">
        //         <h6 className="text">Add Bank Details</h6>
        //         {bankForms.map((form, index) => (
        //           <div className="border p-2 rounded mb-3" key={index}>
        //             <div className="row">
        //               <div className="col-12 col-md-6">
        //                 <ArthaFormControl fullWidth>
        //                   <InputLabel id={`bankName-${index}`}>Bank</InputLabel>
        //                   <Select
        //                     labelId={`bankName-${index}`}
        //                     value={form.bankName}
        //                     onChange={(e) =>
        //                       handleFormChange(index, "bankName", e.target.value)
        //                     }
        //                   >
        //                     {bankNames.map((item, idx) => (
        //                       <MenuItem key={idx} value={item}>
        //                         {item}
        //                       </MenuItem>
        //                     ))}
        //                   </Select>
        //                 </ArthaFormControl>
        //               </div>
        //               <div className="col-12 col-md-6">
        //                 <ArthaFormControl fullWidth>
        //                   <InputLabel id={`accType-label-${index}`}>
        //                     Account Type
        //                   </InputLabel>
        //                   <Select
        //                     labelId={`accType-label-${index}`}
        //                     value={form.accType}
        //                     onChange={(e) =>
        //                       handleFormChange(index, "accType", e.target.value)
        //                     }
        //                   >
        //                     <MenuItem value="Saving">Saving</MenuItem>
        //                     <MenuItem value="Current">Current</MenuItem>
        //                     <MenuItem value="CCOD">CCOD</MenuItem>
        //                   </Select>
        //                 </ArthaFormControl>
        //               </div>
        //             </div>
        //             <div className="row mt-2">
        //               <div className="col-12 col-md-6">
        //                 <ArthaTextField
        //                   value={form.accNo}
        //                   onChange={(e) =>
        //                     handleFormChange(index, "accNo", e.target.value)
        //                   }
        //                   label="Account Number"
        //                   fullWidth
        //                   inputProps={{ maxLength: 17 }}
        //                   error={!!form.accNoError}
        //                   helperText={form.accNoError}
        //                 />
        //               </div>
        //               <div className="col-12 col-md-2 d-flex align-items-end">
        //                 <ArthaClearButton
        //                   type="button"
        //                   onClick={() => handleRemoveForm(index)}
        //                 >
        //                   Remove Bank
        //                 </ArthaClearButton>
        //               </div>
        //             </div>
        //           </div>
        //         ))}
        //         <div className="row mt-2">
        //             <div className="col-12 col-sm-12 col-md-4 col-lg-4">
        //               <ArthaClearButton variant="contained" onClick={handleAddForm}>
        //                 Add Bank
        //               </ArthaClearButton>
        //             </div>
        //             <div className="col-12 col-sm-12 col-md-6 col-lg-6 pt-2">
        //               <span className="text-primary">
        //                 Maximum 5 account details can be added
        //               </span>
        //             </div>
        //           </div>
        //           <div className="row mt-4 mx-auto">
        //             <div className="col-6 d-flex justify-content-end mt-4">
        //               <ArthaClearButton variant="contained" onClick={cancel}>
        //                 Cancel
        //               </ArthaClearButton>
        //             </div>
        //             <div className="col-6 d-flex justify-content-start mt-4">
        //               <ArthaContinueButton
        //                 variant="contained"
        //                 onClick={save}
        //                 disabled={isSaveDisabled}
        //               >
        //                 Save
        //               </ArthaContinueButton>
        //             </div>
        //           </div>
        //       </div>
        //     </DialogContent>
        //   </Dialog>
        <Dialog
            open={openBankDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className="modal row overflow-auto">
                <div className="modal-content col-md-6">
                    <h6 className="text">Add Bank Details</h6>
                    {bankForms.map((form, index) => (
                        <div className="border p-2 rounded mb-3" key={index}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <ArthaFormControl fullWidth>
                                        <InputLabel id={`bankName-${index}`}>Bank</InputLabel>
                                        <Select
                                            labelId={`bankName-${index}`}
                                            value={form.bankName}
                                            onChange={(e) =>
                                                handleFormChange(index, "bankName", e.target.value)
                                            }
                                        >
                                            {bankNames.map((item, idx) => (
                                                <MenuItem key={idx} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </ArthaFormControl>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ArthaFormControl fullWidth>
                                        <InputLabel id={`accType-label-${index}`}>
                                            Account Type
                                        </InputLabel>
                                        <Select
                                            labelId={`accType-label-${index}`}
                                            value={form.accType}
                                            onChange={(e) =>
                                                handleFormChange(index, "accType", e.target.value)
                                            }
                                        >
                                            <MenuItem value="Saving">Saving</MenuItem>
                                            <MenuItem value="Current">Current</MenuItem>
                                            <MenuItem value="CCOD">CCOD</MenuItem>
                                        </Select>
                                    </ArthaFormControl>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-md-6">
                                    <ArthaTextField
                                        value={form.accNo}
                                        onChange={(e) =>
                                            handleFormChange(index, "accNo", e.target.value)
                                        }
                                        label="Account Number"
                                        fullWidth
                                        inputProps={{ maxLength: 17 }}
                                        error={!!form.accNoError}
                                        helperText={form.accNoError}
                                    />
                                </div>
                                <div className="col-12 col-md-2 d-flex align-items-end">
                                    <ArthaClearButton
                                        type="button"
                                        onClick={() => handleRemoveForm(index)}
                                    >
                                        Remove Bank
                                    </ArthaClearButton>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="row mt-2">
                        <div className="col-12 col-md-4">
                            <ArthaClearButton variant="contained" onClick={handleAddForm}>
                                Add Bank
                            </ArthaClearButton>
                        </div>
                    </div>
                    <div className="row mt-4 mx-auto">
                        <div className="col-6 d-flex justify-content-end mt-4">
                            <ArthaClearButton variant="contained" onClick={handleCancel}>
                                Cancel
                            </ArthaClearButton>
                        </div>
                        <div className="col-6 d-flex justify-content-start mt-4">
                            <ArthaContinueButton
                                variant="contained"
                                // onClick={() => onBankDataSave(bankForms)}
                                onClick={handleSave}
                                disabled={isSaveDisabled}
                            >
                                Save
                            </ArthaContinueButton>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddBank;
