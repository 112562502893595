import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import PinCodeValidation from "./pinCodeValidation";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { color } from "@mui/system";
import {
  createLoanApplicationAPI,
  userDetailsAPI,
  updatePersonalDetailsAPI,
  businessLoanFetchDataAPI,
  fetchDataAPI,
  propertyLoanFetchUserDetails,
  propertyLoanUpdatePersonalDetailsAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import { InputLabel, MenuItem, Select } from "@mui/material";
const AddApplicantProfessionalDetails = ({ onSubmit, changeApplicantStatus }) => {
  const [formData, setFormData] = useState({
    panCardNo: "",
    mobileNo: "",
    fullName: "",
    dateOfBirth: "",
    emailId: "",
    employmentType: "",
    employmentSubType: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [isValidDOB, setIsValidDOB] = useState(true);
  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [businessId, setBusinessId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isSelfEmpSelected, setSelfEmpSelected] = React.useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const [professionTypeDropdown, setProfessionTypeDropdown] = React.useState([
    "Doctor",
    "CA",
    "CS",
    "CMA/ICWA (Certified Management Accountant)",
    "Lawyer",
    "Architect",
  ]);

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await createLoanApplicationAPI();
  //         if (response.businessStatusCode === 2) {
  //           setApid(response.ap_id);
  //           setLoanAppId(response.loan_app_id);
  //         }
  //         const loanApplicationStatus = response.status;
  //         navigate('/home/personalloans', {
  //           state: {
  //             loanApplicationStatus
  //           }
  //         });
  //       } catch (error) {
  //         if (error.response && error.response.status === 401) {
  //           handleSignOut();
  //           toast.error("Your Session has expired.You will be redirected to Login Page.");
  //           navigate("/");
  //         } else if (error.response && error.response.status === 429) {
  //           toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
  //         } else {
  //           toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
  //         }
  //         console.error("Error fetching user data:", error);
  //       }
  //     };
  //     fetchData();
  //   }, [ap_id, loan_app_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchUserDetails();
        const {
          mobile,
          pan,
          fullName,
          email,
          dob,
          businessId,
          commencementDate,
          businessPan,
          businessName,
          employmentType,
          employmentSubType,
          udyamAadhaar,
        } = response.data;

        setFormData((prevData) => ({
          ...prevData,
          panCardNo: pan,
          mobileNo: mobile,
          fullName: fullName,
          dateOfBirth: formatDate(dob),
          emailId: email,
          businessId: businessId,
          commencementDate: formatDate(commencementDate),
          businessPan: businessPan,
          employmentType: employmentType,
          employmentSubType: employmentSubType,
          businessName: businessName,
          udyamAadhaar: udyamAadhaar,
        }));
        setBusinessId(businessId);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [ap_id, loan_app_id]);

  // useEffect( () => {
  //     if ( isSelfEmpSelected ) {
  //       fetchDropDownValues();
  //     }
  //   }, [ ] );

  // const fetchDropDownValues = async () => {
  //     setProfessionTypeDropdown(["Doctor", "CA", "CS", "CMA/ICWA (Certified Management Accountant)", "Lawyer", "Architect"])
  //     try {
  //     //   let payload = {
  //     //     "type": "SELF_EMPLOYED",
  //     //     "key": "PROFESSIONAL",
  //     //     "source": "json_value"
  //     //   }
  //       const response = await fetchDataAPI(payload)
  //       setProfessionTypeDropdown(response.data.data[0].values)
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         await handleSignOut();
  //         toast.error("Your Session has expired.You will be redirected to Login Page.")
  //         navigate('/')
  //       } else if (error.response && error.response.status === 429) {
  //         toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
  //       }
  //       else {
  //         toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
  //       }
  //       console.error("Error fetching address vintage:", error);
  //     }
  //   };

  const formatDate = (inputDate) => {
    console.log("input date : ", inputDate);
    if (inputDate) {
      const [day, month, year] = inputDate.split("/");
      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return "";
      }

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Apply specific validation based on the field name
    let updatedValue = value;

    if (name === "panCardNo") {
      // Validate Pan card format: First 5 letters, 4 digits, 1 letter
      updatedValue = value.toUpperCase();
      const enteredPanCard = updatedValue;
      const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      setIsValidPanCard(panCardRegex.test(enteredPanCard));
    } else if (name === "fullName") {
      // Avoid digits and special symbols in Full Name
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "mobileNo") {
      // Allow only numeric values in Mobile Number
      updatedValue = value.replace(/\D/g, "");
    } else if (name === "emailId") {
      // Validate email format
      const isEmailValid =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);

      if (!isEmailValid) {
        setEmailError("Invalid Email. Please enter a valid email address.");
      } else {
        setEmailError(""); // Clear the error message if email is valid
      }

      setIsEmailValid(isEmailValid);
      updatedValue = value;
    } else if (name === "dateOfBirth") {
      const [year, month, day] = value.split("-");

      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return;
      }

      updatedValue = `${year}-${month}-${day}`;
    } else if (name === "employmentType") {
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "employmentSubType") {
      setFormData({
        ...formData,
        employmentSubType: value,
      });
    }

    setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));
    validateForm();
  };

  const validateForm = () => {
    const {
      panCardNo,
      mobileNo,
      fullName,
      dateOfBirth,
      emailId,
      employmentType,
      employmentSubType,
    } = formData;

    const isPanCardValid = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(panCardNo);
    const isMobileValid = /^\d{10}$/.test(mobileNo);
    const isFullNameValid = fullName && fullName.trim() !== "";
    let isDateOfBirthValid = false;
    const isEmailValid = /^\S+@\S+\.\S+$/.test(emailId);
    const isEmploymentTypeValid =
      employmentType && employmentType.trim() !== "";
    const isQualificationValid = !!employmentSubType;

    const currentDate = new Date();
    const selectedDate = new Date(dateOfBirth);
    const minDate = new Date(currentDate);
    minDate.setFullYear(minDate.getFullYear() - 100); // Minimum age: 100 years
    const maxDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 18); // Maximum age: 18 years

    if (selectedDate < minDate || selectedDate > maxDate) {
      isDateOfBirthValid = false;
      setIsValidDOB(false);
    } else {
      isDateOfBirthValid = true;
      setIsValidDOB(true);
    }

    setIsFormValid(
      isPanCardValid &&
        isMobileValid &&
        isFullNameValid &&
        isDateOfBirthValid &&
        isEmailValid &&
        isEmploymentTypeValid &&
        isQualificationValid
    );
  };

  const handleClear = () => {
    changeApplicantStatus("");
  };

  // const pinCodeRef = useRef(null);

  // useEffect(() => {
  //     // Focus on the Pincode input field when the component mounts
  //     const pinCodeInput = document.getElementById("pin-code-inpt");
  //     if (pinCodeInput) {
  //         pinCodeInput.focus();
  //     }
  // }, []);

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const handleContinue = async (values, setSubmitting) => {
    // changeStatus("BUSINESS_DETAILS_WITH_PAN");
    setIsButtonClicked(true);
    setIsLoading(true);
    const formattedDateOfBirth = reverseFormatDate(formData.dateOfBirth);
    const fromatedcommecementDate = reverseFormatDate(
      formData.commencementDate
    );
    const data = {
      panCardNo: formData.panCardNo,
      mobileNo: formData.mobileNo,
      fullName: formData.fullName,
      dob: formattedDateOfBirth,
      email: formData.emailId,
      employmentType: formData.employmentType,
      employmentSubType: formData.employmentSubType,
    };
    try {
      const response = await propertyLoanUpdatePersonalDetailsAPI(data);
      if (response?.data?.httpResponseCode === 200 && response?.data?.businessStatusCode === 2) {
        changeApplicantStatus(response?.data?.nextPage);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error?.response?.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error?.response?.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error storing employee history:", error);
    }
  };

  const reverseFormatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };

  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <>
      <div className="d-flex justify-content-between align-items-center ">
        <h6
          className="text mt-4"
          style={{ marginBottom: "3rem", fontWeight: 800 }}
        >
          Personal Details
        </h6>
        <div
          onClick={goToSummaryPage}
          className="text fw-bold"
          style={{
            color: "orange",
            marginLeft: "auto",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Go To Applicant Summary
        </div>
      </div>

      <h6 className="text mt-2">Personal Details</h6>
      <form className="mt-4">
        <div className="row mt-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              id="outlined-basic"
              name="panCardNo"
              value={formData.panCardNo}
              onChange={handleChange}
              label="Pan Card No."
              fullWidth
              variant="outlined"
              style={{ pointerEvents: "none" }}
                disabled
              className="form-control"
            />

            <div className="subtitle mt-2">
              <div
                className={`pan-card-validation ${
                  isValidPanCard ? "" : "invalid-input"
                }`}
              >
                {isValidPanCard
                  ? ""
                  : "Invalid Pan Card. Please enter in the specified format."}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
            <ArthaTextField
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              label="Mobile No."
              maxLength="10"
              fullWidth
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*",
              }}
              style={{ pointerEvents: "none" }}
                disabled
            />
          </div>
        </div>

        <div className="row mt-3 mt-md-4">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              name="fullName"
              value={formData.fullName}
                disabled
              onChange={handleChange}
              label="Full Name"
              fullWidth
              inputProps={{ maxLength: 80 }}
            />
            <p className="msg mt-1">As per PAN</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              type="date"
              name="dateOfBirth"
              label="Date of birth"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dateOfBirth}
              onChange={handleChange}
              style={{ pointerEvents: "none" }}
                disabled
              inputProps={{
                max: currentDate, // Set max date to current date
              }}
              fullWidth
            />
            {!isValidDOB && (
              <p className="msg" style={{ color: "red" }}>
                Invalid date
              </p>
            )}
          </div>
        </div>

        <div className="row mt-1 mt-md-1">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              type="email"
              name="emailId"
              value={formData.emailId}
              onChange={handleChange}
              label="Email"
              fullWidth
              error={Boolean(emailError)}
              helperText={emailError}
            />
          </div>
        </div>

        <div className="row mt-3 mt-md-5">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              name="employmentType"
              value={formData.employmentType}
              onChange={handleChange}
              label="Employment Details"
              InputLabelProps={{
                maxLength: 80,
                shrink: true,
                style: {
                  color: "#000", // Ensure the label color is not affected by the disabled state
                },
              }}
              fullWidth
              disabled
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              name="employmentSubType"
              value={formData.employmentSubType}
              // onChange={handleChange}
              label="Type of Profession"
              InputLabelProps={{
                maxLength: 80,
                shrink: true,
                style: {
                  color: "#000", // Ensure the label color is not affected by the disabled state
                },
              }}
              fullWidth
              disabled
            />
          </div>
        </div>

        <div className="row mt-4 mx-auto">
          <div className="col-6 d-flex justify-content-end mt-5">
            <ArthaClearButton
              variant="contained"
              onClick={handleClear}
              // disabled={!isFormValid}
              // style={clearButtonStyle}
            >
              Cancel
            </ArthaClearButton>
          </div>

          <div className="col-6 d-flex justify-content-start mt-5">
            <ArthaContinueButton
              variant="contained"
              onClick={handleContinue}
              disabled={!isFormValid}
              style={buttonStyle}
            >
              {isLoading ? (
                <LoadingSpinner style={{ pointerEvents: "none" }} />
              ) : (
                "Continue"
              )}
            </ArthaContinueButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddApplicantProfessionalDetails;
