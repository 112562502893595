import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ApplicantSummaryDetails from "./ApplicantsSummary.jsx";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  propertyLoanFetchUserDetails,
  propertyLoanUpdatePersonalDetailsAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import EligibilityCheckForm from "../PersonalLoan/EligibilityCheckForm.jsx";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

import { Button } from "@mui/material";
import UploadDeceasedDoc from "./UploadDeceasedDoc.jsx";

const AddDeceasedNoPANApplicant = ({
  onSubmit,
  onCancel,
  changeApplicantStatus,
}) => {
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [selectedDocs, setSelectedDocs] = useState({ document: [] });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpen1, setPopupOpen1] = useState(false);
  const navigate = useNavigate();
  const [businessId, setBusinessId] = useState(false);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed

  const [formData, setFormData] = useState({
    // panCardNo: "",
    dateOfDeath: "",
    fullName: "",
  });

  const formatDate = (inputDate) => {
    console.log("input date : ", inputDate);
    if (inputDate) {
      const [day, month, year] = inputDate.split("/");
      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return "";
      }

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyLoanFetchUserDetails();

        console.log("Response",response);
        
        const {
          mobile,
          // pan,
          fullName,
          email,
          dob,
          pinCode,
          businessId,
          commencementDate,
          businessPan,
          businessName,
          udyamAadhaar,
          dateOfDeath,
          documents,
        } = response.data;

        setFormData((prevData) => ({
          ...prevData,
          // panCardNo: pan,
          mobileNo: mobile,
          fullName: fullName,
          dateOfBirth: formatDate(dob),
          dateOfDeath: formatDate(dateOfDeath),
          emailId: email,
          pinCode: pinCode,
          businessId: businessId,
          commencementDate: formatDate(commencementDate),
          businessPan: businessPan,
          businessName: businessName,
          udyamAadhaar: udyamAadhaar,
        }));
        setBusinessId(businessId);
        setSelectedDocs({ document: response?.data?.documents });
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [ap_id, loan_app_id]);

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const reverseFormatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleContinue = async (values, setSubmitting) => {
    // changeStatus("BUSINESS_DETAILS_WITH_PAN");
    setIsButtonClicked(true);
    setIsLoading(true);
    const formattedDateOfDeath = reverseFormatDate(formData.dateOfDeath);
    // const fromatedcommecementDate = reverseFormatDate(
    //   formData.commencementDate
    // );
    const data = {
      fullName: formData.fullName,
      // panCardNo: formData.panCardNo || "",
      dateOfDeath: formattedDateOfDeath,
      documents: selectedDocs.document,
    };
    console.log("Data",data);
    
    try {
      const response = await propertyLoanUpdatePersonalDetailsAPI(data);
      console.log(
        "response.businessStatusCode",
        response.data.businessStatusCode
      );
      if (response.data.httpResponseCode === 200 && response.data.businessStatusCode === 2 ) {
        // setPopupOpen1(true);
        changeApplicantStatus(response?.data?.nextPage)
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.1
      ) {
        toast.info(response.data.message);
      } else if (
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.3
      ) {
        toast.warning(response.data.message);
        navigate("/home");
      } else {
        setShowEligibilityCheckForm(false);
        setIsOpen(true);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "fullName") {
      // Avoid digits and special symbols in Full Name
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "panCardNo") {
      // Convert PAN card number to uppercase
      updatedValue = value.toUpperCase();
    }

    setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));

    // If PAN card number is changed, validate only if there's a value
    if (name === "panCardNo" && updatedValue?.trim() !== "") {
      const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      setIsValidPanCard(panCardRegex.test(updatedValue));
    }
  };

  useEffect(() => {
    const { fullName, dateOfDeath, panCardNo } = formData;

    // Determine if PAN card is valid only if it is provided
    const isPanCardValid = panCardNo?.trim() === "" || isValidPanCard;

    const isFormValid =
      fullName?.trim() !== "" &&
      dateOfDeath?.trim() !== "" &&
      selectedDocs?.document?.length > 0 &&
      isPanCardValid;

    setIsFormValid(isFormValid);
  }, [formData, selectedDocs, isValidPanCard]);

  const handleUploadDocument = () => {
    setPopupOpen(true);
  };

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor: isFormValid
      ? "rgba(255, 115, 0, 1)"
      : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };
  const removeExistingDoc = (documentId) => {
    const [documentType] = documentId.split('-');
    setSelectedDocs(prev => ({
      ...prev,
      document: prev.document.filter(doc => doc.documentType !== documentType)
    }));
  };

  const handlePopupClose = (selectedFileNames) => {
    // If selectedFileNames is empty array, it means all documents were removed
    if (selectedFileNames.length === 0) {
      setSelectedDocs(prev => ({
        ...prev,
        // document: []
      }));
    } else {
      // Update with new document list
      setSelectedDocs(prev => ({
        ...prev,
        document: selectedFileNames
      }));
    }
    setPopupOpen(false);
  };

   const handleFileChangeID = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          registrationCertificateDoc: {
            ...formData.registrationCertificateDoc,
            documentData: e.target.result.split(",")[1], // Assuming base64 data
            documentName: file.name,
            documentType: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const goToSummaryPage = () => {
    changeApplicantStatus("");
  };

  const handleClear = () => {
    changeApplicantStatus("");
  };

  const handleDialogClose1 = () => {
    // setPopupOpen1(false);
    changeApplicantStatus("");
  };
  return (
    <>
      <div className="d-flex mt-3">
        <div>
          <h6
            className="text mt-4"
            style={{ marginBottom: "1.5rem", fontWeight: 700 }}
          >
            Deceased Applicant Details
          </h6>
          <p className="text-start msg mr-5" style={{ color: "blue", marginTop: "-1rem", marginLeft: "0rem", }}>
            Death Certificate is Mandatory for Deceased Applicant
          </p>
        </div>
        <div
          onClick={goToSummaryPage}
          className="text fw-bold"
          style={{
            color: "orange",
            marginLeft: "auto",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Go To Applicant Summary
        </div>
      </div>


      <h6 className="text font-weight-bold mt-4">Personal Details</h6>
      <form className="mt-4">
        <div className="row mt-3 mt-md-4">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              label="Full Name"
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              name="dateOfDeath"
              label="Date of Death"
              type="date"
              fullWidth
              InputLabelProps={{
                maxLength: 80,
                shrink: true,
              }}
              inputProps={{
                max: new Date().toISOString().split("T")[0],
                onKeyDown: (e) => e.preventDefault(), // Disables manual entry
              }}
              value={formData.dateOfDeath}
              onChange={handleChange}
              className="form-control custom-disabled-input"
            />
          </div>
        </div>

        <div className="row mt-4">
          {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <ArthaTextField
              id="outlined-basic"
              name="panCardNo"
              value={formData.panCardNo}
              onChange={handleChange}
              label="Pan Card No. (Optional)"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                maxLength: 80,
              }}
              inputProps={{
                style: { textTransform: "uppercase" },
              }}
              className="form-control"
            />
            <div className="subtitle mt-2">
              <div
                className={`pan-card-validation ${
                  isValidPanCard ? "" : "invalid-input"
                }`}
              >
                {isValidPanCard
                  ? ""
                  : "Invalid Pan Card. Please enter in the specified format."}
              </div>
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md- col-lg-6 mt-4">
            <div className="row mt-1">
              <div className="col-9">
                {/* <p className="msg font-weight-bold mb-2">Supporting Documents</p> */}
                <h6 className="text font-weight-bold">Supporting Documents</h6>
                <p className="msg mt-2" style={{ color: "blue" }}>
                  You may attach additional identification documents for the
                  deceased person{" "}
                </p>
                {selectedDocs.document && selectedDocs.document.length > 0 ? (
                  selectedDocs.document.map((doc, index) => (
                    <React.Fragment key={index}>
                      <FaCheckCircle
                        className="badge-icon"
                        style={{ marginTop: "-7px", color: "green" }}
                      />
                      <span
                        className="mt-2 upload-lable"
                        style={{ position: "relative", top: "-10px" }}
                      >
                        {doc.documentType} <br />
                      </span>
                    </React.Fragment>
                  ))
                ) : (
                  <div>
                    <FaExclamationCircle className="badge-icon" />
                    <span
                      className="mt-2 upload-lable"
                      style={{ position: "relative", top: "-10px" }}
                    >
                      Not Uploaded
                    </span>
                  </div>
                )}
              </div>
              <div className="col-2">
                <input
                  type="file"
                  //   ref={fileInputRefID}
                  style={{ display: "none" }}
                  onChange={handleFileChangeID}
                />
                <Button
                  variant="contained"
                  className="upload_btn btn_white shadow-none mt-5"
                  onClick={handleUploadDocument}
                >
                  Upload
                </Button>
                <UploadDeceasedDoc
                  open={isPopupOpen}
                  existingDocuments={selectedDocs.document}
                  onRemove={removeExistingDoc}
                  handleClose={handlePopupClose}
                />
                <Dialog
                  className="p-1 rounded"
                  open={isPopupOpen1}
                  onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                      handleDialogClose1();
                    }
                  }}
                  disableEscapeKeyDown
                >
                  <div className="border border-dark m-2 rounded">
                    <DialogContent>
                      <DialogContentText className="mt-4 mb-2 fw-bold text-dark">
                        Applicant Details are added successfully.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <ArthaContinueButton
                        className="mx-auto mb-4"
                        onClick={handleDialogClose1}
                        autoFocus
                        style={{ width: "37%", height: "6vh" }}
                      >
                        OK
                      </ArthaContinueButton>
                    </DialogActions>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 mx-auto">
          <div className="col-6 d-flex justify-content-end mt-5">
            <ArthaClearButton
              variant="contained"
              onClick={handleClear}
            // disabled={!isFormValid}
            >
              Cancel
            </ArthaClearButton>
          </div>

          <div className="col-6 d-flex justify-content-start mt-5">
            <ArthaContinueButton
              variant="contained"
              onClick={handleContinue}
              disabled={!isFormValid}
              style={buttonStyle}
            >
              {isLoading ? (
                <LoadingSpinner style={{ pointerEvents: "none" }} />
              ) : (
                "Continue"
              )}
            </ArthaContinueButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddDeceasedNoPANApplicant;
