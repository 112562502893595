import React, { useEffect, useState } from "react";
import Wizard from "./Wizard";
import PersonalDetailsForm from "./PersonalDetailsForm";
import EligibilityCheckForm from "./EligibilityCheckForm";
import KYCAdharVerification from "./KYCAdharVerification";
import EligibilityTenureForm from "./EligibilityTenureForm";
import KYCDetailsForm from "./KYCDetailsForm";
import PhotoVerification from "./PhotoVerification";
import EmployementDetails from "./EmployementDetails";
import BankStatement from "./BankStatement";
import GstrDetails from "./Gstr";
import LoanOffer from "./LoanOffer";
import LoadingComponent from "./LoadingPage";
import SanctionLetter from "./SanctionLetter";
import AccountAggregator from "./AccountAggregator";
import ProfessionalDetails from "./ProfessionalDetails";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BankAccountVerification from "./BankAccountVerification";
import Vkyc from "./vKYC";
import RedirectedLoadingComponent from "./RedirectedLoadingComponent";
import MandateSetup from "./MandateSetup";
import MandateLoadingPage from "./MandateLoadingPage";
import MsignerPage from "./MsignerPage";
import MsignerLoadingPage from "./MsignerLoadingPage";
import Disbursement from "./Disbursement";
import ITrDetails from "./ITR";
import Refernces from "./Refences";
import IncomeAssessmentDetails from "./BusinessIncomeAssessment";
import AdditionalEmployementDetails from "./AdditionalEmployementDetails";
import previousEmployer from "./previousEmployer";
const BusinessLoan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [applicationStatus, setApplicationStatus] =
    useState("PERSONAL_DETAILS");

  const changeStatus = (status) => {
    setApplicationStatus(status);
  };
  // Now you can access the state values
  const { loanApplicationId, apId, loanApplicationStatus } = state || {};
  useEffect(() => {
    console.log("Current state:", state);
    setApplicationStatus(state?.loanApplicationStatus || "");
    console.log("Current status:", state?.loanApplicationStatus);
  }, [state]); // This useEffect will run whenever the 'state' changes

  // State variables
  const [userData, setUserData] = useState({});
  const [ckycFlag, setCkycFlag] = useState(true);
  const [tenureFlag, setTenureFlag] = useState(false);
  const [aaFlag, setAaFlag] = useState(true);
  const [empDetailsFlag, setEmpDetailsflag] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  // Function to handle form submission
  const handleFormSubmit = (formData) => {
    console.log("Before Data:", userData);
    console.log("Form Data:", formData);
    setUserData(formData);
    console.log("After Data:", userData);

    setActiveStep((prevStep) => prevStep + 1);
  };

  // Function to handle going back in the stepper
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Function to handle CKYC success
  const ckycSuccess = (flag) => {
    setCkycFlag(flag);
  };

  // Function to handle AA success
  const aaSuccess = (flag) => {
    setAaFlag(flag);
  };

  // Function to set the active step
  const handleSetActiveStep = (step) => {
    setActiveStep(step);
  };

  // Function to handle additional employment details
  const additionalEmpDetails = (flag) => {
    setActiveStep(4);
    setEmpDetailsflag(flag);
  };

  // Function to determine the initial step based on application status
  const determineInitialStep = (status) => {
    switch (status) {
      case "PERSONAL_DETAILS":
        return 0;
      case "ELIGIBILITY_DETAILS":
        return 1;
      case "ELIGIBILITY_CONFIRMED":
        return 1;
      case "ELIGIBILITY_DETAILS_REJECTED":
        return 1;
      case "AADHAAR_VERIFICATION":
        return 2;
      case "KYC_DETAILS":
        return 2;
      case "PHOTO_VERIFICATION":
        return 3;
      case "AIS_DETAILS":
        return 4;
      case "EMPLOYMENT_DETAILS":
        return 4;
      case "ITR_DETAILS":
        return 4;
      case "GST_RETURN_DETAILS":
        return 4;
      case "REFERENCE_DETAILS":
        return 4;
      case "ACCOUNT_AGGREGATOR_DETAILS":
        return 5;
      case "BANK_STATEMENT_DETAILS":
        return 5;
      case "INCOME_ASSESMENT_DETAILS":
        return 5;
      case "LOAN_OFFER_CONFIRMED":
        return 6;
      case "SANCTION_LETTER_GENERATED":
        return 7;
      case "VKYC_DETAILS":
        return 8;
      case "VKYC_DETAILS_INPROGRESS":
        localStorage.setItem("isBusinessloanVkycRedirected", "true");
        return 22;
        case "BANK_ACCOUNT_DETAILS":
        return 9;
      case "MANDATE_CHECK":
        return 10;
      case "MANDATE_CHECK_INPROGRESS":
        localStorage.setItem("isBusinessloanmandateRedirected", "true");
        return 11;
      case "LOAN_AGREEMENT_DETAILS":
        return 12;
      case "LOAN_AGREEMENT_DETAILS_INPROGRESS":
        localStorage.setItem("isBusinessloanEmsignerRedirected", "true");
        return 13;
      default:
        return 0;
    }
  };
  useEffect(() => {
    let isVkycRedirected = localStorage.getItem("isBusinessloanVkycRedirected");
    if (isVkycRedirected == "true") {
      setActiveStep(22);
      setApplicationStatus("");
    }
  }, []);

  useEffect(() => {
    let ismandateRedirected = localStorage.getItem(
      "isBusinessloanmandateRedirected"
    );
    if (ismandateRedirected == "true") {
      setActiveStep(11);
      setApplicationStatus("");
    }
  }, []);

  useEffect(() => {
    let isEmsignerRedirected = localStorage.getItem(
      "isBusinessloanEmsignerRedirected"
    );
    if (isEmsignerRedirected == "true") {
      setActiveStep(13);
      setApplicationStatus("");
    }
  }, []);

  // useEffect(() => {
  //   if (applicationStatus === "ELIGIBILITY_DETAILS_REJECTED") {
  //     navigate("/home/failed");
  //   } else if (applicationStatus === "PINCODE_NON_SERVICEABLE") {
  //     navigate("/home/inserviceablepin");
  //   } else if (applicationStatus === "CIBIL_REJECTED") {
  //     navigate("/home/failed");
  //   } else {
  //     const initialActiveStep = determineInitialStep(applicationStatus);
  //     setActiveStep(initialActiveStep);
  //     console.log("initialActiveStep", initialActiveStep);
  //     console.log("activeStep", activeStep);
  //   }
  // }, [applicationStatus,navigate,activeStep]);

  useEffect(() => {
    if (applicationStatus) {
      const initialActiveStep = determineInitialStep(applicationStatus);
      setActiveStep(initialActiveStep);
      console.log("initialActiveStep", initialActiveStep);
      console.log("activeStep", activeStep);
    }
  }, [applicationStatus]);

  return (
    <div className="row m-sm-0 m-0">
      <div className="col-12 col-sm-12 col-md-9 mx-auto">
        <div className=" mx-2 plFormBox ">
          <div className="formWizard p-2">
            <h5 className="text-center mb-3">Business Loan Application</h5>
            <Wizard activeStep={activeStep} />
          </div>

          <div className="personalForm">
            {activeStep === 0 && (
              <PersonalDetailsForm onSubmit={handleFormSubmit} />
            )}

            {activeStep === 1 && (
              <>
                {(applicationStatus &&
                  applicationStatus === "ELIGIBILITY_DETAILS") ||
                "ELIGIBILITY_CONFIRMED" ? (
                  <EligibilityCheckForm
                    onContinue={handleFormSubmit}
                    onBack={handleBack}
                    userData={userData}
                    ckycSuccess={ckycSuccess}
                  />
                ) : applicationStatus === "ELIGIBILITY_DETAILSS" ||
                  !ckycFlag ? (
                  <EligibilityTenureForm
                    onContinue={handleFormSubmit}
                    onBack={handleBack}
                    setTenureFlag={setTenureFlag}
                  />
                ) : (
                  <EligibilityCheckForm
                    onContinue={handleFormSubmit}
                    onBack={handleBack}
                    userData={userData}
                    ckycSuccess={ckycSuccess}
                  />
                )}
              </>
            )}

            {activeStep === 2 && (
              <>
                {applicationStatus ? (
                  <>
                    {applicationStatus === "KYC_DETAILS" && (
                      <KYCDetailsForm
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                      />
                    )}
                    {applicationStatus === "AADHAAR_VERIFICATION" && (
                      <KYCAdharVerification
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {ckycFlag ? (
                      <KYCDetailsForm
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        changeStatus={changeStatus}
                      />
                    ) : (
                      <KYCAdharVerification
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                      />
                    )}
                  </>
                )}
              </>
            )}

            {activeStep === 3 && (
              <PhotoVerification
                onBack={handleBack}
                onContinue={handleFormSubmit}
                changeStatus={changeStatus}
              />
            )}

            {activeStep === 4 && (
              <>
                {applicationStatus ? (
                  <>
                    {applicationStatus === "EMPLOYMENT_DETAILS" && (
                      <ProfessionalDetails
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        setActiveStep={handleSetActiveStep}
                        changeStatus={changeStatus}
                      />
                    )}
                    {applicationStatus === "REFERENCE_DETAILS" && (
                      <Refernces
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        onCancel={handleBack}
                        changeStatus={changeStatus}
                      />
                    )}
                    {applicationStatus === "ITR_DETAILS" && (
                      <ITrDetails
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        onCancel={handleBack}
                        changeStatus={changeStatus}
                      />
                    )}
                    {applicationStatus === "GST_RETURN_DETAILS" && (
                      <GstrDetails
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        onCancel={handleBack}
                        changeStatus={changeStatus}
                        setActiveStep={handleSetActiveStep}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {empDetailsFlag ? (
                      <Refernces
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        setActiveStep={handleSetActiveStep}
                        changeStatus={changeStatus}
                      />
                    ) : (
                      <ProfessionalDetails
                        onBack={handleBack}
                        onContinue={handleFormSubmit}
                        setActiveStep={handleSetActiveStep}
                        changeStatus={changeStatus}
                      />
                    )}
                  </>
                )}
              </>
            )}

            {activeStep === 5 && (
              <>
                {applicationStatus ? (
                  <>
                    {applicationStatus === "INCOME_ASSESMENT_DETAILS" && (
                      <IncomeAssessmentDetails
                        setActiveStep={handleSetActiveStep}
                        onContinue={handleFormSubmit}
                        onCancel={handleBack}
                        changeStatus={changeStatus}
                      />
                    )}
                    {applicationStatus === "BANK_STATEMENT_DETAILS" && (
                      <BankStatement
                        setActiveStep={handleSetActiveStep}
                        onCancel={handleBack}
                        onContinue={handleFormSubmit}
                        changeStatus={changeStatus}
                      />
                    )}
                  </>
                ) : (
                  <IncomeAssessmentDetails
                    setActiveStep={handleSetActiveStep}
                    onCancel={handleBack}
                    onContinue={handleFormSubmit}
                    changeStatus={changeStatus}
                  />
                )}
              </>
            )}

            {activeStep === 21 && (
              <LoadingComponent
                setActiveStep={handleSetActiveStep} // Pass setActiveStep to LoadingComponent
              />
            )}
            {activeStep === 6 && (
              <LoanOffer onBack={handleBack} onContinue={handleFormSubmit} />
            )}
            {activeStep === 7 && (
              <SanctionLetter
                onBack={handleBack}
                onContinue={handleFormSubmit}
                setActiveStep={handleSetActiveStep}
              />
            )}
            {activeStep === 8 && (
              <Vkyc
                onBack={handleBack}
                onContinue={handleFormSubmit}
                setActiveStep={handleSetActiveStep}
              />
            )}
            {activeStep === 22 && (
              <RedirectedLoadingComponent setActiveStep={handleSetActiveStep} />
            )}
            {activeStep === 9 && (
              <BankAccountVerification
                onBack={handleBack}
                onContinue={handleFormSubmit}
                setActiveStep={handleSetActiveStep}
              />
            )}
            {activeStep === 10 && (
              <MandateSetup
                onBack={handleBack}
                onContinue={handleFormSubmit}
                setActiveStep={handleSetActiveStep}
              />
            )}
            {activeStep === 11 && (
              <MandateLoadingPage setActiveStep={handleSetActiveStep} />
            )}
            {activeStep === 12 && (
              <MsignerPage onBack={handleBack} onContinue={handleFormSubmit} />
            )}
            {activeStep === 13 && (
              <MsignerLoadingPage setActiveStep={handleSetActiveStep} />
            )}
            {activeStep === 14 && <Disbursement />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLoan;
